<template>
  <div class="page-wrapper">
    <!-- start header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des livres</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">Liste des livres</li>
              </ol>
            </div>
          </div>
          <!-- start book list content -->
          <div class="card">
            <div class="card-header">
              <h3>Livres disponibles</h3>
              <a :href="'/create-livre'" class="btn btn-primary">Ajouter +</a>
            </div>
            <div class="card-body">
              <div class="row align-items-end">
                <div class="col-sm-12 col-md-4">
                  <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start">
                    <label class="me-2">Afficher</label>
                    <select
                      name="example47_length"
                      aria-controls="example47"
                      class="form-select-sm"
                      @change="updatePerPage"
                      v-model="limitQuery"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span class="ms-2">entrées</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                  <div style="float: right">
                    <label for="searchInput" class="me-2">Recherche :</label>
                    <input
                      type="text"
                      id="searchInput"
                      placeholder=""
                      aria-controls="example47"
                      v-model="searchQuery"
                      @input="getLivres(currentPage)"
                    />
                  </div>
                </div>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Titre</th>
                    <th>Genre</th>
                    <th>Année de publication</th>
                    <th>Exemplaires disponibles</th>
                    <th>Catégorie</th>
                    <th>Auteur</th>
                    <th>Édition</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="livre in livres" :key="livre.id">
                    <td>{{ livre.titre_livre }}</td>
                    <td>{{ livre.genre }}</td>
                    <td>{{ livre.annee_publication }}</td>
                    <td>{{ livre.exemplaire_disponible }}</td>
                    <td>{{ getCategorie(livre.id_categorie) }}</td>
                    <td>{{ getAuteur(livre.id_auteur) }}</td>
                    <td>{{ getEdition(livre.id_edition) }}</td>
                    <td>
                      <a class="tblEditBtn" @click="editLivre(livre.id)">
                        <i class="fa fa-pencil"></i>
                      </a>
                      <a class="tblDelBtn" @click="deleteLivre(livre.id)">
                        <i class="fa fa-trash-o"></i>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="!livres.length">
                    <td colspan="8" class="text-center">Aucun livre trouvé</td>
                  </tr>
                </tbody>
              </table>
              <div class="row" v-if="this.livres.length > 0">
                <div class="col-sm-12 col-md-5">
                  <div class="dataTables_info" role="status" aria-live="polite">
                    Affichage de {{ from_begin }} à {{ to_begin }} sur {{ total }} entrées
                  </div>
                </div>
                <div class="col-sm-12 col-md-7 text-right">
                  <div class="dataTables_paginate paging_simple_numbers">
                    <paginate-component
                      :total-pages="total_page"
                      :total="total"
                      :per-page="per_page"
                      :current-page="current_page"
                      @pagechanged="pagPage"
                    ></paginate-component>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end book list content -->
        </div> <!-- end page-content -->
      </div> <!-- end page-content-wrapper -->
    </div> <!-- end page-container -->
  </div> <!-- end page-wrapper -->
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import axios from "@/axios";
import Swal from 'sweetalert2';

export default {
  name: "listLivreComponent",
  components: {
    appnavbar,
    appfooter,
    appheader,
    PaginateComponent
  },
  computed: {
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  data() {
    return {
      livres: [],
      categories: [],
      auteurs: [],
      editions: [],
      limitQuery: 5,
      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      searchQuery: "",
      currentPage: 1,
      lastPage: 1,
    };
  },
  mounted() {
    this.getLivres();
    this.getCategories();
    this.getAuteurs();
    this.getEditions();
  },
  methods: {
    async getLivres() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const params = {
        per_page: this.limitQuery,
        page: this.current_page,
        search: this.searchQuery
      };
      try {
        const response = await axios.get("/livre/index/paginate", { headers, params });
        if (response.data.statut === 200) {
          const allLivres = response.data.livre.data;
          this.livres = allLivres.map(livre => ({
            ...livre,
            categorie: this.getCategorie(livre.id_categorie),
            auteur: this.getAuteur(livre.id_auteur),
            edition: this.getEdition(livre.id_edition)
          }));
          const page = {
            total_page: response.data.livre.last_page,
            total: response.data.livre.total,
            per_page: response.data.livre.per_page,
            current_page: response.data.livre.current_page,
            from_begin: response.data.livre.from,
            to_begin: response.data.livre.to,
          };
          this.total_page = page.total_page;
          this.total = page.total;
          this.per_page = page.per_page;
          this.current_page = page.current_page;
          this.from_begin = page.from_begin;
          this.to_begin = page.to_begin;
        } else {
          Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des livres', 'error');
        }
      } catch (error) {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des livres', 'error');
      }
    },
    async getCategories() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("/categorie/index", { headers });
        this.categories = response.data.categorie;
      } catch (error) {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des catégories', 'error');
      }
    },
    async getAuteurs() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("/auteur/index", { headers });
        this.auteurs = response.data.auteur;
      } catch (error) {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des auteurs', 'error');
      }
    },
    async getEditions() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get("/edition/index", { headers });
        this.editions = response.data.edition;
      } catch (error) {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des éditions', 'error');
      }
    },
    getCategorie(id) {
      const categorie = this.categories.find(c => c.id === id);
      return categorie ? categorie.intitule : '';
    },
    getAuteur(id) {
      const auteur = this.auteurs.find(a => a.id === id);
      return auteur ? auteur.nom_auteur : '';
    },
    getEdition(id) {
      const edition = this.editions.find(e => e.id === id);
      return edition ? edition.nom_edition : '';
    },
    editLivre(id) {
      this.$router.push({ path: `/edit-livre/${id}` });
    },
     async deleteLivre(id) {
    const confirmation = await Swal.fire({
      title: 'Êtes-vous sûr?',
      text: "Vous ne pourrez pas annuler cela!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimez-le!'
    });

    if (confirmation.isConfirmed) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.delete(`/livre/delete/${id}`, { headers });
        if (response.data.statut === 200) {
          Swal.fire('Succès!', 'Livre supprimé avec succès', 'success');
          this.getLivres(); // refresh the list
        } else {
          Swal.fire('Erreur!', 'Une erreur est survenue lors de la suppression du livre', 'error');
        }
      } catch (error) {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la suppression du livre', 'error');
      }
    }
  },
    pagPage(pagination) {
      this.current_page = pagination;
      this.getLivres(this.currentPage);
    },
    updatePerPage(event) {
      const newPerPage = parseInt(event.target.value);
      this.reloadDataWithPerPage(newPerPage);
    },
    reloadDataWithPerPage(newPerPage) {
      if (this.limitQuery !== 5) {
        this.limitQuery = newPerPage;
        this.getLivres(this.currentPage);
      }
    }
  },
};
</script>