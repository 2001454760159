<template>
  <div class="col-lg-6 p-t-20">
    <div
      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
    >
      <label
        class="mdl-textfield__label"
        for="txtFirstName"
        v-show="!form.intitule"
        >Intitule Chambre</label
      >
      <input
        class="mdl-textfield__input"
        type="text"
        id="txtFirstName"
        v-model="form.intitule"
        @input="validatedata('intitule')"
      />
      <span class="erreur">{{ this.nom_chambre_erreur }}</span>
    </div>
  </div>

  <div class="col-lg-6 p-t-20">
    <div
      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
    >
      <label for="list6" class="mdl-textfield__label" v-show="!form.id_batiment"
        >Choisissez le batiment</label
      >
      <select
        class="mdl-textfield__input"
        id="list6"
        readonly
        tabIndex="-1"
        v-model="form.id_batiment"
        @change="validatedata('id_batiment')"
      >
        <option
          v-for="(batiment, index) in batiments"
          :value="batiment.id"
          :key="index"
        >
          {{ batiment.intitule }}
        </option>
      </select>
      <span class="erreur">{{ id_batiment_erreur }}</span>
    </div>
  </div>

  <div class="col-lg-12 p-t-20 text-center">
    <button
      type="submit"
      v-if="!this.editModal"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
      @click.prevent="validerAvantAjout()"
    >
      Enregistrer
    </button>
    <button
      type="submit"
      v-if="this.editModal"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
      @click.prevent="validerAvantAjout()"
    >
      Modifier
    </button>
    <button
      type="button"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
      @click="resetForm"
    >
      Annuler
    </button>
  </div>

  <div class="card card-box mt-4">
    <div class="card-head">
      <header>Liste des dernières chambres</header>
      <div class="tools">
        <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
        <a
          class="t-collapse btn-color fa fa-chevron-down"
          href="javascript:;"
        ></a>
        <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
      </div>
    </div>
    <div class="card-body">
      <table
        class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Intitule Chambre</th>
            <th>Batiment</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="odd gradeX"
            v-for="(chambre, index) in chambres"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ chambre.intitule }}</td>
            <td>{{ chambre.batiment.intitule }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    flatPickr,
  },
  mixins: [permissionMixin],
  props: ["chambre"],
  name: "createchambreCompenent",

  data() {
    return {
      chambres: [],
      batiments: [],
      form: new Form({
        intitule: "",
        id_batiment: "",
      }),
      nom_chambre_erreur: "",
      id_batiment_erreur: "",
      etatForm: false,
      editModal: false,
      idchambre: "",
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_batiment();
    this.get_chambre();
    bus.on("chambreModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.monterToupdate(eventData.chambre);
    });
  },

  /*  mounted() {
        this.get_batiment();
        this.get_chambre();
        bus.on('chambreModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.monterToupdate(eventData.chambre);
        });
    }, */

  methods: {
    ...mapActions(["login", "logout"]),
    async soumettre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("intitule", this.form.intitule);
      formdata.append("id_batiment", this.form.id_batiment);
      if (this.hasCreatePermission("Chambre")) {
        try {
          await axios.post("/chambre/store", formdata, { headers });
          showDialog6("Chambre ajoutée avec succès");
          bus.emit("chambreAjoutee");
          this.resetForm();
          setTimeout(() => {
            window.location.href = "/liste-chambre";
          }, 1500);
        } catch (e) {
          if (e.request.status === 404) {
            showDialog3("Cette chambre existe déjà");
          } else {
            showDialog3("Une erreur est survenue lors de l'enregistrement");
          }
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    validerAvantAjout() {
      // Exécutez la validation des champs
      const isNomchambreValid = this.validatedataold();
      if (isNomchambreValid === true) {
        this.etatForm = false;
        this.editModal = false;
        return 0;
      } else {
        if (this.editModal === true) {
          this.etatForm = true;
          this.form.intitule = this.form.intitule.toUpperCase();
          this.update_chambre(this.idchambre);
          this.editModal = false;
        } else {
          this.form.intitule = this.form.intitule.toUpperCase();
          this.soumettre();
          this.etatForm = true;
          this.editModal = false;
        }
      }
    },

    resetForm() {
      this.form.intitule = "";
      this.form.id_batiment = "";
      this.nom_chambre_erreur = "";
      this.id_batiment_erreur = "";
      this.editModal === false;
    },

    verifCaratere(nom) {
      const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
      return valeur.test(nom);
    },

    validatedataold() {
      this.nom_chambre_erreur = "";
      this.id_batiment_erreur = "";
      var i = 0;
      if (this.form.id_batiment === "") {
        this.id_batiment_erreur =
          "Vous avez oublié de sélectionner le batiment";
        i = 1;
      }

      if (this.form.intitule === "") {
        this.nom_chambre_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.intitule.length < 4) {
        this.nom_chambre_erreur =
          "Ce champ doit contenir au moins 12 Caratères";
        i = 1;
      }
      if (this.form.id_batiment === "") {
        this.id_batiment_erreur =
          "Vous avez oublié de sélectionner le batiment";
        i = 1;
      }

      if (i === 1) return true;
      return false;
    },
    validatedata(champ) {
      switch (champ) {
        case "intitule":
          // Effectuez la validation pour le champ 'nom'
          this.nom_chambre_erreur = "";

          if (this.form.intitule === "") {
            this.nom_chambre_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;

        case "id_batiment":
          //pour prenom
          this.id_batiment_erreur = "";

          if (this.form.id_batiment === "") {
            this.id_batiment_erreur =
              "Vous avez oublié de sélectionner la chambre";
            return true;
          }
          break;
        default:
          break;
      }
    },

    async get_batiment() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Chambre")) {
        await axios
          .get("/batiment/index", { headers })
          .then((response) => {
            this.batiments = response.data.batiment;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des Batiments",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_chambre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Chambre")) {
        await axios
          .get("/chambre/get/last", { headers })
          .then((response) => {
            this.chambres = response.data.chambre;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des chambres",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async update_chambre(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("intitule", this.form.intitule);
      formdata.append("id_batiment", this.form.id_batiment);

      if (this.hasUpdatePermission("Chambre")) {
        try {
          await axios.post("/chambre/update/" + id, formdata, { headers });
          showDialog6("Chambre modifiée avec succès");
          bus.emit("chambreAjoutee");
          const eventData = {
            editModal: false,
          };
          bus.emit("chambreDejaModifier", eventData);
        } catch (e) {
          console.log(e);
          if (e.request.status === 404) {
            showDialog3("Une erreur est survenue lors de la modification");
          } else {
            showDialog3("Une erreur est survenue lors de la modification");
          }
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    monterToupdate(chambre) {

      this.idchambre = chambre.id;
      this.editModal = chambre.editModal;
      this.form.intitule = chambre.chambre;
      this.form.id_batiment = chambre.id_batiment;
      this.form.nom_batiment = chambre.batiment;
    },
  },
};
</script>
