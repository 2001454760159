<template>
  <div class="col-lg-6 p-t-20">
    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
      <label class="mdl-textfield__label" for="txtFirstName" v-show="!form.designation">Designation</label>
      <input class="mdl-textfield__input" type="text" id="txtFirstName" v-model="form.designation"
        @input="validatedata('designation')" />
      <span class="erreur">{{ this.designation_erreur }}</span>
    </div>
  </div>

  <div class="col-lg-6 p-t-20">
    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
      <label class="mdl-textfield__label" for="txtFirstName" v-show="!form.nombre_jour">Nombre Jour</label>
      <input class="mdl-textfield__input" type="number" id="txtFirstName" v-model="form.nombre_jour"
        @input="validatedata('nombre_jour')" />
      <span class="erreur">{{ this.nombre_jour_erreur }}</span>
    </div>
  </div>
  <div class="col-lg-12 p-t-20">
    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
      <label class="mdl-textfield__label" for="txtFirstName" v-show="!form.montant_jour">Montant Jour</label>
      <input class="mdl-textfield__input" type="number" id="txtFirstName" v-model="form.montant_jour"
        @input="validatedata('montant_jour')" />
      <span class="erreur">{{ this.montant_jour_erreur }}</span>
    </div>
  </div>

  <div class="col-lg-12 p-t-20">
    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
      <label class="mdl-textfield__label" for="dateOfBirth" v-show="!form.date_location">Date de location</label>
      <flat-pickr v-model="form.date_location" class="mdl-textfield__input"
        @input="validatedata('date_location')"></flat-pickr>
      <span class="erreur">{{
        this.date_location_erreur
      }}</span>
    </div>
  </div>

  <div class="col-lg-12 p-t-20 mt-1">
    <div
      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">


      <label class="mdl-textfield__label" for="partenaireSelect" v-show="!form.id_partenaire">
        Partenaire
      </label>

      <select class="mdl-textfield__input" id="partenaireSelect" readonly tabIndex="-1" v-model="form.id_partenaire"
        @change="validatedata('id_partenaire')">
        <option v-for="(partenaire, index) in partenaires" :value="partenaire.id" :key="index">
          {{ partenaire.nom_partenaire }}
        </option>
      </select>

      <span class="erreur">{{ id_partenaire_erreur }}</span>
    </div>
  </div>




  <div class="col-lg-12 p-t-20 text-center">
    <button type="submit" v-if="!this.editModal"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
      @click.prevent="validerAvantAjout()">
      Enregistrer
    </button>

    <button type="submit" v-if="this.editModal"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
      @click.prevent="validerAvantAjout()">
      Modifier
    </button>

    <button type="button"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
      @click="resetForm">
      Annuler
    </button>
  </div>



</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import IdleTimeoutMixin from '@/IdleTimeoutMixin.js';
import permissionMixin from "@/permission";

export default {
  mixins: [IdleTimeoutMixin],
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    flatPickr,
  },
  mixins: [permissionMixin],
  props: ["location"],
  name: "createFactureCompenent",

  data() {
    return {
      factures: [],
      partenaires: [],
      directions: [],
      form: new Form({
        designation: "",
        nombre_jour: "",
        montant_jour: "",
        date_location: "",
        id_partenaire: "",
        id_salle: "",
        id_user: "",
        id_direction: "",

        nom_partenaire: "",
        description: "",
        contact: "",
        adresse: "",
        email: "",
        boite_postale: "",
        date_debut: "",
        date_fin: "",
        type: "",
        exoneration: "",
      }),
      designation_erreur: "",
      nombre_jour_erreur: "",
      montant_jour_erreur: "",
      date_location_erreur: "",
      id_partenaire_erreur: "",
      id_salle_erreur: "",

      nom_partenaire_erreur: "",
      description_erreur: "",
      contact_erreur: "",
      adresse_erreur: "",
      email_erreur: "",
      boite_postale_erreur: "",
      date_debut_erreur: "",
      date_fin_erreur: "",
      id_direction_erreur: "",
      type_erreur: "",
      exoneration_erreur: "",

      etatForm: false,
      editModal: false,
      idFacture: "",
      partenaire: false,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_partenaire();
    this.get_direction();

    bus.on("factureModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.monterToupdate(eventData.facture);
    });
  },
  /*  mounted() {
     this.get_partenaire();
     this.get_direction();
 
     bus.on("factureModifier", (eventData) => {
       this.editModal = eventData.editModal;
       this.monterToupdate(eventData.facture);
     });
   }, */

  methods: {
    ...mapActions(["login", "logout"]),
    async soumettre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("designation", this.form.designation);
      formdata.append("nombre_jour", this.form.nombre_jour);
      formdata.append("montant_jour", this.form.montant_jour);
      formdata.append("date_location", this.form.date_location);
      formdata.append("id_partenaire", this.form.id_partenaire);
      formdata.append("id_salle", this.form.id_salle);
      formdata.append("nom_partenaire", this.form.nom_partenaire);
      formdata.append("description", this.form.description);
      formdata.append("contact", this.form.contact);
      formdata.append("adresse", this.form.adresse);
      formdata.append("email", this.form.email);
      formdata.append("boite_postale", this.form.boite_postale);
      formdata.append("date_debut", this.form.date_debut);
      formdata.append("date_fin", this.form.date_fin);
      formdata.append("id_direction", this.form.id_direction);
      formdata.append("type", this.form.type);
      formdata.append("exoneration", this.form.exoneration);
      formdata.append("id_user", this.userIdrole);

      if (this.hasCreatePermission("Facture")) {

        try {
          await axios.post('/location/update/' + id, formdata, {
            headers,
          });
          bus.emit('factureAjoutee');
          showDialog6("Facture modifiée avec succès");
          const eventData = {
            editModal: false,
          };
          bus.emit('factureDejaModifier', eventData);

          setTimeout(() => {
            window.location.href = "/liste-facture";
          }, 1500);



        } catch (e) {
          console.log(e)
          if (e.request.status === 404) {
            showDialog3("Une erreur est survenue lors de la modification");
          } else {
            //Swal.fire('Erreur !', 'Une erreur est survenue lors de l\'enregistrement', 'error')
            showDialog3("Une erreur est survenue lors de la modification");
          }
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    validerAvantAjout() {
      const isVerifIdValid = this.validatedataOld();
      if (isVerifIdValid) {
        this.editModal = false;
        return 0;
      } else {
        if (this.editModal === true) {
          this.etatForm = true;
          this.form.designation = this.form.designation.toUpperCase();
          this.update_facture(this.idFacture);
          this.editModal = false;
          this.resetForm();
        } else {
          this.form.designation = this.form.designation.toUpperCase();
          this.soumettre();
          this.editModal = false;
          this.resetForm();
        }
      }
    },

    resetForm() {
      this.form.designation = "";
      this.form.nombre_jour = "";
      this.form.montant_jour = "";
      this.date_location = "";
      this.id_partenaire = "";
      this.id_salle = "";

      this.designation_erreur = "",
        this.nombre_jour_erreur = "",
        this.montant_jour_erreur = "",
        this.date_location_erreur = "",
        this.id_partenaire_erreur = "",
        this.id_salle_erreur = "",
        this.editModal = false;

      this.nom_partenaire = "";
      this.description = "";
      this.contact = "";
      this.adresse = "";
      this.email = "";
      this.boite_postale = "";
      this.date_debut = "";
      this.date_fin = "";
      this.nom_partenaire_erreur = "";
      this.description_erreur = "";
      this.contact_erreur = "";
      this.adresse_erreur = "";
      this.email_erreur = "";
      this.boite_postale_erreur = "";
      this.date_debut_erreur = "";
      this.date_fin_erreur = "";
      this.id_direction_erreur = "";
      const eventData = {
        editModal: false,
      };
      bus.emit('factureDejaModifier', eventData);
    },

    verifCaratere(nom) {
      const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
      return valeur.test(nom);
    },

    validatedata(champ) {
      switch (champ) {
        case "designation":
          this.designation_erreur = "";
          // Effectuez la validation pour le champ 'nom'
          if (this.form.designation === "") {
            this.designation_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;

        case "nombre_jour":
          //pour direction
          this.nombre_jour_erreur = "";
          if (this.form.nombre_jour === "") {
            this.nombre_jour_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;

        case "date_location":
          //pour direction
          this.date_location_erreur = "";
          if (this.form.date_location === "") {
            this.date_location_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "montant_jour":
          //pour direction
          this.montant_jour_erreur = "";
          if (this.form.montant_jour === "") {
            this.montant_jour_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;

        case "id_partenaire":
          //pour direction
          this.id_partenaire_erreur = "";
          if (this.form.id_partenaire === "") {
            this.id_partenaire_erreur =
              "Vous avez oublié de sélectionner le partenaire concerné";
            return true;
          }
          break;
        case "nom_partenaire":
          //pour direction
          this.nom_partenaire_erreur = "";
          if (this.form.nom_partenaire === "" && this.partenaire) {
            this.nom_partenaire_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "description":
          //pour direction
          this.description_erreur = "";
          if (this.form.description === "" && this.partenaire) {
            this.description_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "contact":
          //pour direction
          this.contact_erreur = "";
          if (this.form.contact === "" && this.partenaire) {
            this.contact_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "adresse":
          //pour direction
          this.adresse_erreur = "";
          if (this.form.adresse === "" && this.partenaire) {
            this.adresse_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "email":
          //pour direction
          this.email_erreur = "";
          if (this.form.email === "" && this.partenaire) {
            this.email_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "type":
          //pour direction
          this.type_erreur = "";
          if (this.form.type === "" && this.partenaire) {
            this.type_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "exoneration":
          //pour direction
          this.exoneration_erreur = "";
          if (this.form.exoneration === "" && this.partenaire) {
            this.exoneration_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "boite_postale":
          //pour direction
          this.boite_postale_erreur = "";
          if (this.form.boite_postale === "" && this.partenaire) {
            this.boite_postale_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "date_debut":
          //pour direction
          this.date_debut_erreur = "";
          if (this.form.date_debut === "" && this.partenaire) {
            this.date_debut_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "date_fin":
          //pour direction
          this.date_fin_erreur = "";
          if (this.form.date_fin === "" && this.partenaire) {
            this.date_fin_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "id_direction":
          //pour direction
          this.id_direction_erreur = "";
          if (this.form.id_direction === "" && this.partenaire) {
            this.id_direction_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;

        default:
          break;
      }
      return false;
    },

    validatedataOld() {
      this.designation_erreur = "";
      this.id_direction_erreur = "";
      this.nombre_jour_erreur = "";
      this.date_location_erreur = "";
      this.montant_jour_erreur = "";
      this.nom_partenaire_erreur = "";
      this.description_erreur = "";
      this.contact_erreur = "";
      this.adresse_erreur = "";
      this.email_erreur = "";
      this.boite_postale_erreur = "";
      this.date_debut_erreur = "";
      this.date_fin_erreur = "";
      this.type_erreur = "";
      this.exoneration_erreur = "";
      var i = 0;

      if (this.form.designation === "") {
        this.designation_erreur = "Ce champ est obligatoire";

        i = 1;
      }
      if (this.form.nombre_jour === "") {
        this.nombre_jour_erreur = "Ce champ est obligatoire";

        i = 1;
      }
      if (this.form.date_location === "") {
        this.date_location_erreur = "Ce champ est obligatoire";

        i = 1;
      }
      if (this.form.montant_jour === "") {
        this.montant_jour_erreur = "Ce champ est obligatoire";

        i = 1;
      }

      if (this.form.nom_partenaire === "" && this.partenaire) {
        this.nom_partenaire_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.description === "" && this.partenaire) {
        this.description_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.contact === "" && this.partenaire) {
        this.contact_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.adresse === "" && this.partenaire) {
        this.adresse_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.email === "" && this.partenaire) {
        this.email_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.boite_postale === "" && this.partenaire) {
        this.boite_postale_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.date_debut === "" && this.partenaire) {
        this.date_debut_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.date_fin === "" && this.partenaire) {
        this.date_fin_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.id_direction === "" && this.partenaire) {
        this.id_direction_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.type === "" && this.partenaire) {
        this.type_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.exoneration === "" && this.partenaire) {
        this.exoneration_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (i == 1) return true;

      return false;
    },

    async get_partenaire() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Facture")) {
        await axios
          .get("/partenaire/index", { headers })
          .then((response) => {
            this.partenaires = response.data.partenaire;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des partenaires",
              "error"
            );
          });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission de lire cette fonctionnalité.");
      }
    },

    createPartenaire() {
      this.partenaire = true;

    },
    async get_direction() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Facture")) {
        await axios
          .get("/direction/index", { headers })
          .then((response) => {
            this.directions = response.data.direction;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recupération des directions",
              "error"
            );
          });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission de lire cette fonctionnalité.");
      }
    },

    async update_facture(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("designation", this.form.designation);
      formdata.append("nombre_jour", this.form.nombre_jour);
      formdata.append("montant_jour", this.form.montant_jour);
      formdata.append("date_location", this.form.date_location);
      formdata.append("partenaire", this.form.nom_partenaire);
      formdata.append("id_partenaire", this.form.id_partenaire);

      if (this.hasUpdatePermission("Facture")) {
        try {
          const user_store = await axios.post('/location/update/' + id, formdata, { headers });
          showDialog6("Facture modifiée avec succès");
          bus.emit('factureAjoutee');
          const eventData = {
            editModal: false,
          };
          bus.emit('factureDejaModifier', eventData);
        } catch (e) {
          console.log(e) 
          if (e.request.status === 404) {
            showDialog3("Une erreur est survenue lors de la modification");
          } else {
            showDialog3("Une erreur est survenue lors de la modification");
          }
        }
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission de modifier une facture.");
      }
    },

    monterToupdate(facture) {
      this.idFacture = facture.id;
      this.editModal = facture.editModal;
      this.form.designation = facture.designation;
      this.form.date_location = facture.date_location;
      this.form.nombre_jour = facture.nombre_jour;
      this.form.montant_jour = facture.montant_jour;
      this.form.nom_partenaire = facture.partenaire;
      this.form.id_partenaire = facture.id_partenaire;
      componentHandler.upgradeAllRegistered();
    },
  }
}
</script>
<style scoped>
.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  /* Spécifiez les dimensions du dropzone */
  width: 100%;
  height: 80%;
}

.uploaded-image {
  /* Spécifiez les dimensions de l'image téléchargée */
  width: 100%;
  height: 100%;
  float: left;
  object-fit: cover;
  /* Garantit que l'image couvre complètement le conteneur */
}
</style>