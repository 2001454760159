<template>
  <div class="col-lg-6 p-t-20">
    <div
      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
    >
      <label
        class="mdl-textfield__label"
        for="txtFirstName"
        v-show="!form.intitule"
        >Nom Matiére</label
      >
      <input
        class="mdl-textfield__input"
        type="text"
        id="txtFirstName"
        v-model="form.intitule"
        @input="validatedata('intitule')"
      />
      <span class="erreur">{{ this.intitule_erreur }}</span>
    </div>
  </div>
  <div
    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
  >
    <label class="mdl-textfield__label" for="txtName" v-show="!form.duree"
      >Durée Totale</label
    >
    <input
      class="mdl-textfield__input"
      type="text"
      id="txtName"
      v-model="form.duree"
      @input="validatedata('duree')"
    />
    <span class="erreur">{{ this.duree_erreur }}</span>
  </div>

  <div class="col-lg-12 p-t-20 text-center">
    <button
      type="submit"
      v-if="!this.editModal"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
      @click.prevent="validerAvantAjout()"
    >
      Enregistrer
    </button>
    <button
      type="submit"
      v-if="this.editModal"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
      @click.prevent="validerAvantAjout()"
    >
      Modifier
    </button>
    <button
      type="button"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
      @click="resetForm"
    >
      Annuler
    </button>
  </div>

  <div class="card card-box mt-4">
    <div class="card-head">
      <header>Liste des dernieres matiéres</header>
      <div class="tools">
        <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
        <a
          class="t-collapse btn-color fa fa-chevron-down"
          href="javascript:;"
        ></a>
        <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
      </div>
    </div>
    <div class="card-body">
      <table
        class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
        id="example47"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Matiére</th>
            <th>Durée</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="odd gradeX"
            v-for="(matiere, index) in matieres"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ matiere.intitule }}</td>
            <td>
              {{ matiere.duree }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    flatPickr,
  },
  mixins: [permissionMixin],
  props: ["direction"],
  name: "editMatiereCompenent",

  data() {
    return {
      users: [],
      form: new Form({
        intitule: "",
        duree: "",
      }),

      intitule_erreur: "",
      duree_erreur: "",
      id_classe_erreur: "",
      etatForm: false,
      editModal: false,
      matieres: [],
      classes: [],
      idMatiere: "",
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_matiere();
    bus.on("matiereModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.monterToupdate(eventData.matiere);
    });
  },

  /*  mounted() {
        this.get_matiere();
        bus.on('matiereModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.monterToupdate(eventData.matiere);
        });
    },
 */
  methods: {
    ...mapActions(["login", "logout"]),
    async soumettre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("intitule", this.form.intitule);
      formdata.append("duree", this.form.duree);
      if (this.hasCreatePermission("Matière")) {
        try {
          const create_store = await axios.post("/matiere/store", formdata, {
            headers,
          });
          showDialog6("Matiére ajoutée avec succès");
          bus.emit("matiereAjoutee;");
          this.resetForm();
          bus.emit("matiereAjoutee");
          setTimeout(() => {
            window.location.href = "/listeMatiere";
          }, 1500);
        } catch (e) {
          console.log(e)
          if (e.request.status === 404) {
            showDialog3("Cette matiére existe déjà");
          } else {
            showDialog3("Une erreur est survenue lors de l'enregistrement");
          }
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    verifCaratere(nom) {
      const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
      return valeur.test(nom);
    },

    validatedata(champ) {
      var i = 0;
      switch (champ) {
        case "intitule":
          this.intitule_erreur = "";
          // Effectuez la validation pour le champ 'nom'
          if (this.form.intitule === "") {
            this.intitule_erreur = "Ce champ est obligatoire";
            i = 1;
            return true;
          }
          if (!this.verifCaratere(this.form.intitule)) {
            this.intitule_erreur =
              "Ce champ ne peut comporter que des lettres et des espaces";
            /* this.erreur= "Ce champ ne peut comporter que des lettres et des espaces" */
            i = 1;
            return true;
          }

          break;

        case "classe":
          this.id_classe_erreur = "";
          //pour classe
          if (this.editModal) {
            if (this.form.id_classe === "") {
              this.id_classe_erreur =
                "Vous avez oublié de sélectionner  la classe'";
              i = 1;
              return true;
            }
          }
          break;

        default:
          break;
      }
    },

    validatedataOld() {
      this.intitule_erreur = "";
      this.duree = "";
      this.id_classe_erreur = "";
      var i = 0;

      if (this.form.intitule === "") {
        this.intitule_erreur = "Ce champ est obligatoire";
        i = 1;
      } else {
        if (!this.verifCaratere(this.form.intitule)) {
          this.intitule_erreur =
            "Ce champ ne peut comporter que des lettres et des espaces";
          i = 1;
        }
      }
      if (this.editModal) {
        if (this.form.id_classe === "") {
          this.id_classe_erreur =
            "Vous avez oublié de sélectionner le chef de direction ";
          i = 1;
        }
      }

      if (i == 1) return true;

      return false;
    },

    validerAvantAjout() {
      const isNomDirectionValid = this.validatedataOld();

      if (isNomDirectionValid === true) {
        this.etatForm = false;
        this.editModal = false;
        return 0;
      } else {
        if (this.editModal === true) {
          this.etatForm = false;
          this.form.intitule = this.form.intitule.toUpperCase();
          this.update_matiere(this.idMatiere);

          this.editModal = false;
        } else {
          this.etatForm = true;
          this.form.intitule = this.form.intitule.toUpperCase();
          this.soumettre();

          this.editModal = false;
        }
      }
    },

    resetForm() {
      this.form.intitule = "";
      this.form.id_user = "";
      this.editModal = false;
      this.intitule_erreur = "";
      this.id_user_erreur = "";
      const eventData = {
        editModal: false,
      };
      bus.emit("directionDejaModifier", eventData);
    },

    get_matiere() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Matière")) {
        axios
          .get("/matiere/get", { headers })
          .then((response) => {
            this.matieres = response.data.matiere;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernieres matieres",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async update_matiere(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("intitule", this.form.intitule);
      formdata.append("duree", this.form.duree);

      if (this.hasUpdatePermission("Matière")) {
        try {
          await axios.post("/matiere/update/" + id, formdata, { headers });
          showDialog6("Matiére modifiée avec succès");
          bus.emit("matiereAjoutee");
          const eventData = {
            editModal: false,
          };
          bus.emit("matiereDejaModifier", eventData);
        } catch (e) {
          console.log(e) 
          if (e.request.status === 404) {
            Swal.fire("Erreur !", "Cette matiére existe déjà", "error");
          } else {
            Swal.fire(
              "Erreur !",
              "Une erreur est survenue lors de l'enregistrement",
              "error"
            );
          }
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    monterToupdate(matiere) {
      this.idMatiere = matiere.id;
      this.editModal = matiere.editModal;
      this.form.intitule = matiere.intitule;
      this.form.duree = matiere.duree;
    },
  },
};
</script>
