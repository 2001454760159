import { mapState, mapActions } from "vuex";
import axios from "@/axios";
export default {
    data() {
        return {
            idleTimeout: null
        };
    },

    mounted() {
        this.resetIdleTimer();
        window.addEventListener('mousemove', () => this.resetIdleTimer());
        window.addEventListener('keypress', () => this.resetIdleTimer());
    },

    methods: {
        ...mapActions(['login', 'logout']),
        resetIdleTimer() {
            clearTimeout(this.idleTimeout);
            this.idleTimeout = setTimeout(() => {
                this.confirmLogout();
            }, 30 * 60 * 1000); // 30 minutes en millisecondes
        },
        /* confirmLogout() {
            if (localStorage.getItem('token')) {
                Swal.fire({
                    title: "Session expirée",
                    text: "Votre session a expiré en raison d'une inactivité prolongée. Voulez-vous vous déconnecter?",
                    icon: "warning",
                   
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        const token = localStorage.getItem('token');
                        const headers = { Authorization: `Bearer ${token}` };
                        axios.post("/logout", null, { headers });
                        localStorage.clear();
                        this.$router.push('/login');
                    } else {
                        location.reload();
                    }
                });
            }

        }, */
        confirmLogout() {
            if (localStorage.getItem('token')) {
                Swal.fire({
                    title: "Session expirée",
                    text: "Votre session a expiré en raison d'une inactivité prolongée. Vous serez automatiquement déconnecté.",
                    icon: "warning",
                    showConfirmButton: false, // Masquer le bouton "OK"
                    timer: 5000, // Définir le délai d'affichage en millisecondes (ici, 3 secondes)
                }).then(() => {
                    // Déclencher la déconnexion automatique après le délai spécifié
                    const token = localStorage.getItem('token');
                    const headers = { Authorization: `Bearer ${token}` };
                    axios.post("/logout", null, { headers });
                    localStorage.clear();
                    this.$router.push('/login');
                });
            }
        },
        
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.resetIdleTimer);
        window.removeEventListener('keypress', this.resetIdleTimer);
    }
};
