<template>
    <div class="page-wrapper">

        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper" v-if="this.viewListe">

                <!-- start page content -->
                <div class="page-content">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">

                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                        :href="'/dashboard-saf'">Tableau
                                        de
                                        bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>

                                <li class="active"> Recouvrement </li>

                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tabbable-line">
                                <ul class="nav customtab nav-tabs" role="tablist">
                                    <button @click="filtre()"
                                        class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-info"
                                        data-bs-toggle="tab">Filtre</button>
                                    <div class="paiemen" style="display: flex;" v-if="this.viewbutton === true">
                                        <button
                                            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-success"
                                            style="width: 150px; background-color: var(--clr); color: rgb(152, 14, 14); border: 1px solid; outline: none; gap: 0; margin-left: 10%;"
                                            @click="imprimerEnPDF()">
                                            <i class="fi fi-rr-bars-sort" style="color: white;"></i>
                                            <span>Imprimer</span>
                                        </button>

                                    </div>


                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane active fontawesome-demo" id="tab1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="card card-box" id="contenu-a-imprimer">
                                                    <div class="card-head" v-if="this.partenaire_selected !== ''">
                                                        <header>Liste recouvrement {{
                    partenaire_selected.partenaire.nom - partenaire }} {{
                    partenaire_selected.date }} {{
                    partenaire_selected.location.designation }}
                                                        </header>

                                                        <div class="tools">
                                                            <a class="fa fa-repeat btn-color box-refresh"
                                                                href="javascript:;"></a>
                                                            <a class="t-collapse btn-color fa fa-chevron-down"
                                                                href="javascript:;"></a>
                                                            <a class="t-close btn-color fa fa-times"
                                                                href="javascript:;"></a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body ">
                                                    </div>
                                                    <table
                                                        class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                                                        id="example47" style="width: 100%">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Designation</th>
                                                                <th>Salle</th>
                                                                <th>Partenaire</th>
                                                                <th>Nombre jours</th>
                                                                <th>Montant/jour</th>
                                                                <th>Montant</th>
                                                                <th>Date Délivrance</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr class="odd gradeX" v-for="(facture, index) in factures">
                                                                <td class="left"> {{ facture.id }}{{
                    formatDate(facture.created_at) }}</td>
                                                                <td class="left">
                                                                    {{ facture.location.designation }}
                                                                </td>
                                                                <td class="left">{{ facture.location.salle.intitule }}
                                                                </td>
                                                                <td class="left">{{
                    facture.location.partenaire.nom_partenaire }}</td>
                                                                <td class="left">
                                                                    {{ facture.location.nombre_jour }}
                                                                </td>
                                                                <td class="left">
                                                                    {{ facture.location.montant_jour }}
                                                                </td>
                                                                <td class="left">
                                                                    {{ facture.location.montant_jour *
                                                                    facture.location.nombre_jour }}
                                                                </td>
                                                                <td class="left">
                                                                    {{ facture.date_facture }}
                                                                </td>

                                                            </tr>
                                                            <tr>
                                                                <td colspan="7" class="left font-bold addr-font-h4">
                                                                    Cumul
                                                                </td>
                                                                <td>
                                                                    {{ this.cumul }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import bus from '../../eventBus';
import html2pdf from 'html2pdf.js';
import 'datatables.net-buttons-bs5/js/buttons.bootstrap5';
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import permissionMixin from "@/permission";

export default {
    components: {
        appnavbar,
        appfooter,
        appheader,

    },
    name: "listeRecouvrementSaf",
    data() {
        return {
            factures: [],
            cumul: "",
            viewbutton: false,
            partenaire_selected: "",
            annee_selected: "",
            viewListe: false,
            /* idService: "", */
        }
    },
    computed: {
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },

    mounted() {
        bus.on('nouveauFiltre', (eventData) => {
            this.initDataTable();
            this.viewListe = eventData.viewListe;
            this.factures = eventData.factures
            this.cumul = eventData.cumul
            if (this.factures.length > 0) {
                this.viewbutton = true;
            }
        });
    },

    methods: {
        ...mapActions(["login", "logout"]),
        initDataTable() {
            this.$nextTick(() => {
                if (!$.fn.DataTable.isDataTable('#example47')) {
                    $('#example47').DataTable({
                        dom: "Bfrtip",
                        buttons: ["copy", "csv", "excel", "pdf", "print"],
                        responsive: true,
                        "autoWidth": true,
                        paginate: false,
                        searching: false, // Désactive la barre de recherche

                        language: {
                            paginate: {
                                first: 'Premier',
                                previous: 'Précédent',
                                next: 'Suivant',
                                last: 'Dernier'
                            },
                            // Message d'affichage du nombre d'éléments par page
                            lengthMenu: 'Afficher _MENU_ entrées',
                            // Message d'information sur le nombre total d'entrées et le nombre affiché actuellement
                            info: 'Affichage de _START_ à _END_ sur _TOTAL_ entrées',
                            // Message lorsque le tableau est vide
                            emptyTable: 'Aucune donnée disponible dans le tableau',
                            // Message indiquant que la recherche est en cours
                            loadingRecords: 'Chargement en cours...',
                            // Message indiquant que la recherche n'a pas renvoyé de résultats
                            zeroRecords: 'Aucun enregistrement correspondant trouvé',
                            // Message indiquant le nombre total d'entrées
                            infoEmpty: 'Affichage de 0 à 0 sur 0 entrées',
                            // Message indiquant que la recherche est en cours dans le champ de recherche
                            search: 'Recherche :'
                        }
                    });
                }
            });
        },

        getImageUrl(url) {
            //return url ? `${window.location.origin}/storage/${url}` : '';
            //return url ? `http://127.0.0.1:8000/storage/${url}` : '';
            return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
        },

        imprimerEnPDF() {
            const element = document.getElementById('contenu-a-imprimer'); // Remplacez 'contenu-a-imprimer' par l'ID de votre élément
            html2pdf(element);
        },

        filtre() {
            const eventData = {
                viewListe: false
            };

            bus.emit('ancienFiltre', eventData);
        },
        formatDate(date) {
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            return year + month + day;
        },

    }
}
</script>