<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboard'"
                    >Accueil</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>

                <li class="active">Audit &nbsp;</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab"
                      >Audits</a
                    >
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Liste Audits</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <!--  -->
                            <div class="row align-items-end card-head">
                              <div class="col-sm-12 col-md-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2"
                                    >Recherche :</label
                                  >
                                  <input
                                    type="text"
                                    id="searchInput"
                                    placeholder=""
                                    aria-controls="example47"
                                    v-model="searchQuery"
                                    @input="get_audit()"
                                  />
                                </div>
                              </div>
                            </div>

                            <!--  -->
                            <div class="">
                              <div class="">
                                <div class="card-body">
                                  <div class="row">
                                    <ul
                                      class="docListWindow small-slimscroll-style"
                                    >
                                      <li v-for="audit in audits">
                                        <div class="prog-avatar">
                                          <img
                                            :src="
                                              this.getImageUrl(audit.user.photo)
                                            "
                                            alt=""
                                            width="40"
                                            height="40"
                                          />
                                        </div>
                                        <div class="details">
                                          <div class="title">
                                            <a href="#"
                                              >{{ audit.user.nom }}
                                              {{ audit.user.prenom }}</a
                                            >
                                            -{{ formatTime(audit.created_at) }}
                                          </div>
                                          <div
                                            v-show="
                                              audit.action == 'Suppression'
                                            "
                                          >
                                            <span
                                              class="feedLblStyle lblFileStyle"
                                              >{{ audit.action }}</span
                                            >
                                            {{ audit.details }}
                                            <small class="text-muted">{{
                                              formatDateTime(audit.created_at)
                                            }}</small>
                                          </div>
                                          <div
                                            v-show="audit.action == 'Creation'"
                                          >
                                            <span
                                              class="feedLblStyle lblTaskStyle"
                                              >{{ audit.action }}</span
                                            >
                                            {{ audit.details }}
                                            <small class="text-muted">{{
                                              formatDateTime(audit.created_at)
                                            }}</small>
                                          </div>
                                          <div
                                            v-show="
                                              audit.action == 'Modification'
                                            "
                                          >
                                            <span
                                              class="feedLblStyle lblCommentStyle"
                                              >{{ audit.action }}</span
                                            >
                                            {{ audit.details }}
                                            <small class="text-muted">{{
                                              formatDateTime(audit.created_at)
                                            }}</small>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-content-wrapper" v-show="editModal">
          <div class="page-content">
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">Modifier Classe</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li>
                    <i class="fa fa-home"></i>&nbsp;<a
                      class="parent-item"
                      :href="'/dashboard'"
                      >Tableau de Bord</a
                    >&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li>
                    <a class="parent-item" :href="'/liste-classe'">Classe</a
                    >&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li class="active">Modifier Classe</li>
                </ol>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card-box">
                  <div class="card-head">
                    <header>Information</header>
                    <button
                      id="panel-button"
                      class="mdl-button mdl-js-button mdl-button--icon pull-right"
                      data-upgraded=",MaterialButton"
                    >
                      <i class="material-icons">more_vert</i>
                    </button>
                    <ul
                      class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                      data-mdl-for="panel-button"
                    >
                      <li class="mdl-menu__item">
                        <i class="material-icons">assistant_photo</i>Action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">print</i>Another action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">favorite</i>Something else
                        here
                      </li>
                    </ul>
                  </div>
                  <div class="card-body row">
                    <!--    <FormulaireModification /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <appafterContent />
      </div>
      <appfooter />
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import Form from "vform";
//import //FormulaireModification from './editClasse.vue';
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    //FormulaireModification,
  },
  mixins: [permissionMixin],
  name: "listeClasseCompenent",

  data() {
    return {
      form: new Form({
        nom_classe: "",
        type_classe: "",
        niveau: "",
        id_type_formation: "",
        id_unite_de_formation: "",
      }),
      classes: [],
      audits: [],
      editModal: false,
      idClasse: "",
      searchQuery: "",
    };
  },

  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_audit();
  },

  /* mounted() {
    this.get_audit();
  }, */

  methods: {
    ...mapActions(["login", "logout"]),
    initDataTable() {
      this.$nextTick(() => {
        if (!$.fn.DataTable.isDataTable("#example47")) {
          $("#example47").DataTable({
            responsive: true,

            // ... (autres options)
            language: {
              // Messages pour la pagination
              paginate: {
                first: "Premier",
                previous: "Précédent",
                next: "Suivant",
                last: "Dernier",
              },
              // Message d'affichage du nombre d'éléments par page
              lengthMenu: "Afficher _MENU_ entrées",
              // Message d'information sur le nombre total d'entrées et le nombre affiché actuellement
              info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
              // Message lorsque le tableau est vide
              emptyTable: "Aucune donnée disponible dans le tableau",
              // Message indiquant que la recherche est en cours
              loadingRecords: "Chargement en cours...",
              // Message indiquant que la recherche n'a pas renvoyé de résultats
              zeroRecords: "Aucun enregistrement correspondant trouvé",
              // Message indiquant le nombre total d'entrées
              infoEmpty: "Affichage de 0 à 0 sur 0 entrées",
              // Message indiquant que la recherche est en cours dans le champ de recherche
              search: "Recherche :",
            },
          });
        }
      });
    },
    /*     async get_audit() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/audit/all", { headers })
        .then((response) => {
          this.audits = response.data.audit;
          this.initDataTable();
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération des audit",
            "error"
          );
        });
    }, */

    /*   async get_audit() {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    const filterCondition = (audit) => true; // Condition de filtre à définir
    try {
        const response = await axios.get("/audit/all", { headers });
        const allAudit = response.data.audit;
        console.log(allAudit)

        const filteredAudits = allAudit.filter((audit) => {
            return (
                filterCondition(audit) &&
                Object.keys(audit).reduce((acc, key) => {
                    const value = audit[key];
                    if (typeof value === "string") {
                        acc.push(value.toLowerCase());
                    } else if (typeof value === "number") {
                        acc.push(value.toString().toLowerCase());
                    } else if (value && typeof value === "object") {
                        Object.values(value).forEach((nestedValue) => {
                            if (typeof nestedValue === "string") {
                                acc.push(nestedValue.toLowerCase());
                            } else if (typeof nestedValue === "number") {
                                acc.push(nestedValue.toString().toLowerCase());
                            }
                        });
                    }
                    return acc;
                }, []).some((value) => value.includes(this.searchQuery.toLowerCase()))
            );
        });

        this.audits = filteredAudits;
        this.initDataTable();
    } catch (error) {
        Swal.fire("Erreur!", "Une erreur est survenue lors de la récupération des audits", "error");
    }
}, */

    async get_audit() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const filterCondition = (audit) => true; // Condition de filtre à définir
      if (this.hasReadPermission("Audit")) {
        try {
          const response = await axios.get("/audit/all", { headers });
          const allAudit = response.data.audit;

          const filteredAudits = allAudit.filter((singleAudit) => {
            return (
              filterCondition(singleAudit) &&
              Object.keys(singleAudit)
                .reduce((acc, key) => {
                  const value = singleAudit[key];
                  if (typeof value === "string") {
                    acc.push(value.toLowerCase());
                  } else if (typeof value === "number") {
                    acc.push(value.toString().toLowerCase());
                  } else if (value && typeof value === "object") {
                    Object.values(value).forEach((nestedValue) => {
                      if (typeof nestedValue === "string") {
                        acc.push(nestedValue.toLowerCase());
                      } else if (typeof nestedValue === "number") {
                        acc.push(nestedValue.toString().toLowerCase());
                      }
                    });
                  }
                  return acc;
                }, [])
                .some((value) => value.includes(this.searchQuery.toLowerCase()))
            );
          });
         // console.log(filteredAudits);

          this.audits = filteredAudits;
          this.initDataTable();
        } catch (error) {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la récupération des audits",
            "error"
          );
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    formatDateTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.formatDate(new Date(dateTime));
    },
    formatDate(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      // Tableau des trois premières lettres des mois en français
      const monthAbbreviations = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];

      // Obtenez les trois premières lettres du mois correspondant au numéro du mois
      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },
    formatTime(timerecup) {
      return this.getTime(new Date(timerecup));
    },

    getTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // Ajoutez un zéro devant les heures ou les minutes si elles sont inférieures à 10
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${formattedHours}:${formattedMinutes}`;
    },

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : "";
      //return url ? `http://127.0.0.1:8000/storage/${url}` : '';
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },

    changement(event) {
      this.interesser = event;
    },

    resetForm() {
      this.form.input = "";
      this.form.nom_classe = "";
      this.form.type_classe = "";
      this.form.niveau = "";
      this.form.id_type_formation = "";
      this.form.id_unite_de_formation = "";
    },
  },
};
</script>
