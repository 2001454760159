<template>
  <div class="col-lg-12 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label" for="txtNamePartenaire" v-show="!form.nom_partenaire">Nom
                          Partenaire</label>
                        <input class="mdl-textfield__input" type="text" id="txtNamePartenaire"
                          v-model="form.nom_partenaire" @input="validatedata('nom_partenaire')" />
                        <span class="erreur">{{
          this.nom_partenaire_erreur
        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label" for="txtDescription"
                          v-show="!form.description">Description</label>
                        <input class="mdl-textfield__input" type="text" id="txtDescription" v-model="form.description"
                          @input="validatedata('description')" />
                        <span class="erreur">{{
          this.description_erreur
        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label" for="text5" v-show="!form.contact">Numero Telephone</label>
                        <input class="mdl-textfield__input" type="text" pattern="-?[0-9]*(\.[0-9]+)?" id="text5"
                          v-model="form.contact" @input="validatedata('contact')" />
                        <span class="erreur">{{ this.contact_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label" for="designation2" v-show="!form.adresse">Adresse</label>
                        <input class="mdl-textfield__input" type="text" id="designation2" v-model="form.adresse"
                          @input="validatedata('adresse')" />
                        <span class="erreur">{{ this.adresse_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label" for="txtemail" v-show="!form.email">Email</label>
                        <input class="mdl-textfield__input" type="mail" id="txtemail" v-model="form.email"
                          @input="validatedata('email')" />
                        <span class="erreur">{{ this.email_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label" for="txtpostal" v-show="!form.boite_postale">Boite
                          Postal</label>
                        <input class="mdl-textfield__input" type="text" id=" if (this.editModal) {"
                          v-model="form.boite_postale" @input="validatedata('boite_postale')" />
                        <span class="erreur">{{
          this.boite_postale_erreur
        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label" for="dateOfBirth" v-show="!form.date_debut">Date
                          début</label>
                        <flat-pickr v-model="form.date_debut" class="mdl-textfield__input"
                          @input="validatedata('date_debut')"></flat-pickr>
                        <span class="erreur">{{ this.date_debut_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                        <label class="mdl-textfield__label" for="dateOfBirth" v-show="!form.date_fin">Date fin</label>
                        <flat-pickr v-model="form.date_fin" class="mdl-textfield__input"
                          @input="validatedata('date_fin')"></flat-pickr>
                        <span class="erreur">{{ this.date_fin_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-6 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                        <label for="list3" class="mdl-textfield__label" v-show="!form.type">Type</label>
                        <select class="mdl-textfield__input" id="list3" readonly tabIndex="-1" v-model="form.type"
                          @change="validatedata('type')">
                          <option value="Secteur Privé">Secteur Privé</option>
                          <option value="Secteur Public">Secteur Public</option>
                          <option value="Ecole">Ecole</option>
                          <option value="ONG">ONG</option>
                        </select>
                        <span class="erreur">{{ this.type_erreur }}</span>
                      </div>
                    </div>

                    <div class="col-lg-6 p-t-20">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                        <label for="list3" class="mdl-textfield__label" v-show="form.exoneration">Exoneration</label>
                        <select class="mdl-textfield__input" id="list3" readonly tabIndex="-1"
                          v-model="form.exoneration" @change="validatedata('exoneration')">
                          <option value="1">OUI</option>
                          <option value="0">NON</option>
                        </select>
                        <span class="erreur">{{
          this.exoneration_erreur
        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-12 p-t-20 mt-1">
                      <div
                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                        <label class="mdl-textfield__label" for="partenaireSelect" v-show="!form.id_direction">
                          Direction
                        </label>

                        <select class="mdl-textfield__input" id="partenaireSelect" readonly tabIndex="-1"
                          v-model="form.id_direction" @change="validatedata('id_direction')">
                          <option v-for="(direction, index) in directions" :value="direction.id" :key="index">
                            {{ direction.nom_direction }}
                          </option>
                        </select>

                        <span class="erreur">{{ id_direction_erreur }}</span>
                      </div>
                    </div>

    <div class="col-lg-12 p-t-20 text-center">
        <button type="submit" v-if="!this.editModal"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
            @click.prevent="validerAvantAjout()">
            Enregistrer
        </button>
        <button type="submit" v-if="this.editModal"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
            @click.prevent="validerAvantAjout()">
            Modifier
        </button>
        <button type="button"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
            @click="resetForm">
            Annuler
        </button>
    </div>

   
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import { mapState, mapActions } from "vuex";
import flatPickr from "vue-flatpickr-component"; 
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
    name: "listePartenaireComponent",
    components: {
        appnavbar,
        flatPickr,
        appfooter,
        appheader,
        appafterContent,
        PaginateComponent,
    },
    mixins: [permissionMixin],
    data() {
    return {
      partenaires: [],
      directions: [],
      form: new Form({
        nom_partenaire: "",
        description: "",
        contact: "",
        adresse: "",
        email: "",
        boite_postale: "",
        date_debut: "",
        date_fin: "",
        type: "",
        exoneration: "",
      }),

      nom_partenaire_erreur: "",
      description_erreur: "",
      contact_erreur: "",
      adresse_erreur: "",
      email_erreur: "",
      boite_postale_erreur: "",
      date_debut_erreur: "",
      date_fin_erreur: "",
      id_direction_erreur: "",
      type_erreur: "",
      exoneration_erreur: "",
      idPartenaire: "",
      etatForm: false,
      editModal: false,

    };
  },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },
    async created() {
        await this.get_permission();
        this.get_partenaire();
        this.get_direction();

        bus.on('partenaireModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.monterToupdate(eventData.partenaire);
        });
    },

    methods: {
        ...mapActions(["login", "logout"]),

        pagPage(pagination) {
            this.current_page = pagination;
            this.get_partenaire(this.currentPage);
        },
        updatePerPage(event) {
            // Récupérer la nouvelle valeur sélectionnée
            const newPerPage = parseInt(event.target.value);

            // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
            this.reloadDataWithPerPage(newPerPage);
        },
        reloadDataWithPerPage(newPerPage) {
            // Mettre à jour la limite de la requête avec la nouvelle quantité par page
            if (this.limitQuery !== 5) {
                this.limitQuery = newPerPage;
                // Recharger les données avec la nouvelle quantité par page
                this.get_partenaire(this.currentPage);
            }
        },
        validatedata(champ) {
      var i = 0;
      switch (champ) {
        case "nom_partenaire":
          //pour direction
          this.nom_partenaire_erreur = "";
          if (this.form.nom_partenaire === "") {
            this.nom_partenaire_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "description":
          //pour direction
          this.description_erreur = "";
          if (this.form.description === "") {
            this.description_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "contact":
          //pour direction
          this.contact_erreur = "";
          if (this.form.contact === "") {
            this.contact_erreur = "Ce champ est obligatoire";
            return true;
          } else if (
            !this.validatePhoneNumber(this.form.contact) &&
            this.partenaire
          ) {
            this.contact_erreur = "Le numéro de téléphone n'est pas valide";
            return true;
          }
          break;
        case "adresse":
          //pour direction
          this.adresse_erreur = "";
          if (this.form.adresse === "") {
            this.adresse_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "email":
          //pour direction
          this.email_erreur = "";
          if (this.form.email === "") {
            this.email_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "type":
          //pour direction
          this.type_erreur = "";
          if (this.form.type === "") {
            this.type_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "exoneration":
          //pour direction
          this.exoneration_erreur = "";
          if (this.form.exoneration === "") {
            this.exoneration_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "boite_postale":
          //pour direction
          this.boite_postale_erreur = "";
          if (this.form.boite_postale === "") {
            this.boite_postale_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "date_debut":
          //pour direction
          this.date_debut_erreur = "";
          if (this.form.date_debut === "") {
            this.date_debut_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "date_fin":
          //pour direction
          this.date_fin_erreur = "";
          if (this.form.date_fin === "") {
            this.date_fin_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;
        case "id_direction":
          //pour direction
          this.id_direction_erreur = "";
          if (this.form.id_direction === "") {
            this.id_direction_erreur = "Ce champ est obligatoire";
            return true;
          }
          break;

        default:
          break;
      }
    },

 validatedataOld() {
      this.intitule_erreur = "";
      this.duree = "";
      this.id_classe_erreur = "";
      var i = 0;

      if (this.form.nom_partenaire === "") {
        this.nom_partenaire_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.description === "") {
        this.description_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.contact === "") {
        this.contact_erreur = "Ce champ est obligatoire";
        i = 1;
      } else if (
        !this.validatePhoneNumber(this.form.contact) &&
        this.partenaire
      ) {
        this.contact_erreur = "Le numéro de téléphone n'est pas valide";
        i = 1;
      }

      if (this.form.adresse === "") {
        this.adresse_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.email === "") {
        this.email_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.boite_postale === "") {
        this.boite_postale_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.date_debut === "") {
        this.date_debut_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.date_fin === "") {
        this.date_fin_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.id_direction === "") {
        this.id_direction_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.type === "") {
        this.type_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.form.exoneration === "") {
        this.exoneration_erreur = "Ce champ est obligatoire";
        i = 1;
      }

      if (i == 1) return true;

      return false;
    },

    validerAvantAjout() {
      const isNomDirectionValid = this.validatedataOld();

      if (isNomDirectionValid === true) {
        this.etatForm = false;
        this.editModal = false;
        return 0;
      } else {
        if (this.editModal === true) {
          this.etatForm = false;
          this.form.nom_partenaire = this.form.nom_partenaire.toUpperCase();
          this.update_partenaire(this.idPartenaire);

          this.editModal = false;
        } else {
          this.etatForm = true;
          this.form.nom_partenaire = this.form.nom_partenaire.toUpperCase();
          this.soumettre();

          this.editModal = false;
        }
      }
    },

    resetForm() {
      this.form.nom_partenaire = "";
      this.form.description = "";
      this.form.contact = "";
      this.form.adresse = "";
      this.form.email = "";
      this.form.boite_postale = "";
      this.form.date_debut = "";
      this.form.date_fin = "";
      this.form.exoneration = "";
      this.form.id_direction = "";
      this.form.type = "";

      this.nom_partenaire_erreur = "";
      this.description_erreur = "";
      this.contact_erreur = "";
      this.adresse_erreur = "";
      this.email_erreur = "";
      this.boite_postale_erreur = "";
      this.date_debut_erreur = "";
      this.date_fin_erreur = "";
      this.id_direction_erreur = "";
      this.type_erreur = "";
      this.exoneration_erreur = "";
   
      this.editModal = false;
    
      const eventData = {
        editModal: false,
      };
      bus.emit("partenaireDejaModifier", eventData);
    },

    verifCaratere(nom) {
      const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
      return valeur.test(nom);
    },

    validatePhoneNumber(phoneNumber) {
      // Expression régulière pour vérifier le numéro de téléphone (format simple ici)
      const phoneRegex = /^\d{9}$/; // Format : 9 chiffres
      return phoneRegex.test(phoneNumber);
    },

        async get_direction() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Partenaire")) {
        await axios
          .get("/direction/index", { headers })
          .then((response) => {
            this.directions = response.data.direction;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recupération des directions",
              "error"
            );
          });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission de lire cette fonctionnalité.");
      }
    },

    get_partenaire() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Partenaire")) {
        axios
          .get("/partenaire/get/last", { headers })
          .then((response) => {
            this.partenaires = response.data.partenaire;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des derniers partenaires",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

        monterToupdate(partenaire) {
            this.idPartenaire = partenaire.id;
            this.editModal = partenaire.editModal;

            this.form.id = partenaire.id;
            this.form.nom_partenaire = partenaire.nom_partenaire;
            this.form.description = partenaire.description;
            this.form.contact = partenaire.contact;
            this.form.adresse = partenaire.adresse;
            this.form.email = partenaire.email;
            this.form.boite_postale = partenaire.boite_postale;
            this.form.date_debut = partenaire.date_debut;
            this.form.date_fin = partenaire.date_fin;
            this.form.id_direction = partenaire.id_direction;
            this.form.type = partenaire.type;
            this.form.exoneration = partenaire.exoneration;
  

        },

        async update_partenaire(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("nom_partenaire", this.form.nom_partenaire);
      formdata.append("description", this.form.description);
      formdata.append("contact", this.form.contact);
      formdata.append("adresse", this.form.adresse);
      formdata.append("email", this.form.email);
      formdata.append("boite_postale", this.form.boite_postale);
      formdata.append("date_debut", this.form.date_debut);
      formdata.append("date_fin", this.form.date_fin);
      formdata.append("id_direction", this.form.id_direction);
      formdata.append("type", this.form.type);
      formdata.append("exoneration", this.form.exoneration);
    //  formdata.append("id_user", this.userIdrole);
      if (this.hasUpdatePermission("Partenaire")) {
        try {
          await axios.post("/partenaire/update/" + id, formdata, { headers });
          showDialog6("Partenaire modifiée avec succès");
          bus.emit("partenaireAjoutee");
          const eventData = {
            editModal: false,
          };
          bus.emit("partenaireDejaModifier", eventData);
        } catch (e) {
          console.log(e)
          if (e.request.status === 404) {
            Swal.fire("Erreur !", "Ce partenaire existe déjà", "error");
          } else {
            Swal.fire(
              "Erreur !",
              "Une erreur est survenue lors de la modification",
              "error"
            );
          }
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
   
    },
};
</script>