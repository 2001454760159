<template>
  <div class="page-wrapper">
    <!-- start header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />
      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Créer un livre</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="index.html">Home</a>&nbsp;<i class="fa fa-angle-right"></i></li>
                <li class="active">Creer un livre</li>
              </ol>
            </div>
          </div>
          
          <div class="card">
            <div class="card-header">
              <h3>Informations du livre</h3>
            </div>
            <div class="card-body">
            <form  class="form-horizontal">
                <div class="row">
                  <div class="col-lg-12 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="titre_livre">Titre</label>
                      <input class="mdl-textfield__input" type="text" id="titre_livre" v-model="form.titre_livre" @input="validatedata('titre_livre')" />
                      <span class="erreur">{{ errors.titre_livre }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="genre">Genre</label>
                      <input class="mdl-textfield__input" type="text" id="genre" v-model="form.genre" @input="validatedata('genre')" />
                      <span class="erreur">{{ errors.genre }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="annee_publication">Année de publication</label>
                      <flat-pickr v-model="form.annee_publication" class="mdl-textfield__input" :config="{ dateFormat: 'Y' }" @input="validatedata('annee_publication')"></flat-pickr>
                      <span class="erreur">{{ errors.annee_publication }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="exemplaire_disponible">Exemplaires disponibles</label>
                      <input class="mdl-textfield__input" type="number" id="exemplaire_disponible" v-model="form.exemplaire_disponible" @input="validatedata('exemplaire_disponible')" />
                      <span class="erreur">{{ errors.exemplaire_disponible }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="id_categorie" v-show="!form.id_categorie">Catégorie</label>
                      <select class="mdl-textfield__input" id="id_categorie" v-model="form.id_categorie" @change="validatedata('id_categorie')">
                        <option value="" disabled></option>
                        <option v-for="categorie in categories" :value="categorie.id">{{ categorie.intitule }}</option>
                      </select>
                      <span class="erreur">{{ errors.id_categorie }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="id_auteur" v-show="!form.id_auteur">Auteur</label>
                      <select class="mdl-textfield__input" id="id_auteur" v-model="form.id_auteur" @change="validatedata('id_auteur')">
                        <option value="" disabled></option>
                        <option v-for="auteur in auteurs" :value="auteur.id">{{ auteur.nom_auteur }}</option>
                      </select>
                      <span class="erreur">{{ errors.id_auteur }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="id_edition" v-show="!form.id_edition">Édition</label>
                      <select class="mdl-textfield__input" id="id_edition" v-model="form.id_edition" @change="validatedata('id_edition')">
                        <option value="" disabled></option>
                        <option v-for="edition in editions" :value="edition.id">{{ edition.nom_edition }}</option>
                      </select>
                      <span class="erreur">{{ errors.id_edition }}</span>
                    </div>
                  </div>
                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      v-if="!this.editModal"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      :disabled="is_loading"
                      @click.prevent="validerAvantAjout()"
>
                      {{ is_loading ? "Veuillez patienter..." : "Enregistrer" }}
                      <div v-show="is_loading" class="mdl-spinner mdl-js-spinner is-active"></div>
                    </button>


                   
                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
          </form>
            </div>
          </div>
          <!-- end form content -->
        </div> <!-- end page-content -->
      </div> <!-- end page-content-wrapper -->
    </div> <!-- end page-container -->
  </div> <!-- end page-wrapper -->
</template>

<script>
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import axios from "@/axios";
import Form from 'vform';
import Swal from 'sweetalert2';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: "createLivreComponent",
  components: {
    appnavbar,
    appfooter,
    appheader,
    flatPickr,
  },
  computed: {
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  data() {
    return {
      form: new Form({
        titre_livre: "",
        genre: "",
        annee_publication: "",
        exemplaire_disponible: "",
        id_categorie: "",
        id_auteur: "",
        id_edition: ""
      }),
      categories: [],
      auteurs: [],
      editions: [],
      is_loading: false,
      editModal: false,
      errors: {
        titre_livre: "",
        genre: "",
        annee_publication: "",
        exemplaire_disponible: "",
        id_categorie: "",
        id_auteur: "",
        id_edition: ""
      }
    }
  },
  mounted() {
    this.getCategories();
    this.getAuteurs();
    this.getEditions();
  },
  methods: {
    validatedata(champ) {
      let isValid = true;

      switch (champ) {
        case "titre_livre":
          this.errors.titre_livre = "";
          if (!this.form.titre_livre) {
            this.errors.titre_livre = "Ce champ est obligatoire";
            isValid = false;
          }
          break;

        case "genre":
          this.errors.genre = "";
          if (!this.form.genre) {
            this.errors.genre = "Ce champ est obligatoire";
            isValid = false;
          }
          break;

        case "annee_publication":
          this.errors.annee_publication = "";
          const year = new Date(this.form.annee_publication).getFullYear();
          if (isNaN(year) || year <= 0) {
            this.errors.annee_publication = "Veuillez entrer une année valide";
            isValid = false;
          }
          break;

        case "exemplaire_disponible":
          this.errors.exemplaire_disponible = "";
          if (isNaN(this.form.exemplaire_disponible) || this.form.exemplaire_disponible <= 0) {
            this.errors.exemplaire_disponible = "Veuillez entrer une valeur valide";
            isValid = false;
          }
          break;

        case "id_categorie":
          this.errors.id_categorie = "";
          if (!this.form.id_categorie) {
            this.errors.id_categorie = "Ce champ est obligatoire";
            isValid = false;
          }
          break;

        case "id_auteur":
          this.errors.id_auteur = "";
          if (!this.form.id_auteur) {
            this.errors.id_auteur = "Ce champ est obligatoire";
            isValid = false;
          }
          break;

        case "id_edition":
          this.errors.id_edition = "";
          if (!this.form.id_edition) {
            this.errors.id_edition = "Ce champ est obligatoire";
            isValid = false;
          }
          break;

        default:
          break;
      }

      return isValid;
    },
    validerAvantAjout() {
      const champs = ["titre_livre", "genre", "annee_publication", "exemplaire_disponible", "id_categorie", "id_auteur", "id_edition"];
      let isValid = true;
      for (const champ of champs) {
        if (!this.validatedata(champ)) {
          isValid = false;
        }
      }

      if (isValid) {
        if (this.editModal) {
          this.updateLivre();
        } else {
          this.soumettre();
        }
      } 
    },
    resetForm() {
      this.form.reset();
      this.errors = {
        titre_livre: "",
        genre: "",
        annee_publication: "",
        exemplaire_disponible: "",
        id_categorie: "",
        id_auteur: "",
        id_edition: ""
      };
    },
    async soumettre() {
      this.is_loading = true;
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const response = await axios.post('/livre/store', this.form, { headers });

        if (response.status === 200) {
          Swal.fire('Succès!', 'Livre enregistré avec succès', 'success');
          this.resetForm();
          this.$router.push('/list-livre'); // Redirection vers /list-livre après le succès
        } else {
          Swal.fire('Erreur!', response.data.message || 'Une erreur est survenue lors de l\'enregistrement', 'error');
        }
      } catch (error) {
        console.error("Erreur lors de la soumission du livre :", error);
        Swal.fire('Erreur!', 'Une erreur est survenue lors de l\'enregistrement', 'error');
      }

      this.is_loading = false;
    },
    async getCategories() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios.get("/categorie/index", { headers }).then((response) => {
        this.categories = response.data.categorie;
      }).catch((error) => {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des catégories', 'error');
      });
    },
    async getAuteurs() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios.get("/auteur/index", { headers }).then((response) => {
        this.auteurs = response.data.auteur;
      }).catch((error)=> {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des auteurs', 'error');
      });
    },
    async getEditions() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios.get("/edition/index", { headers }).then((response) => {
        this.editions = response.data.edition;
      }).catch((error) => {
        Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des éditions', 'error');
      });
    }
  },
};
</script>
