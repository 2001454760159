<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content" v-if="!viewListe">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Recouvrement</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard-saf'">Tableau de
                    Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">
                  Recouvrement &nbsp;<a class="parent-item"></a>
                </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button">
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>

                <div class="card-body row">

                  <div class="col-lg-6 p-t-20">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                      <label class="mdl-textfield__label" for="dateOfBirth" v-show="!form.date_debut">Date
                      </label>
                      <flat-pickr v-model="form.date_debut" class="mdl-textfield__input" @input="validatedata('date')">
                      </flat-pickr>
                      <span class="erreur">{{ this.date_erreur }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                      <label for="list3" class="mdl-textfield__label" v-show="!form.annee">Choisissez l'année </label>
                      <select class="mdl-textfield__input" id="list3" readonly tabIndex="-1" v-model="form.annee"
                        @change="validatedata('annee')">
                        <option :value="2023">2023</option>
                        <option :value="2024">2024</option>
                        <option :value="2025">2025</option>
                        <option :value="2026">2026</option>
                        <option :value="2027">2027</option>
                      </select>
                      <span class="erreur">{{ this.annee_erreur }}</span>
                    </div>
                  </div>


                  <div class="col-lg-12 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                      <label class="mdl-textfield__label" for="partenaireId" v-show="!form.nom_partenaire">Nom
                        Partenaire</label>
                      <select class="mdl-textfield__input" id="partenaireId" readonly tabIndex="-1"
                        v-model="form.id_partenaire" @change="validatedata('id_partenaire')">
                        <option v-for="partenaire in partenaires" :value="partenaire.id">
                          {{ partenaire.nom_partenaire }}
                        </option>
                      </select>
                      <span class="erreur">{{ this.id_partenaire_erreur }}</span>
                    </div>
                  </div>

                  <div class="col-lg-12 p-t-20 text-center">

                    <button type="submit"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      @click.prevent="validerAvantAjout()">
                      Appliquer
                    </button>

                    <button type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm">
                      Annuler
                    </button>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content-wrapper" v-show="viewListe">
      <listeRecouvrementSaf />
    </div>
    <appfooter />
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import listeRecouvrementSaf from "./listeRecouvrementSaf.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    listeRecouvrementSaf,
    flatPickr,
  },
  mixins: [permissionMixin],
  name: "recouvrementSafCompenent",

  data() {
    return {
      filieres: [],
      form: new Form({
        annee: "",
        date_debut: "",
        id_partenaire: "",
        nom_partenaire: "",
        partenaire: "",
      }),
      partenaires: [],
      annees: [],

      annee_erreur: "",
      date_erreur: "",
      id_partenaire_erreur: "",
      etatForm: false,
      viewListe: false,
      i: 0,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_partenaire();
    bus.on("ancienFiltre", (eventData) => {
      this.viewListe = eventData.viewListe;
    });
  },

  /*mounted() {
     //this.get_annee_academique();
     this.get_partenaire();
     bus.on("ancienFiltre", (eventData) => {
       this.viewListe = eventData.viewListe;
     });
   }, */

  methods: {
    ...mapActions(["login", "logout"]),
    async soumettre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("annee", this.form.annee);
      formdata.append("date", this.form.date_debut);
      formdata.append("id_partenaire", this.form.id_partenaire);
      if (this.hasReadPermission("Recouvrement")) {
        try {
          const response = await axios.post("/recouvrement/saf", formdata, {
            headers,
          });
          // Traitez la réponse de l'API selon vos besoins

          if (response.data.statut === 200) {
            this.viewListe = true;
            const eventData = {
              viewListe: true,
              factures: response.data.factures_non_soldees,
              cumul: response.data.cumul_montant_payer
            };

            bus.emit("nouveauFiltre", eventData);
          }
        } catch (error) {
          console.log(error);
          if (error.request.status === 500) {
            showDialog2();
            this.editModal = false;
          }
        }
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },


    async get_partenaire() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Recouvrement")) {
        await axios
          .get("/partenaire/index", { headers })
          .then((response) => {
            this.partenaires = response.data.partenaire;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des partenaires",
              "error"
            );
          });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    validerAvantAjout() {
      const isIdChampValid = this.validatedataOld();
      if (isIdChampValid) {
        this.etatForm = false;
        return 0;
      } else {
        this.soumettre();
        this.etatForm = true;
      }
    },

    validatedata(champ) {
      /* var i = 0;
      switch (champ) {
        case "annee":
          this.annee_erreur = "";
          //pour user
          if (this.form.annee === "") {
            this.annee_erreur =
              "Vous avez oublié de sélectionner l'année";
            i = 1;
            return true;
          }
          break;

        case "id_partenaire":
          this.id_partenaire_erreur = "";
          //pour mois
          if (this.form.id_partenaire === "") {
            this.id_partenaire_erreur = "Vous avez oublié de sélectionner  le partenaire";
            i = 1;
            return true;
          }
          break;
        case "date":
          this.date_erreur = "";
          //pour departement
          if (this.form.date_debut === "") {
            this.date_erreur =
              "Vous avez oublié de sélectionner  la date";
            i = 1;
            return true;
          }
          break;

        default:
          break;
      } */
    },

    validatedataOld() {
      var i = 0;
      this.id_date_erreur = "";
      this.id_partenaire_erreur = "";
      this.annee_erreur = "";


      if (i == 1) return true;

      return false;
    },

    resetForm() {
      this.form.annee = "";
      this.form.date = "";
      this.form.id_partenaire = "";
      this.form.nom_partenaire = "";
      this.annee_erreur = "";
      this.date_erreur = "";
      this.id_partenaire_erreur = "";
      this.partenaires = [];

      this.etatForm = false;

    },
  },
};
</script>
