<template>
   <div class="page-wrapper">

<div class="page-header navbar navbar-fixed-top">
    <div class="page-header-inner">
        <appheader 
        :userNom="userNom"
:userPrenom="userPrenom"
:userPhoto="userPhoto"/>
    </div>
</div>
<div class="page-container">
    <appnavbar 
    :userNom="userNom"
:userPrenom="userPrenom"
:userPhoto="userPhoto"
:userIdrole="userIdrole"
:userRole="userRole"/>

    <!-- start page content -->
    <div class="page-content-wrapper">
        <!-- start page content -->
    <div class="page-content" v-if="!this.editModal">
        <div class="page-bar">
            <div class="page-title-breadcrumb">

                <ol class="breadcrumb page-breadcrumb pull-right">
                    <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'dashboard'">Accueil</a>&nbsp;<i
                            class="fa fa-angle-right"></i>
                    </li>
                    <li class="active">Paiement &nbsp;<i class="fa fa-angle-right"></i> </li>

                    <li><a class="parent-item" :href="'liste-paiement-facture'">  Liste Paiement</a>&nbsp;<i class="fa fa-angle-right"></i>
                    </li>
                  
                </ol>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tabbable-line">
                    <ul class="nav customtab nav-tabs" role="tablist">
                        <li class="nav-item"><a href="#tab1" class="nav-link active" data-bs-toggle="tab">Paiement</a>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane active fontawesome-demo" id="tab1">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card card-box">
                                        <div class="card-head">
                                            <header>Liste des paiements</header>
                                            <div class="tools">
                                                <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                                                <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                                                <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                                            </div>
                                        </div>
                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-6">
                                                    <div class="btn-group">
                                                        <a :href="'/create-paiement-facture'" id="addRow" class="btn btn-primary">
                                                            Ajouter <i class="fa fa-plus text-white"></i>
                                                        </a>

                                                    </div>
                                                </div>
                                            </div>
                                                <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div
                                  class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length"
                                >
                                  <label class="me-2">Afficher</label>
                                  <select
                                    name="example47_length"
                                    aria-controls="example47"
                                    class="form-select-sm"
                                    @change="updatePerPage"
                                    v-model="limitQuery"
                                  >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2"
                                    >Recherche :</label
                                  >
                                  <input
                                    type="text"
                                    id="searchInput"
                                    placeholder=""
                                    aria-controls="example47"
                                    v-model="searchQuery"
                                    @input="get_direction(currentPage)"
                                  />
                                </div>
                              </div>
                            </div>
                            <!--  -->
                                            <table
                                                class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                                                id="example47">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Partenaire</th>
                                                        <th>Date Facture</th>
                                                        <th>Numéro de Facture</th>
                                                        <th>Montant dû</th>
                                                        <th>Montant payé</th>
                                                        <th>Reste à payé</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="odd gradeX" v-for="(paiement, index) in paiement_partenaires"
                                                        :key="index">
                                                        <td class="patient-img"> {{ index + 1 }}
                                                        </td>
                                                        <td> {{ paiement.partenaire }} </td>
                                                        <td> {{ paiement.date_facture }} </td>
                                                        <td> {{ paiement.id_facture }}</td>
                                                        <td> {{ paiement.montant_total }}</td>
                                                        <td> {{ paiement.montant_payer }}</td>
                                                        <td> {{ paiement.reliquat }}</td>
                                                        

                                                        <td>
                                                            <a class="tblEditBtn" @click="openModal(paiement)">
                                                                <i class="fa fa-pencil"></i>
                                                            </a>
                                                            <a class="tblDelBtn" @click="deletePaiement(paiement)">
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="row" v-if="this.paiement_partenaires.length > 0">
                                                            <div class="col-sm-12 col-md-5">
                                                                <div class="dataTables_info" id="datable_1_info"
                                                                    role="status" aria-live="polite">
                                                                    Affichage de {{ from_begin }} à
                                                                    {{ to_begin }} sur {{ total }} entrées
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-7 text-right">
                                                                <div class="dataTables_paginate paging_simple_numbers"
                                                                    id="datable_1_paginate">
                                                                    <paginate-component :total-pages="total_page"
                                                                        :total="total" :per-page="per_page"
                                                                        :current-page="current_page"
                                                                        @pagechanged="pagPage"></paginate-component>
                                                                </div>
                                                            </div>
                                                        </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="page-content-wrapper" v-show="editModal">
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class=" pull-left">
                        <div class="page-title">Nouveau paiement</div>
                    </div>
                    <ol class="breadcrumb page-breadcrumb pull-right">
                        <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" @href="'/dashboardCaissier'">Tableau
                                de Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                        </li>
                        <li><a class="parent-item" :href="'/liste-valide-inscription'">Paiement</a>&nbsp;<i
                                class="fa fa-angle-right"></i>
                        </li>
                        <li class="active">Modifier Paiement</li>
                    </ol>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card-box">
                        <div class="card-head">
                            <header>Information</header>
                            <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                                data-upgraded=",MaterialButton">
                                <i class="material-icons">more_vert</i>
                            </button>
                            <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                                data-mdl-for="panel-button">
                                <li class="mdl-menu__item"><i class="material-icons">assistant_photo</i>Action
                                </li>
                                <li class="mdl-menu__item"><i class="material-icons">print</i>Another action
                                </li>
                                <li class="mdl-menu__item"><i class="material-icons">favorite</i>Something else
                                    here</li>
                            </ul>
                        </div>
                        <div class="card-body row">
                            <FormulaireModification />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <appafterContent />
            </div>
            <appfooter />
        </div>
    </div>
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";

import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import FormulaireModification from "./editComptableComponent.vue";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";


export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    FormulaireModification,
    PaginateComponent
  },
  mixins: [permissionMixin],
  name: "createComptableCompenent",

  data() {
    return {
      form: new Form({
        id_facture: "",
       montant_payer: "",
        montant_total: "",
        date_facture: "",
        id_user: "",
        montant_jour:"",
        nombre_jour:""
      }),
      paiement_partenaires: [],
      paiements: [],
      classes: [],

      annee_academiques: [],
      search_query: "",
      selectedFacture: {
        id: "",
        partenaire: "",
        type: "",
        reliquat: ""
      },
      eleve_classe: "",
      id_eleve_erreur: "",
      id_annee_accademique_erreur: "",

      montant_erreur: "",
      id_eleve_erreur: "",
      id_classe_erreur: "",
      etatForm: false,
      searchidPaiement: true,
      editModal: false,
      idPaiement: "",
      limitQuery: 5,
            total_page: 0,
            total: 0,
            per_page: 0,
            current_page: 1,
            from_begin: 0,
            to_begin: 0,
            is_loading: false,
            searchQuery: "",
            currentPage: 1,
            lastPage: 1,

    
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  async created() {
    await this.get_permission();
    this.get_paiement_facture();
    bus.on("paiementAjoutee", () => {
      // Écouter l'événement de nouvelle utilisateur ajoutée
      this.get_paiement_facture(); // Mettre à jour la liste des utilisateurs
    });
    bus.on("paiementDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_paiement_facture();
    });
  },
  /* mounted() {
    this.get_paiement_facture();
    bus.on("paiementAjoutee", () => {
      // Écouter l'événement de nouvelle utilisateur ajoutée
      this.get_paiement_facture(); // Mettre à jour la liste des utilisateurs
    });
    bus.on("paiementDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_paiement_facture();
    });
  }, */

  methods: {
    ...mapActions(["login", "logout"]),

    pagPage(pagination) {
            this.current_page = pagination;
            this.get_direction(this.currentPage);
        },
        updatePerPage(event) {
            // Récupérer la nouvelle valeur sélectionnée
            const newPerPage = parseInt(event.target.value);

            // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
            this.reloadDataWithPerPage(newPerPage);
        },

        reloadDataWithPerPage(newPerPage) {
            // Mettre à jour la limite de la requête avec la nouvelle quantité par page
            if (this.limitQuery !== 5) {
                this.limitQuery = newPerPage;
                // Recharger les données avec la nouvelle quantité par page
                this.get_direction(this.currentPage);
            }
        },
    async recuper_reliquat(id_facture) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasReadPermission("Paiement")){
      try {
        const response = await axios.post("/paiement_partenaire/valider-facture/" + id_facture, {}, { headers });
        const reliquat = response.data.reliquat; // Récupérer le reliquat à partir de la réponse
        console.log("Reliquat récupéré :", reliquat);
        this.selectedFacture.reliquat = reliquat;
        return this.selectedFacture.reliquat // Mettre à jour le reliquat dans les données de la facture sélectionnée
      } catch (error) {
        console.error("Erreur lors de la récupération du reliquat :", error);
        Swal.fire("Erreur!", "Une erreur est survenue lors de la récupération du reliquat", "error");
      }
    }
    else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },

    async get_paiement_facture() {
  const token = localStorage.getItem("token");
  const filterCondition = (paiement_partenaire) => true; // Condition de filtre à définir
  const headers = { Authorization: `Bearer ${token}` };
            const params = {
                per_page: this.limitQuery,
                page: this.current_page,
                // Ajoutez d'autres paramètres si nécessaire
            };
            if(this.hasReadPermission("Paiement")){
  try {
    const response = await axios.get("/paiement_partenaire/pagination", { headers: headers,
                    params: params, // Ajouter les paramètres ici
                });
    const paiement_partenaires = response.data.paiement_partenaire.data;
    const filteredPaiements = paiement_partenaires.filter((paiement_partenaire) => {
                    return (
                        filterCondition(paiement_partenaire) &&
                        Object.keys(paiement_partenaire).reduce((acc, key) => {
                            const value = paiement_partenaire[key];
                            if (typeof value === 'string') {
                                acc.push(value.toLowerCase());
                            } else if (value && typeof value === 'object') {
                                Object.values(value).forEach((nestedValue) => {
                                    if (typeof nestedValue === 'string') {
                                        acc.push(nestedValue.toLowerCase());
                                    }
                                });
                            }
                            return acc;
                        }, []).some((value) => value.includes(this.searchQuery.toLowerCase()))
                    );
                });


    const formattedPaiementPart = await Promise.all(filteredPaiements.map(async (paiement_partenaire) => {
      const reliquat = await this.recuper_reliquat(paiement_partenaire.id_facture); // Attendre la résolution de la promesse
      return {
        id: paiement_partenaire.id,
        partenaire: paiement_partenaire.facture.location.partenaire.nom_partenaire,
        date_facture: paiement_partenaire.date_paiement,
        montant_total: paiement_partenaire.facture.location.montant_jour * paiement_partenaire.facture.location.nombre_jour,
        montant_payer: paiement_partenaire.montant_payer,
        id_facture: paiement_partenaire.id_facture,
        reliquat: reliquat,
      };
    }));


    this.paiement_partenaires = formattedPaiementPart;
    const page = {
                    total_page: response.data.paiement_partenaire.last_page,
                    total: response.data.paiement_partenaire.total,
                    per_page: response.data.paiement_partenaire.per_page,
                    current_page: response.data.paiement_partenaire.current_page,
                    from_begin: response.data.paiement_partenaire.from,
                    to_begin: response.data.paiement_partenaire.to,
                };

                this.paiement_partenaires = formattedPaiementPart;
                this.total_page = page.total_page;
                this.total = page.total;
                this.per_page = page.per_page;
                this.current_page = page.current_page;
                this.from_begin = page.from_begin;
                this.to_begin = page.to_begin;

                //console.log(this.directions);
            } catch (error) {
                Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des directions', 'error');
            }
          }
          else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
        },


    changement(event) {
      this.interesser = event;
    },

    resetForm() {
        this.form.id_facture = "";
      this.form.statut = "";
      this.form.id_annee_academique = "";
      this.selectedFacture.id = "";
      this.selectedFacture.type = "";
      this.selectedFacture.partenaire = "";
      this.selectedFacture.classe = "";
      this.search_query = "";
      this.montant_payer = "";
      this.mode_paiement = "";
      this.date_paiement = "";
      this.searchidPaiement = true;
     
    },

    async deletePaiement(paiement) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasDeletePermission("Paiement")){
      Swal.fire({
        title: "Êtes-vous sûr de vouloir supprimer ce paiement ?",
        text: "Cette action sera irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then((result) => {
        if (result.value) {
          axios.delete(`/paiement_partenaire/delete/${paiement.id}`, { headers }).then(resp => {
             showDialog6("Paiement supprimé avec succés");
              this.get_paiement_facture();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
    else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },

    openModal(paiement) {
      this.editModal = true;
     
          const eventData = {
            paiement: paiement,
           
          };
          console.log("eventData");
          console.log(eventData);
          bus.emit("paiementModifier", eventData);
          // Vous pouvez utiliser eleveId ici ou le passer à d'autres fonctions
        },
       

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      return url ? `http://127.0.0.1:8000/storage/${url}` : "";
    },

   
  },
};
</script>