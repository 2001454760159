<template>
  <div class="chat-sidebar-container" data-close-on-body-click="false">
    <div class="chat-sidebar">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            href="#quick_sidebar_tab_1"
            class="nav-link active tab-icon"
            data-bs-toggle="tab"
          >
            <i class="material-icons">message</i>Message
            <span class="badge badge-danger">4</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            href="#quick_sidebar_tab_3"
            class="nav-link tab-icon"
            data-bs-toggle="tab"
          >
            <i class="material-icons">settings</i>
            Settings
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <!-- Start Doctor Chat -->
        <div
          class="tab-pane active chat-sidebar-chat in active show"
          role="tabpanel"
          id="quick_sidebar_tab_1"
        >
          <div class="chat-sidebar-list">
            <div
              class="chat-sidebar-chat-users slimscroll-style"
              data-rail-color="#ddd"
              data-wrapper-class="chat-sidebar-list"
            >
              <div class="chat-header">
                <h5 class="list-heading">Online</h5>
              </div>
              <ul class="media-list list-items">
                <li class="media">
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user3.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="online dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">John Deo</h5>
                    <div class="media-heading-sub">Spine Surgeon</div>
                  </div>
                </li>
                <li class="media">
                  <div class="media-status">
                    <span class="badge badge-success">5</span>
                  </div>
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user1.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="busy dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Rajesh</h5>
                    <div class="media-heading-sub">Director</div>
                  </div>
                </li>
                <li class="media">
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user5.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="away dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Jacob Ryan</h5>
                    <div class="media-heading-sub">Ortho Surgeon</div>
                  </div>
                </li>
                <li class="media">
                  <div class="media-status">
                    <span class="badge badge-danger">8</span>
                  </div>
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user4.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="online dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Kehn Anderson</h5>
                    <div class="media-heading-sub">CEO</div>
                  </div>
                </li>
                <li class="media">
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user2.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="busy dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Sarah Smith</h5>
                    <div class="media-heading-sub">Anaesthetics</div>
                  </div>
                </li>
                <li class="media">
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user7.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="online dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Vlad Cardella</h5>
                    <div class="media-heading-sub">Cardiologist</div>
                  </div>
                </li>
              </ul>
              <div class="chat-header">
                <h5 class="list-heading">Offline</h5>
              </div>
              <ul class="media-list list-items">
                <li class="media">
                  <div class="media-status">
                    <span class="badge badge-warning">4</span>
                  </div>
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user6.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="offline dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Jennifer Maklen</h5>
                    <div class="media-heading-sub">Nurse</div>
                    <div class="media-heading-small">Last seen 01:20 AM</div>
                  </div>
                </li>
                <li class="media">
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user8.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="offline dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Lina Smith</h5>
                    <div class="media-heading-sub">Ortho Surgeon</div>
                    <div class="media-heading-small">Last seen 11:14 PM</div>
                  </div>
                </li>
                <li class="media">
                  <div class="media-status">
                    <span class="badge badge-success">9</span>
                  </div>
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user9.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="offline dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Jeff Adam</h5>
                    <div class="media-heading-sub">Compounder</div>
                    <div class="media-heading-small">Last seen 3:31 PM</div>
                  </div>
                </li>
                <li class="media">
                  <img
                    class="media-object"
                    :src="'./assets/img/user/user10.jpg'"
                    width="35"
                    height="35"
                    alt="..."
                  />
                  <i class="offline dot"></i>
                  <div class="media-body">
                    <h5 class="media-heading">Anjelina Cardella</h5>
                    <div class="media-heading-sub">Physiotherapist</div>
                    <div class="media-heading-small">Last seen 7:45 PM</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- End Doctor Chat -->
        <!-- Start Setting Panel -->
        <div
          class="tab-pane chat-sidebar-settings"
          role="tabpanel"
          id="quick_sidebar_tab_3"
        >
          <div class="chat-sidebar-settings-list slimscroll-style">
            <div class="chat-header">
              <h5 class="list-heading">Layout Settings</h5>
            </div>
            <div class="chatpane inner-content">
              <div class="settings-list">
                <div class="setting-item">
                  <div class="setting-text">Sidebar Position</div>
                  <div class="setting-set">
                    <select
                      class="sidebar-pos-option form-control input-inline input-sm input-small"
                    >
                      <option value="left" selected>Left</option>
                      <option value="right">Right</option>
                    </select>
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting-text">Header</div>
                  <div class="setting-set">
                    <select
                      class="page-header-option form-control input-inline input-sm input-small"
                    >
                      <option value="fixed" selected>Fixed</option>
                      <option value="default">Default</option>
                    </select>
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting-text">Footer</div>
                  <div class="setting-set">
                    <select
                      class="page-footer-option form-control input-inline input-sm input-small"
                    >
                      <option value="fixed">Fixed</option>
                      <option value="default" selected>Default</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="chat-header">
                <h5 class="list-heading">Account Settings</h5>
              </div>
              <div class="settings-list">
                <div class="setting-item">
                  <div class="setting-text">Notifications</div>
                  <div class="setting-set">
                    <div class="switch">
                      <label
                        class="mdl-switch mdl-js-switch mdl-js-ripple-effect"
                        for="switch-1"
                      >
                        <input
                          type="checkbox"
                          id="switch-1"
                          class="mdl-switch__input"
                          checked
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting-text">Show Online</div>
                  <div class="setting-set">
                    <div class="switch">
                      <label
                        class="mdl-switch mdl-js-switch mdl-js-ripple-effect"
                        for="switch-7"
                      >
                        <input
                          type="checkbox"
                          id="switch-7"
                          class="mdl-switch__input"
                          checked
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting-text">Status</div>
                  <div class="setting-set">
                    <div class="switch">
                      <label
                        class="mdl-switch mdl-js-switch mdl-js-ripple-effect"
                        for="switch-2"
                      >
                        <input
                          type="checkbox"
                          id="switch-2"
                          class="mdl-switch__input"
                          checked
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting-text">2 Steps Verification</div>
                  <div class="setting-set">
                    <div class="switch">
                      <label
                        class="mdl-switch mdl-js-switch mdl-js-ripple-effect"
                        for="switch-3"
                      >
                        <input
                          type="checkbox"
                          id="switch-3"
                          class="mdl-switch__input"
                          checked
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-header">
                <h5 class="list-heading">General Settings</h5>
              </div>
              <div class="settings-list">
                <div class="setting-item">
                  <div class="setting-text">Location</div>
                  <div class="setting-set">
                    <div class="switch">
                      <label
                        class="mdl-switch mdl-js-switch mdl-js-ripple-effect"
                        for="switch-4"
                      >
                        <input
                          type="checkbox"
                          id="switch-4"
                          class="mdl-switch__input"
                          checked
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting-text">Save Histry</div>
                  <div class="setting-set">
                    <div class="switch">
                      <label
                        class="mdl-switch mdl-js-switch mdl-js-ripple-effect"
                        for="switch-5"
                      >
                        <input
                          type="checkbox"
                          id="switch-5"
                          class="mdl-switch__input"
                          checked
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="setting-item">
                  <div class="setting-text">Auto Updates</div>
                  <div class="setting-set">
                    <div class="switch">
                      <label
                        class="mdl-switch mdl-js-switch mdl-js-ripple-effect"
                        for="switch-6"
                      >
                        <input
                          type="checkbox"
                          id="switch-6"
                          class="mdl-switch__input"
                          checked
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
