<template>
    <div class="col-lg-6 p-t-20">
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
            <label class="mdl-textfield__label" for="txtFirstName" v-show="!form.intitule">Titre</label>
            <input class="mdl-textfield__input" type="text" id="txtFirstName" v-model="form.intitule"
                @input="validatedata('intitule')">
            <span class="erreur">{{ this.intitule_erreur }}</span>
        </div>
    </div>

    <div class="col-lg-6 p-t-20">
        <div
            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
            <label for="list6" class="mdl-textfield__label" v-show="!form.categorie_personnel">Choisissez la categorie
            </label>
            <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1" v-model="form.categorie_personnel"
                @change="validatedata('categorie_personnel')">
                <option value="Personnel Administratif">Personnel Administratif</option>
                <option value="Simple">Simple</option>
                <option value="Personnel Appui">Personnel Appui</option>
            </select>
            <span class="erreur">{{ categorie_personnel_erreur }}</span>
        </div>
    </div>
    <div class="mt-4"></div>

    <div class="card-head mt-2">
        <header>Permissions</header>

        <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect " data-mdl-for="panel-button">
            <li class="mdl-menu__item"><i class="material-icons">assistant_photo</i>Action
            </li>
            <li class="mdl-menu__item"><i class="material-icons">print</i>Another action
            </li>
            <li class="mdl-menu__item"><i class="material-icons">favorite</i>Something
                else
                here</li>
        </ul>
    </div>

    <div class="card-body row p-4" v-for="(permi, index) in permissionss.permission" :key="index">
        <div class="header d-flex justify-content-end"> </div>

        <div class="header d-flex justify-content-end">
            <svg class="cursor-pointer" @click.prevent="duplicatePaiement(index)" xmlns="hhtp://www.w3.org/200/svg"
                viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path fill="green"
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
            </svg>
            <svg v-show="permissionss.permission.length > 1" class="cursor-pointer ml-2"
                @click.prevent="removePaiement(index)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path fill="#EC4899"
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
            </svg>
        </div>

        <div class="col-lg-6 p-t-20">
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                <label :for="`permi_id_fonctionnalite_${index}`" class="mdl-textfield__label"
                    v-show="!permi.id_fonctionnalite">Fonctionnalite</label>
                <select :id="`permi_id_fonctionnalite_${index}`" class="mdl-textfield__input" readonly tabIndex="-1"
                    v-model="permi.id_fonctionnalite" @change="validatedata('id_fonctionnalite')">
                    <option v-for="fonctionnalite in fonctionnalites" :value="fonctionnalite.id"
                        :key="fonctionnalite.id">
                        {{ fonctionnalite.intitule }}
                    </option>
                </select>
                <span class="erreur">{{ id_fonctionnalite_erreur[index] }}</span>
            </div>
        </div>

        <div class="col-lg-6">
            <table class="mx-auto" style="width: 100%;">

                <tr class="text-center">
                    <th class="">Creer</th>
                    <th class="">Lire</th>
                    <th class="">Modifier</th>
                    <th class="">Supprimer</th>
                </tr>

                <tr class="text-center">
                    <td class="">
                        <div class="checkbox checkbox-black">
                            <input :id="`permi_create_${index}`" type="checkbox" checked="checked"
                                v-model="permi.create">
                            <label :for="`permi_create_${index}`"></label>
                        </div>
                    </td>

                    <td class="">
                        <div class="checkbox checkbox-aqua">
                            <input :id="`checkboxbg4_${index}`" type="checkbox" checked="checked" v-model="permi.read">
                            <label :for="`checkboxbg4_${index}`"></label>
                        </div>
                    </td>

                    <td class="">
                        <div class="checkbox checkbox-yellow">
                            <input :id="`checkboxbg2_${index}`" type="checkbox" checked="checked"
                                v-model="permi.update">
                            <label :for="`checkboxbg2_${index}`"></label>
                        </div>
                    </td>

                    <td class="">
                        <div class="checkbox checkbox-red">
                            <input :id="`checkboxbg3_${index}`" type="checkbox" checked="checked"
                                v-model="permi.delete">
                            <label :for="`checkboxbg3_${index}`"></label>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="col-lg-12 p-t-20 text-center">

       <!--  <button type="submit" v-if="!this.editModal"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
            @click.prevent="validerAvantAjout()">Enregistrer</button> -->
        <button type="submit" 
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
            @click.prevent="validerAvantAjout()">Modifier</button>
        <button type="button"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
            @click="resetForm">Annuler</button>
    </div>

    <div class="card card-box mt-4">
        <div class="card-head">
            <header>Liste des derniers roles</header>
            <div class="tools">
                <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
            </div>
        </div>
        <div class="card-body ">
            <table class="table table-striped table-bordered table-hover table-checkable order-column valign-middle">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Titre</th>
                        <th>Categorie</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="odd gradeX" v-for="(role, index) in roles" :key="index">
                        <td> {{ index + 1 }} </td>
                        <td> {{ role.intitule }} </td>
                        <td> {{ role.categorie_personnel }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import bus from '../../eventBus';
import axios from '@/axios';
import Form from 'vform';
import Swal from 'sweetalert2';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";


export default {
    components: {
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        flatPickr,
    },
    mixins: [permissionMixin],
    props: ['service'],
    name: "editRole",

    data() {
        return {
            form: new Form({
                'intitule': "",
                'categorie_personnel': "",

            }),
            roles: [],
            intitule_erreur: "",
            categorie_personnel_erreur: "",
            etatForm: false,
            editModal: false,
            idrole: "",
            fonctionnalites: [],
            id_fonctionnalite_erreur: [],

            permissionss: new Form({
                permission: [
                    {
                        id: "",
                        id_fonctionnalite: "",
                        read: false,
                        create: false,
                        update: false,
                        delete: false,
                    },
                ],
            }),

        }
    },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },
    /*   mounted() {
          this.get_role();
          bus.on('roleModifier', (eventData) => {
              this.editModal = eventData.editModal;
              this.monterToupdate(eventData.role);
          });
      }, */
    async created() {
        await this.get_permission();
        this.get_fonctionnalite();
        this.get_role();
        bus.on('roleModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.monterToupdate(eventData.role);
        });
    },



    methods: {
        ...mapActions(["login", "logout"]),
        async soumettre() {

            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
            formdata.append('intitule', this.form.intitule);
            formdata.append('categorie_personnel', this.form.categorie_personnel);
           /*  if (this.hasCreatePermission("Role")) { */

                try {
                    const create_store = await axios.post('/role/store', formdata, { headers });
                    showDialog6("Role ajoutée avec succès");
                    this.resetForm();
                    bus.emit('roleAjoutee');
                    setTimeout(() => {
                        window.location.href = '/liste-role';
                    }, 1500);

                }
                catch (e) {
                    console.log(e)
                    if (e.request.status === 404) {
                        showDialog3('Cet role existe déjà')
                    }
                    else {
                        showDialog3("Une erreur est survenue lors de l\'enregistrement");
                    }
                }
            /* }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            } */
        },

        async get_fonctionnalite() {

            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
          /*   if (this.hasReadPermission("Role")) { */
                await axios.get('/fonctionnalite/all', { headers })
                    .then(response => {
                        this.fonctionnalites = response.data.fonctionnalite;

                    }).catch(error => {
                        Swal.fire('Erreur!', 'Une erreur est survenue lors de la recupération des fonctionnalités', 'error')
                    });
           /*  }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            } */
        },
        rafraichissementAutomatique() {
            document.addEventListener("DOMContentLoaded", this.resetForm());
        },

 /*        duplicatePaiement(index) {
            const newPaiement = { ...this.permissionss.permission[index] };
            this.permissionss.permission.splice(index + 1, 0, newPaiement);
        }, */
        duplicatePaiement(index) {
            // Crée un nouvel objet avec les valeurs par défaut
            const newPaiement = {
                id_fonctionnalite: "",
                id: "",
                read: false,
                create: false,
                update: false,
                delete: false,
            };
            
            // Insère le nouvel objet après l'index spécifié
            this.permissionss.permission.splice(index + 1, 0, newPaiement);
        },

        removePaiement(index) {
            if (this.permissionss.permission.length > 1) {
                this.permissionss.permission.splice(index, 1);
            }
        },

        async get_role() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
           /*  if (this.hasReadPermission("Role")) { */
                await axios.get('/role/get/last', { headers })
                    .then(response => {
                        this.roles = response.data.role;

                    }).catch(error => {
                        Swal.fire('Erreur!', 'Une erreur est survenue lors de la recupération des derniers roles', 'error')
                    });
           /*  }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            } */
        },

        verifCaratere(nom) {
            const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
            return valeur.test(nom);
        },

        validatedata(champ) {

var i = 0;

switch (champ) {
    case 'intitule':
        this.intitule_erreur = "";
        // Effectuez la validation pour le champ 'nom'
        if (this.form.intitule === "") {
            this.intitule_erreur = "Ce champ est obligatoire"
            i = 1;
            return true

        }
        break;
    case 'categorie_personnel':
        this.categorie_personnel_erreur = "";
        //pour type role
        if (this.form.categorie_personnel === "") {
            this.categorie_personnel_erreur = "Vous avez oublié de sélectionner la categorie du role "
            i = 1;
            return true
        }

        break;
        case 'id_fonctionnalite':
                    this.id_fonctionnalite_erreur = [];
                    for (let m = 0; m < this.permissionss.permission.length; m++) {
                        const permi = this.permissionss.permission[m];
                        if (permi.id_fonctionnalite === "") {
                            this.id_fonctionnalite_erreur[m] = "La fonctionnalite " + (m + 1) + " n'a pas été selectionner";
                            return true;
                        }
                    }
                    break;

    default:
        break;
}
},

validatedataOld() {
    // Initialisation des messages d'erreur à vide
    this.intitule_erreur = "";
    this.categorie_personnel_erreur = "";
    
    // Initialise un tableau d'erreurs pour les permissions
    this.permissionss_erreurs = [];

    // Indicateurs d'erreur pour les champs de validation
    let intituleError = false;
    let categoriePersonnelError = false;
    let fonctionnaliteError = false;

    // Validation du champ intitule
    if (this.form.intitule === "") {
        this.intitule_erreur = "Ce champ est obligatoire";
        intituleError = true;
    }

    // Validation du champ categorie_personnel
    if (this.form.categorie_personnel === "") {
        this.categorie_personnel_erreur = "Vous avez oublié de sélectionner la catégorie du rôle";
        categoriePersonnelError = true;
    }

    // Validation de chaque permission dans le tableau permissionss.permission
    for (let k = 0; k < this.permissionss.permission.length; k++) {
        const permi = this.permissionss.permission[k];
        
        // Vérifie si id_fonctionnalite est vide
        if (permi.id_fonctionnalite === "") {
          this.id_fonctionnalite_erreur[k] = "Vous avez oublié de sélectionner la fonctionnalité " + (k + 1);
            fonctionnaliteError = true;
        } 
       /*  else {
            this.id_fonctionnalite_erreur[k] = ""; // Réinitialise l'erreur si id_fonctionnalite n'est pas vide
        } */
    }

    // Si une des erreurs est détectée, retourner true
    if (intituleError || categoriePersonnelError || fonctionnaliteError) {
        return true;
    }

    // Si aucune erreur n'est détectée, retourner false
    return false;
},


        validerAvantAjout() {
            // Exécutez la validation des champs
            const isIdChampValid = this.validatedataOld();
            if (isIdChampValid) {
                this.editModal = false;
                return 0;
            } else {

               
                    this.etatForm = true;
                    this.form.intitule = this.form.intitule.charAt(0).toUpperCase() + this.form.intitule.slice(1).toLowerCase();
                    /* this.form.intitule = this.form.intitule.toUpperCase(); */
                    this.update_role(this.idrole);
                    this.editModal = false;
               

            }

        },
        async update_role(id) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
            formdata.append('intitule', this.form.intitule);
            formdata.append('categorie_personnel', this.form.categorie_personnel);
            formdata.append("permis", JSON.stringify(this.permissionss.permission));
            //console.log(this.permissionss.permission)

          /*   if (this.hasUpdatePermission("Role")) { */
                try {
                    await axios.post('/role/update/' + id, formdata, { headers });
                    showDialog6("Role modifiée avec succès");
                    bus.emit('roleAjoutee');
                    const eventData = {
                        editModal: false,
                    };
                    bus.emit('roleDejaModifier', eventData);
                }
                catch (e) {
                    console.log(e)
                    if (e.request.status === 404) {
                        showDialog3("Une erreur est survenue lors de la modification");
                    }
                    else {
                        showDialog3("Une erreur est survenue lors de la modification");
                    }
                }
            /* }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            } */
        },

        monterToupdate(role) {
            this.idrole = role.id;
            this.editModal = role.editModal;
            this.form.intitule = role.intitule;
            this.form.categorie_personnel = role.categorie;


            this.permissionss.permission = [];
            role.permis.forEach(item => {
                this.permissionss.permission.push({
                    id:item.id,
                    id_fonctionnalite: item.id_fonctionnalite,
                    read: item.read === 1,
                    create: item.create === 1,
                    update: item.update === 1,
                    delete: item.delete === 1,
                });

            });

            if (role.permis.length === 0) {
        // Réinitialiser avec les valeurs par défaut du data
        this.permissionss.permission = [
            {
                id: "",
                id_fonctionnalite: "",
                read: false,
                create: false,
                update: false,
                delete: false,
            }
        ];
    }

        },

        resetForm() {
            this.form.intitule = "";
            this.form.categorie_personnel = "";
            this.intitule_erreur = "";
            this.categorie_personnel_erreur = "";
            this.niveau_erreur = ""
            this.permissionss.permission = [];
        },



    }
}
</script>
