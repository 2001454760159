import axios from 'axios';

const instance= axios.create({
   //baseURL: 'http://localhost:8000/api/'
   //baseURL: 'https://rafinatv.com/back/public/api/'
   baseURL: 'https://erp.cfptdigital.sn/back/public/api/'
   //baseURL: 'https://hautedispo.online/back/public/api/'
   // baseURL: 'https://15./api/'
});

export default instance;