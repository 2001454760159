<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'dashboard'">Accueil</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>

                <li class="active">
                  Paramétres &nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'liste-role'"> Role</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab">Role</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Tous les roles</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a :href="'/create-role'" id="addRow" class="btn btn-primary">
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQuery">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_role(currentPage)" />
                                </div>
                              </div>
                            </div>

                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Titre</th>
                                  <th>Categorie</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(role, index) in roles" :key="index">
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ role.intitule }}</td>
                                  <td>{{ role.categorie }}</td>

                                  <td>
                                    <a class="tblEditBtn" @click="openModal(role)">
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a class="tblDelBtn" @click="deleterole(role)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="row" v-if="this.roles.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_begin }} à
                                  {{ to_begin }} sur {{ total }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_page" :total="total" :per-page="per_page"
                                    :current-page="current_page" @pagechanged="pagPage"></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-content-wrapper" v-show="editModal">
          <div class="page-content">
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">Modifier Role</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li>
                    <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Tableau de
                      Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li>
                    <a class="parent-item" :href="'/liste-role'">Role</a>&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li class="active">Modifier Role</li>
                </ol>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card-box">
                  <div class="card-head">
                    <header>Information</header>
                    <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                      data-upgraded=",MaterialButton">
                      <i class="material-icons">more_vert</i>
                    </button>
                    <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                      data-mdl-for="panel-button">
                      <li class="mdl-menu__item">
                        <i class="material-icons">assistant_photo</i>Action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">print</i>Another action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">favorite</i>Something else
                        here
                      </li>
                    </ul>
                  </div>
                  <div class="card-body row">
                    <FormulaireModification />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <appafterContent />
      </div>
      <appfooter />
    </div>
  </div>
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import datatable from "datatables.net-bs5";
import FormulaireModification from "./editRole.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    FormulaireModification,
    PaginateComponent,
  },
  mixins: [permissionMixin],
  name: "listeRoleCompenent",

  data() {
    return {
      form: new Form({
        intitule: "",
        categorie_personnel: "",

      }),
      
      roles: [],
      editModal: false,
      limitQuery: 5,
      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,
      searchQuery: "",
      currentPage: 1,
      lastPage: 1,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  /*    mounted() {
      this.get_role();
      bus.on("roleAjoutee", () => {
        // Écouter l'événement de nouvelle utilisateur ajoutée
        this.get_role(); // Mettre à jour la liste des utilisateurs
      });
      bus.on("roleDejaModifier", (eventData) => {
        this.editModal = eventData.editModal;
        this.get_role();
      });
      //console.log(this.get_permission())
      
    },  */
  async created() {
    await this.get_permission();
    this.get_role();
    bus.on("roleAjoutee", () => {
      this.get_role();
    });
    bus.on("roleDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_role();
    });
  },


  methods: {
    ...mapActions(["login", "logout"]),
    pagPage(pagination) {
      this.current_page = pagination;
      this.get_role(this.currentPage);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },

    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQuery !== 5) {
        this.limitQuery = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_role(this.currentPage);
      }
    },

    async get_role() {
      const token = localStorage.getItem("token");
      const filterCondition = (role) => true; // Condition de filtre à définir
      const headers = { Authorization: `Bearer ${token}` };
      const params = {
        per_page: this.limitQuery,
        page: this.current_page,
        // Ajoutez d'autres paramètres si nécessaire
      };
      //await this.get_permission();
     /*  if (this.hasReadPermission("Role")) { */

        try {
          //const response = await axios.get('/role/all', { headers });
          const response = await axios.get("/role/all/paginate", {
            headers: headers,
            params: params, // Ajouter les paramètres ici
          });
          const allrole = response.data.role.data;

          const filteredroles = allrole.filter((role) => {
            return (
              filterCondition(role) &&
              Object.keys(role)
                .reduce((acc, key) => {
                  const value = role[key];
                  if (typeof value === "string") {
                    acc.push(value.toLowerCase());
                  } else if (typeof value === "number") {
                    acc.push(value.toString().toLowerCase());
                  } else if (value && typeof value === "object") {
                    Object.values(value).forEach((nestedValue) => {
                      if (typeof nestedValue === "string") {
                        acc.push(nestedValue.toLowerCase());
                      } else if (typeof nestedValue === "number") {
                        acc.push(nestedValue.toString().toLowerCase());
                      }
                    });
                  }
                  return acc;
                }, [])
                .some((value) => value.includes(this.searchQuery.toLowerCase()))
            );
          });

          const formattedroles = filteredroles.map((role) => {
            return {
              id: role.id,
              intitule: role.intitule,
              categorie: role.categorie_personnel,
              permis: role.permissions,
              editModal: true,
            };
          });

          const page = {
            total_page: response.data.role.last_page,
            total: response.data.role.total,
            per_page: response.data.role.per_page,
            current_page: response.data.role.current_page,
            from_begin: response.data.role.from,
            to_begin: response.data.role.to,
          };

          this.roles = formattedroles;
          this.total_page = page.total_page;
          this.total = page.total;
          this.per_page = page.per_page;
          this.current_page = page.current_page;
          this.from_begin = page.from_begin;
          this.to_begin = page.to_begin;
        } catch (error) {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la récupération des roles",
            "error"
          );
        }
     /*  }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      } */
    },

    changement(event) {
      this.interesser = event;
    },

    resetForm() {
      this.form.input = "";
      this.form.intitule = "";
      this.form.type_role = "";
      this.form.niveau = "";
      this.form.id_type_formation = "";
      this.form.id_unite_de_formation = "";
    },

    async deleterole(type) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
    /*   if (this.hasDeletePermission("Role")) { */
        Swal.fire({
          title: "Voulez-vous confirmer la suppression?",
          text: "Cette action sera irréversible!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
        }).then((result) => {
          if (result.value) {
            axios
              .delete(`/role/delete/${type.id}`, { headers })
              .then((resp) => {
                showDialog6("Role supprimée avec succés");
                this.get_role();
              })
              .catch(function (error) {
                console.log(error);
                showDialog3("Erreur lors de la suppression de role");
              });
          }
        });
     /*  }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      } */
    },

    openModal(role) {
      this.editModal = true;

      // Créez un objet avec les données à envoyer
      const eventData = {
        role: role,
        editModal: true,
      };

      bus.emit("roleModifier", eventData);
    },
  },
};
</script>
