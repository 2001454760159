<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Liste des Factures</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard-saf'">Accueil</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/liste-facture'">Facture</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Liste des factures</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">

                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link" :class="{ 'active': ongletActif === 'tab1' }" data-bs-toggle="tab"
                      @click="ongletActif = 'tab1'">Proforma</a>
                  </li>
                  <li class="nav-item">
                    <a href="#tab2" class="nav-link" :class="{ 'active': ongletActif === 'tab2' }" data-bs-toggle="tab"
                      @click="ongletActif = 'tab2'">Définitive</a>
                  </li>
                  <li class="nav-item">
                    <a href="#tab3" class="nav-link" :class="{ 'active': ongletActif === 'tab3' }" data-bs-toggle="tab"
                      @click="ongletActif = 'tab3'">Acompte</a>
                  </li>
                  <li class="nav-item">
                    <a href="#tab4" class="nav-link" :class="{ 'active': ongletActif === 'tab4' }" data-bs-toggle="tab"
                      @click="ongletActif = 'tab4'">Solde</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fontawesome-demo" :class="{ 'active': ongletActif === 'tab1' }" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Proforma</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a :href="'/create-facture'" id="addRow" class="btn btn-primary">
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQueryProforma">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_facture(currentPageProforma)" />
                                </div>
                              </div>
                            </div>

                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47" style="width: 100%">
                              <thead>
                                <tr>
                                  <th class="text-right">#</th>
                                  <th class="text-right">N° Facture</th>
                                  <th class="text-right">DÉSIGNATION</th>
                                  <th class="text-right">OBJET</th>
                                  <th class="text-right">PARTENAIRE</th>
                                  <th class="text-right">QUANTITE</th>
                                  <th class="text-right">PRIX UNITAIRE</th>
                                  <th class="text-right">MONTANT</th>
                                  <th class="text-right">DATE</th>
                                  <th class="text-right">STATUS</th>
                                  <th class="text-right">ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(facture, index) in factureProforma">
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ facture.id }}{{ formatDate(facture.created_at) }}</td>

                                  <td class="left">
                                    {{ facture.designation }}
                                  </td>
                                  <td class="left">
                                    {{ facture.objet }}
                                  </td>

                                  <td class="left">{{ facture.partenaire }}</td>

                                  <td class="left">
                                    {{ facture.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.montant_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.montant_jour * facture.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.date_location }}
                                  </td>

                                  <td class="text-center align-middle" v-if="facture.reserver == 1">
                                    <span class="fas fa-circle col-green me-2">
                                    </span>
                                  </td>

                                  <td class="text-center align-middle" v-if="facture.reserver == 0">
                                    <span class="fas fa-circle col-red me-2">
                                    </span>
                                  </td>

                                  <td>
                                    <a class="tblEditBtn" title="Imprimer" @click="factureImprime(facture.id)">
                                      <i class="fa fa-print"></i>

                                    </a>

                                    <a class="tblEditBtn" title="Modifier" @click="openModal(facture)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer" @click="deleteLocation(facture)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Réserver"
                                      @click="reserverFacture(facture.id, facture.reserver)">
                                      <i class="fa fa-calendar-plus-o"></i>
                                    </a>

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="row" v-if="this.factureProforma.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_beginp }} à
                                  {{ to_beginp }} sur {{ totalp }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_paged" :total="totalp" :per-page="per_pagep"
                                    :current-page="current_pagep" @pagechanged="pagPageProforma"></paginate-component>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fontawesome-demo" id="tab2" :class="{ 'active': ongletActif === 'tab2' }">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Factures Définitives</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">

                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQueryDefinitive">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_facture_definitive(currentPageDefinitive)" />
                                </div>
                              </div>
                            </div>

                            <!--  -->

                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="dataTableFactureDefinitiveS" style="width: 100%">
                              <thead>
                                <tr>

                                  <th>#</th>
                                  <th>N° Facture</th>
                                  <th>Designation</th>
                                  <th>Salle</th>
                                  <th>Partenaire</th>
                                  <th>Nombre jours</th>
                                  <th>Montant/jour</th>
                                  <th>Montant</th>
                                  <th>Date</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(facture, index) in factureDefinitive">
                                  <td class="left">{{ index + 1 }}</td>
                                  <td class="left">{{ facture.id }}{{ formatDate(facture.created_at) }}</td>
                                  <td class="left">
                                    {{ facture.designation }}
                                  </td>
                                  <td class="left">{{ facture.salle }}</td>
                                  <td class="left">{{ facture.partenaire }}</td>
                                  <td class="left">
                                    {{ facture.nombre_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour * facture.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.date_facture }}
                                  </td>

                                  <td>

                                    <a class="tblEditBtn" title="Imprimer" @click="factureDefImprime(facture.id)">
                                      <i class="fa fa-print"></i>
                                    </a>

                                    <a class="tblEditBtn" title="Modifier" @click="openModal(facture)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer" @click="deleteFacture(facture)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div class="row" v-if="this.factureDefinitive.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_begind }} à
                                  {{ to_begind }} sur {{ totald }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_paged" :total="totald" :per-page="per_paged"
                                    :current-page="current_paged" @pagechanged="pagPageDefinitive"></paginate-component>
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fontawesome-demo" id="tab3" :class="{ 'active': ongletActif === 'tab3' }">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Factures Acompte</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">

                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQueryAcompte">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_facture_acompte(currentPageAcompte)" />
                                </div>
                              </div>
                            </div>

                            <!--  -->


                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="initDataTableFactureAcompteS" style="width: 100%">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>N° Facture</th>
                                  <th>Designation</th>
                                  <th>Salle</th>
                                  <th>Partenaire</th>
                                  <th>Nombre jours</th>
                                  <th>Montant/jour</th>
                                  <th>Montant</th>
                                  <th>Date</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(facture, index) in factureAcompte">
                                  <td class="left">{{ index + 1 }}</td>
                                  <td class="left">{{ facture.id }}{{ formatDate(facture.created_at) }}</td>
                                  <td class="left">
                                    {{ facture.designation }}
                                  </td>
                                  <td class="left">{{ facture.salle }}</td>
                                  <td class="left">{{ facture.partenaire }}</td>
                                  <td class="left">
                                    {{ facture.nombre_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour * facture.nombre_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.date_facture }}
                                  </td>
                                  <td>
                                    <a class="tblEditBtn" title="Imprimer" @click="factureDefImprime(facture.id)">
                                      <i class="fa fa-print"></i>

                                    </a>

                                    <a class="tblEditBtn" title="Modifier" @click="openModal(facture)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer" @click="deleteFacture(facture)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Générer Facture Solde" @click=" facture.nombre_jour *
          facture.montant_jour < facture.montant_payer ? this.erreurFactureSolde() : facturerSolde(facture)">
                                      <i class="fa fa-calendar-plus-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div class="row" v-if="this.factureAcompte.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_begina }} à
                                  {{ to_begina }} sur {{ totala }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_pagea" :total="totala" :per-page="per_pagea"
                                    :current-page="current_pagea" @pagechanged="pagPageAcompte"></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fontawesome-demo" id="tab4" :class="{ 'active': ongletActif === 'tab4' }">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Facture Solde</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">

                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQuerySolde">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_facture_solde(currentPageSolde)" />
                                </div>
                              </div>
                            </div>

                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="initDataTableFactureSoldeS" style="width: 100%">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>N° Facture</th>
                                  <th>Designation</th>
                                  <th>Salle</th>
                                  <th>Partenaire</th>
                                  <th>Nombre jours</th>
                                  <th>Montant/jour</th>
                                  <th>Montant</th>
                                  <th>Date</th>

                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(facture, index) in factureSolde">
                                  <td class="left">{{ index + 1 }}</td>
                                  <td class="left">{{ facture.id }}{{ formatDate(facture.created_at) }}</td>
                                  <td class="left">
                                    {{ facture.designation }}
                                  </td>
                                  <td class="left">{{ facture.salle }}</td>
                                  <td class="left">{{ facture.partenaire }}</td>
                                  <td class="left">
                                    {{ facture.nombre_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour }}
                                  </td>
                                  <td class="left">
                                    {{ facture.montant_jour *
          facture.nombre_jour }}
                                  </td>

                                  <td class="left">
                                    {{ facture.date_facture }}
                                  </td>

                                  <td>
                                    <a class="tblEditBtn" title="Imprimer" @click="factureDefImprime(facture.id)">
                                      <i class="fa fa-print"></i>

                                    </a>

                                    <a class="tblEditBtn" title="Modifier" @click="openModal(utilisateur)">
                                      <i class="fa fa-pencil"></i>
                                    </a>

                                    <a class="tblDelBtn" title="Supprimer" @click="deleteFacture(utilisateur)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>


                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="row" v-if="this.factureSolde.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_begins }} à
                                  {{ to_begins }} sur {{ totals }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_pages" :total="totals" :per-page="per_pages"
                                    :current-page="current_pages" @pagechanged="pagPageSolde"></paginate-component>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="page-content-wrapper" v-show="editModal">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Modifier Facture</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" href="'/dashboard'">Tableau de Bord</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" href="'/liste-facture'">Facture</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Modifier Facture</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button">
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body row">
                  <FormulaireModification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";

import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import FormulaireModification from "./editFacture.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import { mapState, mapActions } from "vuex";
import permissionMixin from "@/permission";

export default {
  name: "listeFactureCompenent",
  components: {
    FormulaireModification,
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    PaginateComponent,
  },
  mixins: [permissionMixin],

  data() {
    return {

      limitQueryProforma: 5,
      limitQueryDefinitive: 5,
      limitQueryAcompte: 5,
      limitQuerySolde: 5,

      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,

      total_pagep: 0,
      totalp: 0,
      per_pagep: 0,
      current_pagep: 1,
      from_beginp: 0,
      to_beginp: 0,
      is_loadingP: false,

      total_pagea: 0,
      totala: 0,
      per_pagea: 0,
      current_pagea: 1,
      from_begina: 0,
      to_begina: 0,
      is_loadingA: false,

      total_paged: 0,
      totald: 0,
      per_paged: 0,
      current_paged: 1,
      from_begind: 0,
      to_begind: 0,
      is_loadingD: false,

      total_pages: 0,
      totals: 0,
      per_pages: 0,
      current_pages: 1,
      from_begins: 0,
      to_begins: 0,
      is_loadingS: false,

      searchQuery: "",

      form: new Form({
        designation: "",
        nombre_jour: "",
        montant_jour: "",
        date_location: "",
        id_partenaire: "",
        id_salle: "",
        id_user: "",
      }),
      factureProforma: [],
      factureDefinitive: [],
      factureSolde: [],
      factureAcompte: [],
      idUser: "",
      editModal: false,

      currentPageProforma: 1,
      currentPageDefinitive: 1,
      currentPageAcompte: 1,
      currentPageSolde: 1,

      lastPageProforma: 1,
      lastPageDefinitive: 1,
      lastPageAcompte: 1,
      lastPageSolde: 1,


      userEnCoursDeModification: null,
      ongletActif: 'tab1',

      endpoints: {
        factureDefinitive: "/facture/definitive",
        factureAcompte: "/facture/acompte",
        factureSolde: "/facture/solde",
      },
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_facture();
    this.getFactures();

    bus.on("factureAjoutee", () => {
      this.get_facture();
      this.getFactures();
    });

    bus.on('changerOngletActif', onglet => {
      this.ongletActif = onglet;
    });

    bus.on("factureDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_facture();
      this.getFactures();
    });
  },
  /*   mounted() {
      this.get_facture();
      this.getFactures();
      bus.on("factureAjoutee", () => {
        this.get_facture();
        this.getFactures();
      });
      bus.on('changerOngletActif', onglet => {
        this.ongletActif = onglet;
      });
      bus.on("factureDejaModifier", (eventData) => {
        this.editModal = eventData.editModal;
        this.get_facture();
        this.getFactures();
      });
    }, */

  methods: {
    ...mapActions(["login", "logout"]),

    pagPageProforma(pagination) {
      this.current_pagep = pagination;
      this.get_facture(this.currentPageProforma);
    },
    pagPageSolde(pagination) {
      this.current_pages = pagination;
      this.get_facture_solde(this.currentPageSolde);
    },
    pagPageDefinitive(pagination) {
      this.current_paged = pagination;
      this.get_facture_definitive(this.currentPageDefinitive);
    },
    pagPageAcompte(pagination) {
      this.current_pagea = pagination;
      this.get_facture_acompte(this.currentPageAcompte);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },

    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQueryProforma !== 5) {
        this.limitQueryProforma = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_facture(this.currentPageProforma);
      }
      if (this.limitQueryDefinitive !== 5) {
        this.limitQueryDefinitive = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_facture_definitive(this.currentPageDefinitive);
      }
      if (this.limitQueryAcompte !== 5) {

        this.limitQueryAcompte = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_facture_acompte(this.currentPageAcompte);
      }
      if (this.limitQuerySolde !== 5) {

        this.limitQuerySolde = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_facture_solde(this.currentPageSolde);
      }
    },


    async getFactures() {
      await Promise.all([
        this.get_facture_definitive(this.currentPageDefinitive),
        this.get_facture_acompte(this.currentPageAcompte),
        this.get_facture_solde(this.currentPageSolde),
      ]);
    },

    async get_facture_definitive(page) {
      this.currentPageDefinitive = page;
      const filterCondition = (facture) =>
        facture.type === "Definitive";
      const perPage = this.limitQueryDefinitive;
      if (this.hasReadPermission("Facture")) {

        const usersResponse = await this.fetchFactures(
          "/facture/definitive",
          this.current_paged,
          filterCondition,
          perPage
        );
        this.factureDefinitive = usersResponse.filteredDefinitive;

        this.total_paged = usersResponse.page.total_page;
        this.totald = usersResponse.page.total;
        this.per_paged = usersResponse.page.per_page;
        this.current_paged = usersResponse.page.current_page;
        this.from_begind = usersResponse.page.from_begin;
        this.to_begind = usersResponse.page.to_begin;
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_facture_acompte(page) {
      this.currentPageAcompte = page;
      const filterCondition = (facture) =>
        facture.type === "Acompte";
      const perPage = this.limitQueryAcompte;
      if (this.hasReadPermission("Facture")) {

        const usersResponse = await this.fetchFactures(
          "/facture/acompte",
          this.current_pagea,
          filterCondition,
          perPage
        );
        this.factureAcompte = usersResponse.filteredDefinitive;

        this.total_pagea = usersResponse.page.total_page;
        this.totala = usersResponse.page.total;
        this.per_pagea = usersResponse.page.per_page;
        this.current_pagea = usersResponse.page.current_page;
        this.from_begina = usersResponse.page.from_begin;
        this.to_begina = usersResponse.page.to_begin;
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_facture_solde(page) {
      this.currentPageSolde = page;
      const filterCondition = (facture) =>
        facture.type === "Solde";
      const perPage = this.limitQuerySolde;
      if (this.hasReadPermission("Facture")) {

        const usersResponse = await this.fetchFactures(
          "/facture/solde",
          this.current_pages,
          filterCondition,
          perPage
        );
        this.factureSolde = usersResponse.filteredDefinitive;

        this.total_pages = usersResponse.page.total_page;
        this.totals = usersResponse.page.total;
        this.per_pages = usersResponse.page.per_page;
        this.current_pages = usersResponse.page.current_page;
        this.from_begins = usersResponse.page.from_begin;
        this.to_begins = usersResponse.page.to_begin;
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    formatDate(date) {
      const dateObj = new Date(date);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return year + month + day;
    },

    /*     async get_facture() {
          const token = localStorage.getItem("token");
          const filterCondition = "";
    
          const headers = { Authorization: `Bearer ${token}` };
          const params = {
            per_page: this.limitQueryProforma,
            page: this.current_pagep,
            // Ajoutez d'autres paramètres si nécessaire
          };
          try {
            // const response = await axios.get('/direction/index/paginate', { headers });
            const response = await axios
              .get("/location/index/proforma", {
                headers: headers,
                params: params, // Ajouter les paramètres ici
              });
            const allFacture = response.data.location.data;
    
            const filteredFactures = allFacture.filter((location) => {
              return (
                filterCondition(location) &&
                Object.keys(facture).reduce((acc, key) => {
                  const value = facture[key];
                  if (typeof value === 'string') {
                    acc.push(value.toLowerCase());
                  } else if (value && typeof value === 'object') {
                    Object.values(value).forEach((nestedValue) => {
                      if (typeof nestedValue === 'string') {
                        acc.push(nestedValue.toLowerCase());
                      }
                    });
                  }
                  return acc;
                }, []).some((value) => value.includes(this.searchQuery.toLowerCase()))
              );
            });
           
            const formattedFactures = filteredFactures.map((facture) => {
              return {
                id: facture.id,
                editModal: true,
                designation: facture.designation,
                objet: facture.objet,
                nombre_jour: facture.nombre_jour,
                montant_jour: facture.montant_jour,
                date_location: facture.date_location,
                created_at: facture.created_at,
                partenaire: facture.partenaire.nom_partenaire,
    
                reserver: facture.reserver,
                id_partenaire: facture.partenaire.id,
    
                nom: facture.user.nom,
                prenom: facture.user.prenom,
              };
            });
            console.log(formattedFactures)
    
            //this.factureProforma = formattedFactures;
            const page = {
              total_page: response.data.facture.last_page,
              total: response.data.facture.total,
              per_page: response.data.facture.per_page,
              current_page: response.data.facture.current_page,
              from_begin: response.data.facture.from,
              to_begin: response.data.facture.to,
            };
    
    
    
            this.factureProforma = formattedFactures;
            this.total_pagep = page.total_page;
            this.totalp = page.total;
            this.per_pagep = page.per_page;
            this.current_pagep = page.current_page;
            this.from_beginp = page.from_begin;
            this.to_beginp = page.to_begin;
    
          } catch (error) {
            Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des factures proforma', 'error');
          }
        }, */

    async get_facture() {
      const token = localStorage.getItem("token");

      const headers = { Authorization: `Bearer ${token}` };
      const params = {
        per_page: this.limitQueryProforma,
        page: this.current_pagep,
        // Ajoutez d'autres paramètres si nécessaire
      };
      if (this.hasReadPermission("Facture")) {
        try {
          const response = await axios.get("/location/index/proforma", {
            headers: headers,
            params: params, // Ajouter les paramètres ici
          });
          const allFacture = response.data.location.data;

          const filteredFactures = allFacture.filter((location) => {
            return (
              // Mettez votre condition de filtrage ici
              Object.keys(location).reduce((acc, key) => {
                const value = location[key];
                if (typeof value === 'string') {
                  acc.push(value.toLowerCase());
                } else if (value && typeof value === 'object') {
                  Object.values(value).forEach((nestedValue) => {
                    if (typeof nestedValue === 'string') {
                      acc.push(nestedValue.toLowerCase());
                    }
                  });
                }
                return acc;
              }, []).some((value) => typeof value === 'string' && value.includes(this.searchQuery.toLowerCase()))
            );
          });

          const formattedFactures = filteredFactures.map((facture) => {
            return {
              id: facture.id,
              editModal: true,
              designation: facture.designation,
              objet: facture.objet,
              nombre_jour: facture.nombre_jour,
              montant_jour: facture.montant_jour,
              date_location: facture.date_location,
              created_at: facture.created_at,
              partenaire: facture.partenaire.nom_partenaire,
              reserver: facture.reserver,
              id_partenaire: facture.partenaire.id,
              nom: facture.user.nom,
              prenom: facture.user.prenom,
            };
          });

          this.factureProforma = formattedFactures;
          this.total_pagep = response.data.location.last_page;
          this.totalp = response.data.location.total;
          this.per_pagep = response.data.location.per_page;
          this.current_pagep = response.data.location.current_page;
          this.from_beginp = response.data.location.from;
          this.to_beginp = response.data.location.to;



        } catch (error) {
          Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des factures proforma', 'error');
        }
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async fetchFactures(endpoint, page, filterCondition, perPage) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const response = await axios.get(endpoint, {
          params: {
            page: page,
            per_page: perPage,
          },
          headers: headers,
        });

        const allFacture = response.data.facture.data;

        const filteredDefinitive = allFacture.filter((facture) => {
          return (
            filterCondition(facture) &&
            Object.values(facture).reduce((acc, value) => {
              if (typeof value === 'string') {
                acc.push(value.toLowerCase());
              }
              return acc;
            }, []).some((value) => value.includes(this.searchQuery.toLowerCase()))
          );
        });

        return {
          filteredDefinitive: filteredDefinitive.map((facture) => ({

            designation: facture.location.designation,
            id: facture.id,
            salle: facture.location.salle.intitule,
            nombre_jour: facture.location.nombre_jour,
            montant_jour: facture.location.montant_jour,
            montant_payer: facture.montant_payer,
            date_facture: facture.date_facture,
            created_at: facture.created_at,
            objet: facture.objet,
            type: facture.type,
            id_reservation: facture.id_reservation ? facture.id_reservation : "",

            partenaire: facture.location.partenaire.nom_partenaire,

            /*  nom: facture.user.nom,
             prenom: facture.user.prenom,  */
          })),
          page: {
            // Autres données supplémentaires que vous souhaitez retourner
            total_page: response.data.facture.last_page,
            total: response.data.facture.total,
            per_page: response.data.facture.per_page,
            current_page: response.data.facture.current_page,
            from_begin: response.data.facture.from,
            to_begin: response.data.facture.to,
          },

        };
      } catch (error) {
        console.log(error);
        Swal.fire(
          "Erreur!",
          "Une erreur est survenue lors de la récupération des utilisateurs",
          "error"
        );
        return [];
      }
    },


    /*   async get_facture_valide() {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        await axios
          .get("/facture/index/facture/paginate", { headers })
          .then((response) => {
            const allFacture = response.data.facture.data;
  
            const filteredDefinitive = allFacture.filter((facture) => {
              return facture.type === "Definitive";
            });
            
            const DefinitiveFacture = filteredDefinitive.map((facture) => {
              return {
             
                designation: facture.location.designation,
                id:facture.id,
                salle: facture.location.salle.intitule,
                nombre_jour: facture.location.nombre_jour,
                montant_jour: facture.location.montant_jour,
                montant_payer: facture.montant_payer,
                date_facture: facture.date_facture,
                created_at: facture.created_at,
                objet: facture.objet,
                type: facture.type,
  
                partenaire: facture.location.partenaire.nom_partenaire,
              };
            });
  
            const filteredAcompte = allFacture.filter((facture) => {
              return facture.type === "Acompte";
            });
          
            const AcompteFacture = filteredAcompte.map((facture) => {
              return {
                designation: facture.location.designation,
                id:facture.id,
                id_location:facture.id_location,
                salle: facture.location.salle.intitule,
                nombre_jour: facture.location.nombre_jour,
                montant_jour: facture.location.montant_jour,
                montant_payer: facture.montant_payer,
                date_facture: facture.date_facture,
                created_at: facture.created_at,
                objet: facture.objet,
                type: facture.type,
                id_reservation: facture.id_reservation,
                partenaire: facture.location.partenaire.nom_partenaire,
              };
            });
  
            const filteredSolde = allFacture.filter((facture) => {
              return facture.type === "Solde";
            });
  
            const SoldeFacture = filteredSolde.map((facture) => {
              return {
                designation: facture.location.designation,
                id:facture.id,
                salle: facture.location.salle.intitule,
                nombre_jour: facture.location.nombre_jour,
                montant_jour: facture.location.montant_jour,
                montant_payer: facture.montant_payer,
                date_facture: facture.date_facture,
                created_at: facture.created_at,
                objet: facture.objet,
                type: facture.type,
  
               partenaire: facture.location.partenaire.nom_partenaire,
  
              };
            });
  
            this.factureDefinitive = DefinitiveFacture;
            this.initDataTableFactureDefinitive()
  
            this.factureAcompte = AcompteFacture;
            this.initDataTableFactureAcompte()
  
            this.factureSolde = SoldeFacture;
            this.initDataTableFactureSolde()
  
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recupération des factures ",
              "error"
            );
          });
      }, */

    changement(event) {
      this.interesser = event;
    },

    async deleteLocation(type) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasDeletePermission("Facture")) {
        Swal.fire({
          title: 'Êtes-vous sûr de vouloir supprimer cet enregistrement?',
          text: "Cette action sera irréversible!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non'
        }).then((result) => {
          if (result.value) {
            axios.delete(`/location/delete/${type.id}`, { headers }).then(resp => {

              showDialog6("Ligne supprimée avec succès");
              this.get_facture();



            }).catch(function (error) {
              console.log(error);
            })
          }

        });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }

    },

    async deleteLocation(type) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasDeletePermission("Facture")) {
        Swal.fire({
          title: 'Êtes-vous sûr de vouloir supprimer cet enregistrement?',
          text: "Cette action sera irréversible!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non'
        }).then((result) => {
          if (result.value) {
            axios.delete(`/location/delete/${type.id}`, { headers }).then(resp => {

              showDialog6("Ligne supprimée avec succès");
              this.get_facture();



            }).catch(function (error) {
              console.log(error);
            })
          }

        });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async deleteFacture(type) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasDeletePermission("Facture")) {
        Swal.fire({
          title: 'Êtes-vous sûr de vouloir supprimer cet enregistrement?',
          text: "Cette action sera irréversible!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non'
        }).then((result) => {
          if (result.value) {
            axios.delete(`/facture/delete/${type.id}`, { headers }).then(resp => {

              showDialog6("Ligne supprimée avec succès");
              this.get_facture();


            }).catch(function (error) {
              console.log(error);
            })
          }

        });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    openModal(facture) {
      this.editModal = true;
      const eventData = {
        facture: facture,
        /* editModal: true */
      };
      bus.emit('factureModifier', eventData);
    },

    reserverFacture(factureId, reserver) {
      if (reserver === 1) {
        showDialog3("Cette facture à été déjà réserver");
      }
      else {
        this.$router.push({ name: 'reservation', params: { id: factureId } });
      }
    },

    factureImprime(factureId) {
      this.$router.push({ name: 'imprimerFactureProforma', params: { id: factureId } });
    },
    factureDefImprime(factureId) {
      this.$router.push({ name: 'imprimerFactureReserver', params: { id: factureId } });
    },

    erreurFactureSolde() {
      showDialog3("Cette facture à été déjà valider");
    },

    async facturerSolde(facture) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();

      //formdata.append("date_facture", this.form.date_facture);
      formdata.append("montant_payer", facture.nombre_jour *
        facture.montant_jour - facture.montant_payer);

      formdata.append("id_user", this.userIdrole);
      formdata.append("objet", facture.objet);
      formdata.append("id_location", facture.id_location);
      formdata.append("id_reservation", facture.id_reservation);
      formdata.append("type", "Solde");
      if (this.hasCreatePermission("Facture")) {
        try {
          const reservation_store = await axios.post("/facture/store", formdata, {
            headers,
          });
          if (reservation_store.data == 401) {
            showDialog3("Cette facture à été déjà réserver");
          } else {
            showDialog6("Facture ajoutée avec succès");
            /* bus.emit("factureAjoutee;");
            this.resetForm(); */
            setTimeout(() => {
              window.location.href = "/liste-facture";
            }, 1500);
          }
        } catch (e) {
          console.log(e)
          if (e.request.status === 404) {
            showDialog3("Une erreur est survenue lors de l'enregistrement");
          }
        }
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      //return url ? `http://127.0.0.1:8000/storage/${url}` : "";
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },
  },
};
</script>
