<template>
  <div class="page-wrapper">
    <!-- start header -->
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userPhoto="userPhoto"
          :userNom="userNom"
          :userPrenom="userPrenom"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <alerte />
              <div class="pull-left">
                <div class="page-title">Tableau de bord</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'dashboard'"
                    >Accueil</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Tableau de bord</li>
              </ol>
            </div>
          </div>
          <!-- start widget -->
          <div class="row">
            <div class="row">
              <div class="col-xl-3">
                <div class="card p-4">
                  <!--  <div class="card-body"> -->
                  <div class="row">
                    <div class="col mt-0">
                      <h4 class="info-box-title">Recouvrement Etudiant</h4>
                    </div>
                    <div class="col-auto">
                      <div class="l-bg-green info-icon">
                        <i class="fa fa-users pull-left col-orange font-30"></i>
                      </div>
                    </div>
                  </div>
                  <h1 class="mt-1 mb-3 info-box-title">3000</h1>
                  <div class="mb-0">
                    <span class="text-success m-r-10"
                      ><i class="material-icons col-green align-middle"
                        >trending_up</i
                      >
                      10.32%
                    </span>
                    <span class="text-muted"> Pour Ce Mois</span>
                  </div>
                  <!--  </div> -->
                </div>
              </div>
              <div class="col-xl-3">
                <div class="card p-4">
                  <!--  <div class="card-body"> -->
                  <div class="row">
                    <div class="col mt-0">
                      <h4 class="info-box-title">Recouvrement Location</h4>
                    </div>
                    <div class="col-auto">
                      <div class="col-indigo info-icon">
                        <i class="fa fa-book pull-left card-icon font-30"></i>
                      </div>
                    </div>
                  </div>
                  <h1 class="mt-1 mb-3 info-box-title">2500</h1>
                  <div class="mb-0">
                    <span class="text-danger m-r-10"
                      ><i class="material-icons col-red align-middle"
                        >trending_down</i
                      >
                      -10.64%
                    </span>
                    <span class="text-muted"> Pour Ce Mois</span>
                  </div>
                  <!--   </div> -->
                </div>
              </div>
              <div class="col-xl-3">
                <div class="card p-4">
                  <!--  <div class="card-body"> -->
                  <div class="row">
                    <div class="col mt-0">
                      <h4 class="info-box-title">Recouvrement service</h4>
                    </div>
                    <div class="col-auto">
                      <div class="col-teal info-icon">
                        <i class="fa fa-user pull-left card-icon font-30"></i>
                      </div>
                    </div>
                  </div>
                  <h1 class="mt-1 mb-3 info-box-title">323</h1>
                  <div class="mb-0">
                    <span class="text-success m-r-10"
                      ><i class="material-icons col-green align-middle"
                        >trending_up</i
                      >
                      21..19%
                    </span>
                    <span class="text-muted">Pour Ce Mois</span>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
              <div class="col-xl-3">
                <div class="card p-4">
                  <!--  <div class="card-body"> -->
                  <div class="row">
                    <div class="col mt-0">
                      <h4 class="info-box-title">Recettes totales</h4>
                    </div>
                    <div class="col-auto">
                      <div class="col-pink info-icon">
                        <i class="fa fa-coffee pull-left card-icon font-30"></i>
                      </div>
                    </div>
                  </div>
                  <h1 class="mt-1 mb-3 info-box-title">2,352</h1>
                  <div class="mb-0">
                    <span class="text-danger m-r-10"
                      ><i class="material-icons col-red align-middle"
                        >trending_down</i
                      >
                      -4.27%
                    </span>
                    <span class="text-muted">Since last week</span>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>

          </div>

          <!-- end widget -->
          <div class="row">
            <div class="col-lg-8 col-md-12 col-sm-12 col-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Audits</header>
                </div>
                <div class="card-body">
                  <div class="row">
                    <ul class="docListWindow small-slimscroll-style">
                      <li v-for="audit in audits">
                        <div class="prog-avatar">
                          <img
                            :src="this.getImageUrl(audit.user.photo)"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#"
                              >{{ audit.user.nom }} {{ audit.user.prenom }}</a
                            >
                            -{{ formatTime(audit.created_at) }}
                          </div>
                          <div v-show="audit.action == 'Suppression'">
                            <span class="feedLblStyle lblFileStyle">{{
                              audit.action
                            }}</span>
                            {{ audit.details }}
                            <small class="text-muted">{{
                              formatDateTime(audit.created_at)
                            }}</small>
                          </div>
                          <div v-show="audit.action == 'Creation'">
                            <span class="feedLblStyle lblTaskStyle">{{
                              audit.action
                            }}</span>
                            {{ audit.details }}
                            <small class="text-muted">{{
                              formatDateTime(audit.created_at)
                            }}</small>
                          </div>
                          <div v-show="audit.action == 'Modification'">
                            <span class="feedLblStyle lblCommentStyle">{{
                              audit.action
                            }}</span>
                            {{ audit.details }}
                            <small class="text-muted">{{
                              formatDateTime(audit.created_at)
                            }}</small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Liste des Caissiers</header>
                </div>
                <div class="card-body">
                  <div class="row">
                    <ul class="docListWindow small-slimscroll-style">
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user1.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">Rajesh</a> -(M.Com, PHD)
                          </div>
                          <div>
                            <span class="clsAvailable">Available</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user2.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">Sarah Smith</a> -(M.A., B.Ed)
                          </div>
                          <div>
                            <span class="clsAvailable">Available</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user3.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">John Deo</a> - (B.C.A., M.C.A.)
                          </div>
                          <div>
                            <span class="clsNotAvailable">Not Available</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user4.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">Jay Soni</a> - (B.E., M.E.)
                          </div>
                          <div>
                            <span class="clsOnLeave">On Leave</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user5.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">Jacob Ryan</a> - (M.Phil)
                          </div>
                          <div>
                            <span class="clsNotAvailable">Not Available</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user6.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">Megha Trivedi</a> - (M.S.W, PHD)
                          </div>
                          <div>
                            <span class="clsAvailable">Available</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user2.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">Sarah Smith</a> -(B.S.C, M.S.C.)
                          </div>
                          <div>
                            <span class="clsAvailable">Available</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user3.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">John Deo</a> - (B.E., M.E.)
                          </div>
                          <div>
                            <span class="clsNotAvailable">Not Available</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="prog-avatar">
                          <img
                            :src="'./assets/img/user/user4.jpg'"
                            alt=""
                            width="40"
                            height="40"
                          />
                        </div>
                        <div class="details">
                          <div class="title">
                            <a href="#">Jay Soni</a> - (B.C.A., M.C.A.)
                          </div>
                          <div>
                            <span class="clsOnLeave">On Leave</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="full-width text-center p-t-10">
                      <a
                        href="#"
                        class="btn purple btn-outline btn-circle margin-0"
                        >Voir plus</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12 col-12">
              <div class="white-box border-gray">
                <h4 class="box-title">
                  <small class="pull-right m-t-10 text-success"
                    ><i class="fa fa-sort-asc"></i> 25% Plus élevé que le mois
                    dernier</small
                  >
                  Bilan financier mensuel
                </h4>
                <div class="stats-row">
                  <div class="stat-item">
                    <h6>Croissance globale</h6>
                    <b>35.80%</b>
                  </div>
                  <div class="stat-item">
                    <h6>Par Mois</h6>
                    <b>45.20%</b>
                  </div>
                  <div class="stat-item">
                    <h6>Par Jour</h6>
                    <b>5.50%</b>
                  </div>
                </div>
                <div id="sparkline13">
                  <canvas
                    height="50"
                    style="
                      display: inline-block;
                      width: 378px;
                      height: 50px;
                      vertical-align: top;
                    "
                  ></canvas>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-12">
              <div class="white-box border-gray">
                <h4 class="box-title">
                  <small class="pull-right m-t-10 text-danger"
                    ><i class="fa fa-sort-desc"></i> 30% Inférieur à celui du
                    mois dernier</small
                  >Analyse des remboursements
                </h4>
                <div class="stats-row">
                  <div class="stat-item">
                    <h6>Croissance globale</h6>
                    <b>20.60%</b>
                  </div>
                  <div class="stat-item">
                    <h6>Par Mois</h6>
                    <b>65.30%</b>
                  </div>
                  <div class="stat-item">
                    <h6>Par Jour</h6>
                    <b>4.90%</b>
                  </div>
                </div>
                <div id="sparkline15">
                  <canvas
                    height="50"
                    style="
                      display: inline-block;
                      width: 378px;
                      height: 50px;
                      vertical-align: top;
                    "
                  ></canvas>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 col-12">
              <div class="white-box border-gray">
                <h4 class="box-title">
                  <small class="pull-right m-t-10 text-success"
                    ><i class="fa fa-sort-asc"></i> 20% Plus élevé que le mois
                    dernier</small
                  >Taux de paiement en retard
                </h4>
                <div class="stats-row">
                  <div class="stat-item">
                    <h6>Croissance globale</h6>
                    <b>38.40%</b>
                  </div>
                  <div class="stat-item">
                    <h6>Par Mois</h6>
                    <b>52.49%</b>
                  </div>
                  <div class="stat-item">
                    <h6>Par Jour</h6>
                    <b>4.70%</b>
                  </div>
                </div>
                <div id="sparkline14">
                  <canvas
                    height="50"
                    style="
                      display: inline-block;
                      width: 378px;
                      height: 50px;
                      vertical-align: top;
                    "
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="card card-box">
                <div class="card-head">
                  <header>Liste Etudiant inscrit</header>
                  <div class="tools">
                    <a
                      class="fa fa-repeat btn-color box-refresh"
                      href="javascript:;"
                    ></a>
                    <a
                      class="t-collapse btn-color fa fa-chevron-down"
                      href="javascript:;"
                    ></a>
                    <a
                      class="t-close btn-color fa fa-times"
                      href="javascript:;"
                    ></a>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-wrap">
                    <div class="table-responsive">
                      <table
                        class="table display product-overview mb-30"
                        id="support_table"
                      >
                        <thead>
                          <tr>
                            <th>Photo</th>
                            <th>Matricule</th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Classe</th>
                            <th>Montant</th>
                            <th>Année Académique</th>
                            <th>Date</th>
                            <th>Etat paiement</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="odd gradeX"
                            v-for="(inscription, index) in inscriptions"
                          >
                            <td class="patient-img">
                              <img
                                :src="getImageUrl(inscription.photo)"
                                alt="Etu"
                              />
                            </td>
                            <td>{{ inscription.matricule }}</td>
                            <td class="left">
                              {{ inscription.nom }}
                            </td>
                            <td class="left">
                              {{ inscription.prenom }}
                            </td>
                            <td class="left">
                              {{ inscription.classe }}
                            </td>
                            <td class="left">{{ inscription.montant }} F</td>
                            <td class="left">
                              {{ inscription.annee_academique }}
                            </td>
                            <td class="left">
                              {{ formatDateTime(inscription.date) }}
                            </td>
                            <td>
                              <span class="label label-sm label-success"
                                >Soldé</span
                              >
                            </td>
                            <td>
                              <a
                                class="tblEditBtn"
                                @click="openModal(inscription)"
                              >
                                <i class="fa fa-pencil"></i>
                              </a>
                              <a
                                href="javascript:void(0)"
                                class="tblDelBtn"
                                @click="deleteInscription(inscription)"
                              >
                                <i class="fa fa-trash-o"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
  <!--  </div> -->
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import * as sparklineData from "/public/assets/js/pages/sparkline/sparkline-data.js";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import alerte from "../alerte/listeAlerteComponent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    alerte,
  },
  data() {
    return {
      form: new Form({
        nom: "",
        prenom: "",
        genre: "",
        adresse: "",
        telephone: "",
        email: "",
        date_naissance: "",
        lieu_naissance: "",
        nationalite: "",
        id_role: "",
        id_specialite: "",
        id_departement: "",
        id_service: "",
        type: "",
        situation_matrimoniale: "",
      }),
      utilisateurs: [],
      inscriptions: [],
      roles: [],
      audits: [],
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  mounted() {
    sparklineData.initSparklines();
    this.getInscription();
    this.get_audit();
  },

  methods: {
    ...mapActions(["login", "logout"]),
    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      //return url ? `http://127.0.0.1:8000/storage/${url}` : '';
      return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
    },
    async fetchUtilisateurs() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      // Exemple : remplacez cela par votre logique de récupération de données
      // et assurez-vous que les données sont correctement assignées à utilisateurs
      await axios
        .get("/user/index", { headers })
        .then((response) => {
          this.utilisateurs = response.data.user.slice(0, 7);
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des utilisateurs",
            error
          );
        });
    },
    async getInscription() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      // Exemple : remplacez cela par votre logique de récupération de données
      // et assurez-vous que les données sont correctement assignées à utilisateurs
      await axios
        .get("/inscription/last", { headers })
        .then((response) => {
          const allInscriptions = response.data.inscription;

          const inscriptionVal = allInscriptions.filter((inscription) => {
            return inscription.statut == 1;
          });

          const formattedInscriptionVal = inscriptionVal.map((inscrit) => {
            return {
              id: inscrit.id,
              photo: inscrit.eleve.user.photo,
              matricule: inscrit.eleve.user.matricule,
              nom: inscrit.eleve.user.nom,

              prenom: inscrit.eleve.user.prenom,

              classe:
                inscrit.classe.type_formation.intitule +
                " " +
                inscrit.classe.nom_classe +
                " " +
                inscrit.classe.niveau +
                " " +
                inscrit.classe.type_classe,
              annee_academique: inscrit.annee_academique.intitule,

              statut: inscrit.statut,

              date: inscrit.created_at,
              montant: inscrit.montant,

              nom_departement:
                inscrit.classe.unite_de_formation.departement.nom_departement,
              nom_filiere:
                inscrit.classe.unite_de_formation.nom_unite_formation,
            };
          });
          this.inscriptions = formattedInscriptionVal;
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des etudiants inscrit",
            error
          );
        });
    },
    async get_departement() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/departement/all", { headers })
        .then((response) => {
          this.countDep = response.data.departement.length;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération du nombre de département",
            "error"
          );
        });
    },
    async get_filiere() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/unite_de_formation/all", { headers })
        .then((response) => {
          this.countFiliere = response.data.unite_de_formation.length;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération du nombre de filiere",
            "error"
          );
        });
    },
    async get_eleve() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/user/count", { headers })
        .then((response) => {
          this.countElev = response.data.eleve.length;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recupération du nombre des étudiants",
            "error"
          );
        });
    },
    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : "";
      return url ? `http://127.0.0.1:8000/storage/${url}` : "";
    },
    async fetchUtilisateurs() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      // Exemple : remplacez cela par votre logique de récupération de données
      // et assurez-vous que les données sont correctement assignées à utilisateurs
      await axios
        .get("/user/index", { headers })
        .then((response) => {
          this.utilisateurs = response.data.user.slice(0, 7);
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des utilisateurs",
            error
          );
        });
    },
    async get_audit() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const response = await axios.get("/audit/all", { headers });
        const allAudits = response.data.audit;

        // Filtrer les audits pour obtenir uniquement ceux dont le rôle de l'utilisateur est 4 (caissier)
        const caissierAudits = allAudits.filter(
          (audit) => audit.user.id_role == 4 || audit.user.id_role == 26
        );

        // Trier les audits par ordre décroissant de date
        caissierAudits.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        // Récupérer les 6 derniers audits
        const derniersAudits = caissierAudits.slice(0, 6);

        // Maintenant, vous pouvez utiliser derniersAudits pour afficher les 6 derniers audits des caissiers

        this.audits = derniersAudits;
      } catch (error) {
        Swal.fire(
          "Erreur!",
          "Une erreur est survenue lors de la récupération des audits",
          "error"
        );
      }
    },

    formatDateTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.formatDate(new Date(dateTime));
    },
    formatDate(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      // Tableau des trois premières lettres des mois en français
      const monthAbbreviations = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];

      // Obtenez les trois premières lettres du mois correspondant au numéro du mois
      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },
    formatTime(timerecup) {
      return this.getTime(new Date(timerecup));
    },

    getTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // Ajoutez un zéro devant les heures ou les minutes si elles sont inférieures à 10
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${formattedHours}:${formattedMinutes}`;
    },

    countRoleOccurrences() {
      const roleOccurrences = {};

      this.utilisateurs.forEach((user) => {
        const roleIntitule = user.role ? user.role.intitule : "Non défini";

        if (!roleOccurrences[roleIntitule]) {
          roleOccurrences[roleIntitule] = 1;
        } else {
          roleOccurrences[roleIntitule]++;
        }
      });

      return roleOccurrences;
    },
  },
};
</script>
