<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Accueil</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">
                  Paramétres &nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/liste-cours'"> Cours</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab">Cours</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Tous les Cours</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a :href="'/create-cours'" id="addRow" class="btn btn-primary">
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQuery">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_cours(currentPage)" />
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <!-- <th>Nom du cours</th> -->
                                  <!-- <th>Heure de début</th>
                                  <th>Heure de fin</th>
                                  <th>Date du cours</th> -->
                                  <th>Classe</th>
                                  <th>Formateur</th>
                                  <th>Matiére</th>
                                  <th>Annee Academique</th>
                                  <th>Semestre</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(cour, index) in cours" :key="index">
                                  <td>{{ index + 1 }}</td>
                                 <!--  <td>{{ cour.intitule }}</td> -->
                                <!--   <td>{{ cour.heure_debut }}</td>
                                  <td>{{ cour.heure_fin }}</td> -->
                                  <!-- <td>{{ cour.date_cour }}</td> -->
                                  <td>{{ cour.classe }}</td>
                                  <td>{{ cour.formateur }}</td>
                                  <td>{{ cour.matiere }}</td>
                                  <td>{{ cour.annee }}</td>
                                  <td>{{ cour.semestre }}</td>

                                  <td>
                                    <a class="tblEditBtn" @click="openModal(cour)">
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a class="tblDelBtn" @click="deleteCour(cour)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="row" v-if="this.cours.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_begin }} à
                                  {{ to_begin }} sur {{ total }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_page" :total="total" :per-page="per_page"
                                    :current-page="current_page" @pagechanged="pagPage"></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content-wrapper" v-show="editModal">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Modifier Cours</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Tableau de
                    Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Paramétres</li>
                <li>
                  <a class="parent-item" :href="'/listeCours'">Cours</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Modifier Cours</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button">
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body row">
                  <FormulaireModification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";

import FormulaireModification from './editCourComponent.vue';
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    FormulaireModification,
    PaginateComponent,
  },
  mixins: [permissionMixin],
  name: "listeCourComponent",

  data() {
    return {
      form: new Form({
        intitule: "",
        heure_debut: "",
        heure_fin: "",
        date_cour: "",
        id_classe: "",
        id_formateur: "",
        id_matiere: "",
        id_salle: "",
        id_semestre: "",
      }),

      intitule_erreur: "",
      heure_debut_erreur: "",
      heure_fin_erreur: "",
      date_cour: "",
      id_classe_erreur: "",
      id_formateur_erreur: "",
      id_matiere_erreur: "",
      id_salle_erreur: "",
      id_semestre_erreur: "",
      etatForm: false,
      editModal: false,
      cours: [],
      classes: [],
      formateurs: [],
      matieres: [],
      salles: [],
      semestres: [],
      idCour: "",
      limitQuery: 5,
      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,
      searchQuery: "",
      currentPage: 1,
      lastPage: 1,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  async created() {
    await this.get_permission();
    this.get_classe();
    this.get_cours();
    this.get_formateur();
    this.get_salle();
    this.get_semestre();
    this.get_matiere();

    bus.on("courAjoutee", () => {
      this.get_cours();
    });
    bus.on("courDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_cours();
    });
  },
  /*  mounted() {
        this.get_classe();
        this.get_cours();
        this.get_formateur();
        this.get_salle();
        this.get_semestre();
        this.get_matiere();

        bus.on('courAjoutee', () => { 
            this.get_cours(); 
        });
        bus.on('courDejaModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.get_cours();
        });
    }, */

  methods: {
    ...mapActions(["login", "logout"]),

    pagPage(pagination) {
      this.current_page = pagination;
      this.get_cours(this.currentPage);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },

    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQuery !== 5) {
        this.limitQuery = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_cours(this.currentPage);
      }
    },
    formatDateTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.formatDate(new Date(dateTime));
    },
    formatDate(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      // Tableau des trois premières lettres des mois en français
      const monthAbbreviations = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];

      // Obtenez les trois premières lettres du mois correspondant au numéro du mois
      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },

    async get_cours() {
      const token = localStorage.getItem("token");
      const filterCondition = (direct) => true; // Condition de filtre à définir

      const headers = { Authorization: `Bearer ${token}` };
      const params = {
        per_page: this.limitQuery,
        page: this.current_page,
        // Ajoutez d'autres paramètres si nécessaire
      };
      if (this.hasReadPermission("Cours")) {
        try {
          const response = await axios.get("/cour/all/paginate", {
            headers: headers,
            params: params, // Ajouter les paramètres ici
          });
          const allcour = response.data.cour.data;

          const filteredCours = allcour.filter((cour) => {
            return (
              filterCondition(cour) &&
              Object.keys(cour)
                .reduce((acc, key) => {
                  const value = cour[key];
                  if (typeof value === "string") {
                    acc.push(value.toLowerCase());
                  } else if (value && typeof value === "object") {
                    Object.values(value).forEach((nestedValue) => {
                      if (typeof nestedValue === "string") {
                        acc.push(nestedValue.toLowerCase());
                      }
                    });
                  }
                  return acc;
                }, [])
                .some((value) => value.includes(this.searchQuery.toLowerCase()))
            );
          });

          const formattedCours = filteredCours.map((cour) => {
            return {
              id: cour.id,
              id_classe: cour.classe.id,
              id_formateur: cour.formateur.id,
              id_matiere: cour.matiere.id,
            //  id_salle: cour.salle.id,
              id_semestre: cour.semestre.id,
              /* intitule: cour.intitule, */
              id_classe: cour.classe.id,
              id_formateur: cour.formateur.id,
              id_matiere: cour.matiere.id,
              id_annee_academique: cour.id_annee_academique,
              annee: cour.annee.intitule,
            //  id_salle: cour.salle.id,
              id_semestre: cour.semestre.id,
              /* intitule: cour.intitule, */
             /*  heure_debut: cour.heure_debut,
              heure_fin: cour.heure_fin,
              date_cour: cour.date_cour, */
              classe: `${cour.classe.nom_classe} ${cour.classe.niveau} ${cour.classe.type_classe}`,
              formateur: `${cour.formateur.user.prenom} ${cour.formateur.user.nom}`,
              matiere: cour.matiere.intitule,
             // salle: cour.salle.intitule,
              semestre: cour.semestre.intitule,
              editModal: true,
            };
          });
          const page = {
            total_page: response.data.cour.last_page,
            total: response.data.cour.total,
            per_page: response.data.cour.per_page,
            current_page: response.data.cour.current_page,
            from_begin: response.data.cour.from,
            to_begin: response.data.cour.to,
          };

          this.cours = formattedCours;
          this.total_page = page.total_page;
          this.total = page.total;
          this.per_page = page.per_page;
          this.current_page = page.current_page;
          this.from_begin = page.from_begin;
          this.to_begin = page.to_begin;

        } catch (error) {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la récupération des cours",
            "error"
          );
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    async get_classe() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        await axios
          .get("/classe/all", { headers })
          .then((response) => {
            this.classes = response.data.classe;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recupération des derniers classes",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    async get_formateur() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        await axios
          .get("/formateur/index", { headers })
          .then((response) => {
            this.formateurs = response.data.formateur;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recupération des derniers classes",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    get_matiere() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        axios
          .get("/matiere/index", { headers })
          .then((response) => {
            this.matieres = response.data.matiere;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernieres matieres",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    get_semestre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        axios
          .get("/semestre/index", { headers })
          .then((response) => {
            this.semestres = response.data.semestre;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernieres matieres",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_salle() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        await axios
          .get("/salle/index", { headers })
          .then((response) => {
            this.salles = response.data.salle;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernières salles",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    resetForm() {
      this.intitule_erreur = "";

     /*  this.form.intitule_erreur = ""; */
      this.form.heure_debut_erreur = "";
      this.form.heure_fin_erreur = "";
      this.form.date_cour = "";
      this.form.id_classe_erreur = "";
      this.form.id_formateur_erreur = "";
      this.form.id_matiere_erreur = "";
      this.form.id_salle_erreur = "";
      this.form.id_semestre_erreur = "";
      this.editModal = false;
      this.form.intitule = "";
      this.form.heure_debut = "";
      this.form.heure_fin = "";
      this.form.date_cour = "";
      this.form.id_classe = "";
      this.form.id_formateur = "";
      this.form.id_matiere = "";
      this.form.id_salle = "";
      this.form.id_semestre = "";

      const eventData = {
        editModal: false,
      };
      bus.emit("courDejaModifier", eventData);
    },
    async deleteCour(type) {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    if (this.hasDeletePermission("Cours")) {
      Swal.fire({
        title: "Voulez-vous confirmer la suppression?",
        text: "Cette action sera irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(`/cour/delete/${type.id}`, { headers })
            .then((resp) => {
              showDialog6("Cours supprimé avec succés");
              this.get_cours();
            })
            .catch(function (error) {
              console.log(error);
              showDialog3("Erreur lors de la suppression du cours");
            });
        }
      });
    } else {
      // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
      showDialog3("Vous n'avez pas la permission requise.");
    }
  },
  openModal(cour) {
    this.editModal = true;
    // Créez un objet avec les données à envoyer
    const eventData = {
      cour: cour,
      editModal: this.editModal,
      // Ajoutez d'autres propriétés si nécessaire
    };
    bus.emit("courModifier", eventData);
  },
}
};
</script>
