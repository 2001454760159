<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content" v-if="!viewListe">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Recouvrement</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboardCaissier'"
                    >Tableau de Bord</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">
                  Recouvrement &nbsp;<a class="parent-item"></a
                  >
                </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button
                    id="panel-button"
                    class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton"
                  >
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul
                    class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button"
                  >
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body row">
                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="directionSelect"
                        v-show="!form.id_annee_academique"
                      >
                        Choisissez l'année academique
                      </label>
                      <select
                        class="mdl-textfield__input"
                        id="directionSelect"
                        readonly
                        tabIndex="-1"
                        v-model="form.id_annee_academique"
                        @change="validatedata('annee_academique')"
                      >
                        <option
                          v-for="annee_academique in annee_academiques"
                          :value="annee_academique.id"
                        >
                          {{ annee_academique.intitule }}
                        </option>
                      </select>
                      <span class="erreur">{{
                        id_annee_academique_erreur
                      }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                    >
                      <label
                        class="mdl-textfield__label"
                        for="directionMois"
                        v-show="!form.id_mois"
                      >
                        Choisissez le mois
                      </label>
                      <select
                        class="mdl-textfield__input"
                        id="directionMois"
                        readonly
                        tabIndex="-1"
                        v-model="form.id_mois"
                        @change="validatedata('mois')"
                      >
                        <option v-for="mois in mois" :value="mois.id">
                          {{ mois.intitule }}
                        </option>
                      </select>
                      <span class="erreur">{{ id_mois_erreur }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                    >
                      <label
                        for="list6"
                        class="mdl-textfield__label"
                        v-show="!form.id_departement"
                        >Choisissez le département</label
                      >
                      <select
                        class="mdl-textfield__input"
                        id="list6"
                        readonly
                        tabIndex="-1"
                        v-model="form.id_departement"
                        @change="get_filiere(form.id_departement)"
                      >
                        <option
                          v-for="departement in departements"
                          :value="departement.id"
                        >
                          {{ departement.nom_departement }}
                        </option>
                      </select>
                      <span class="erreur">{{ id_departement_erreur }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                    >
                      <label
                        for="list7"
                        class="mdl-textfield__label"
                        v-show="!form.id_departement"
                        >Choisissez la Filière</label
                      >
                      <select
                        class="mdl-textfield__input"
                        id="list7"
                        readonly
                        tabIndex="-1"
                        v-model="form.id_unite_de_formation"
                        @change="get_classe(form.id_unite_de_formation)"
                      >
                        <option
                          v-for="unite_de_formation in unite_de_formations"
                          :value="unite_de_formation.id"
                        >
                          {{ unite_de_formation.nom_unite_formation }}
                        </option>
                      </select>
                      <span class="erreur">{{
                        id_unite_de_formation_erreur
                      }}</span>
                    </div>
                  </div>

                  <div class="col-lg-6 p-t-20">
                    <div
                      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
                    >
                      <label
                        for="list8"
                        class="mdl-textfield__label"
                        v-show="!form.id_departement"
                        >Choisissez la classe</label
                      >
                      <select
                        class="mdl-textfield__input"
                        id="list8"
                        readonly
                        tabIndex="-1"
                        v-model="form.id_classe"
                        @change="validatedata('classe')"
                      >
                        <option v-for="classe in classes" :value="classe.id">
                          {{ classe.type_formation.intitule }}
                          {{ classe.nom_classe }} {{ classe.niveau }}
                          {{ classe.type_classe }}
                        </option>
                      </select>
                      <span class="erreur">{{ id_classe_erreur }}</span>
                    </div>
                  </div>

                  <div class="col-lg-12 p-t-20 text-center">
                    <button
                      type="submit"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                      @click.prevent="validerAvantAjout()"
                    >
                      Appliquer
                    </button>

                    <button
                      type="button"
                      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                      @click="resetForm"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content-wrapper" v-show="viewListe">
      <listeRecouvrement />
    </div>

    <appfooter />
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import listeRecouvrement from "./listeRecouvrementComponent.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    listeRecouvrement,
  },
  mixins: [permissionMixin],
  name: "createRecouvrementCompenent",

  data() {
    return {
      filieres: [],
      form: new Form({
        id_annee_academique: "",
        id_mois: "",
        id_departement: "",
        id_unite_de_formation: "",
        id_classe: "",
      }),
      annee_academiques: [],
      mois: [],
      departements: [],
      unite_de_formations: [],
      classes: [],

      id_annee_academique_erreur: "",
      id_mois_erreur: "",
      id_departement_erreur: "",
      id_unite_de_formation_erreur: "",
      id_classe_erreur: "",
      nom_classe_selected: "",
      annee_selected: "",
      etatForm: false,
      viewListe: false,
      i: 0,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_annee_academique();
    this.get_mois();
    this.get_departement();
    bus.on("ancienFiltre", (eventData) => {
      this.viewListe = eventData.viewListe;
    });
  },

 /*  mounted() {
    this.get_annee_academique();
    this.get_mois();
    this.get_departement();
    bus.on("ancienFiltre", (eventData) => {
      this.viewListe = eventData.viewListe;
    });
  }, */

  methods: {
    ...mapActions(["login", "logout"]),
    async soumettre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      const formdata = new FormData();
      formdata.append("id_annee_academique", this.form.id_annee_academique);
      formdata.append("id_mois", this.form.id_mois);
      formdata.append("id_departement", this.form.id_departement);
      formdata.append("id_unite_de_formation", this.form.id_unite_de_formation);
      formdata.append("id_classe", this.form.id_classe);

      this.get_classe_by_id(this.form.id_classe);
      this.get_annee_by_id(this.form.id_annee_academique);
      if(this.hasCreatePermission("Recouvrement Scolarité")){

      try {
        const response = await axios.post("/recouvrement/filtre", formdata, {
          headers,
        });

        // Traitez la réponse de l'API selon vos besoins
        if (response.data.statut === 200) {
          this.viewListe = true;
          const eventData = {
            eleve_non_payers: response.data.eleve_non_payer,
            nom_classe_selected: this.nom_classe_selected,
            annee_selected: this.annee_selected,
            viewListe: true,
          };

          bus.emit("nouveauFiltre", eventData);
        }
      } catch (error) {
        console.log(error);
        if (error.request.status === 500) {
          showDialog2();
          this.editModal = false;
        }
      }
    }
    else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_annee_academique() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

      if(this.hasReadPermission("Recouvrement Scolarité")){
      await axios
        .get("/annee_academique/index", { headers })
        .then((response) => {
          this.annee_academiques = response.data.annee_academique;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recuperation des année_academiques",
            "error"
          );
        });
      }
      else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },
    async get_mois() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasReadPermission("Recouvrement Scolarité")){
      await axios
        .get("/mois/index", { headers })
        .then((response) => {
          this.mois = response.data.mois;
        })
        .catch((error) => {
          //this.resetForm();
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recuperation du mois",
            "error"
          );
        });
      }
      else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },
    async get_classe(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasReadPermission("Recouvrement Scolarité")){
      await axios
        .get(`/find/classes/${id}`, { headers })
        .then((response) => {
          //this.classes = response.data.classe;
          this.validatedata("unite_de_formation");
          // Filtrer les classes par type d'intitulé (CS ou FPJ)
          this.classes = response.data.classe.filter((classe) => {
            return classe.type_classe === "CS" || classe.type_classe === "FPJ";
          });
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "une erreur est survenue lors de la recuperation des classes",
            "error"
          );
        });
      }
      else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },

    async get_departement() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasReadPermission("Recouvrement Scolarité")){
      await axios
        .get("/departement/all", { headers })
        .then((response) => {
          this.departements = response.data.departement;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "une erreur est survenue lors de la recuperation des Departements",
            "error"
          );
        });
      }
      else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },

    async get_filiere(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasReadPermission("Recouvrement Scolarité")){
      await axios
        .get(`/find/filieres/${id}`, { headers })
        .then((response) => {
          this.unite_de_formations = response.data.filiere;
          this.validatedata("departement");
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "une erreur est survenue lors de la recuperation des filières",
            "error"
          );
        });
      }
      else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },

    async get_classe_by_id(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasReadPermission("Recouvrement Scolarité")){

      if (this.form.id_classe) {
        await axios
          .get("/classe/show/" + id, { headers })
          .then((response) => {
            this.nom_classe_selected = response.data.classe;
          })
          .catch((error) => {
            //this.resetForm();
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation du nom de la classe choisi",
              "error"
            );
          });
      }
      }
      else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },
    async get_annee_by_id(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasReadPermission("Recouvrement Scolarité")){
        if (this.form.id_classe) {
          await axios
            .get("/annee_academique/show/" + id, { headers })
            .then((response) => {
              this.annee_selected = response.data.annee_academique;
            })
            .catch((error) => {
              //this.resetForm();
              Swal.fire(
                "Erreur!",
                "Une erreur est survenue lors de la recuperation de l'annee  choisi",
                "error"
              );
            });
        }
      }
      else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },


    validerAvantAjout() {
      const isIdChampValid = this.validatedataOld();
      if (isIdChampValid) {
        this.etatForm = false;
        return 0;
      } else {
        this.soumettre();
        this.etatForm = true;
      }
    },

    validatedata(champ) {
      var i = 0;

      switch (champ) {
        case "annee_academique":
          this.id_annee_academique_erreur = "";
          //pour user
          if (this.form.id_annee_academique === "") {
            this.id_annee_academique_erreur =
              "Vous avez oublié de sélectionner  l'année académique";
            i = 1;
            return true;
          }
          break;

        case "mois":
          this.id_mois_erreur = "";
          //pour user
          if (this.form.id_mois === "") {
            this.id_mois_erreur = "Vous avez oublié de sélectionner  le mois";
            i = 1;
            return true;
          }
          break;
        case "departement":
          this.id_departement_erreur = "";
          //pour user
          if (this.form.id_departement === "") {
            this.id_departement_erreur =
              "Vous avez oublié de sélectionner  le departement";
            i = 1;
            return true;
          }
          break;

        case "unite_de_formation":
          this.id_unite_de_formation_erreur = "";
          //pour user
          if (this.form.id_unite_de_formation === "") {
            this.id_unite_de_formation_erreur =
              "Vous avez oublié de sélectionner  la filiére";
            i = 1;
            return true;
          }
          break;

        case "classe":
          this.id_classe_erreur = "";
          //pour user
          if (this.form.id_classe === "") {
            this.id_classe_erreur =
              "Vous avez oublié de sélectionner  la classe";
            i = 1;
            return true;
          }
          break;

        default:
          break;
      }
      /*  return false; */
    },

    validatedataOld() {
      var i = 0;
      this.id_annee_academique_erreur = "";
      this.id_mois_erreur = "";
      this.id_departement_erreur = "";
      this.id_unite_de_formation_erreur = "";
      this.id_classe_erreur = "";

      if (this.form.id_annee_academique === "") {
        this.id_annee_academique_erreur =
          "Vous avez oublié de sélectionner l'année académique'";
        i = 1;
      }
      if (this.form.id_mois === "") {
        this.id_mois_erreur = "Vous avez oublié de sélectionner le mois";
        i = 1;
      }
      if (this.form.id_departement === "") {
        this.id_departement_erreur =
          "Vous avez oublié de sélectionner le departement''";
        i = 1;
      }
      if (this.form.id_unite_de_formation === "") {
        this.id_unite_de_formation_erreur =
          "Vous avez oublié de sélectionner la filiére";
        i = 1;
      }
      if (this.form.id_classe === "") {
        this.id_classe_erreur = "Vous avez oublié de sélectionner la classe";
        i = 1;
      }

      if (i == 1) return true;

      return false;
    },

    closeModal() {
      this.resetForm();
      var ajout = document.querySelector("[data-modal-filtre]");
      //var confirmation = document.querySelector(selector);

      // if (this.etatForm == true) {
      var actif = document.querySelectorAll(".actif");
      actif.forEach((item) => {
        item.classList.remove("actif");
      });
      ajout.close();
      //}

      ajout.classList.remove("actif");

      this.editModal === false;
    },

    resetForm() {
      this.form.id_annee_academique = "";
      this.form.id_mois = "";
      this.form.id_departement = "";
      this.form.id_unite_de_formation = "";
      this.form.id_classe = "";
      this.id_annee_academique_erreur = "";
      this.id_mois_erreur = "";
      this.id_departement_erreur = "";
      this.id_unite_de_formation_erreur = "";
      this.id_classe_erreur = "";
      this.etatForm = false;
      this.id_annee_academique_erreur = "";
      this.id_mois_erreur = "";
      this.id_departement_erreur = "";
      this.id_unite_de_formation_erreur = "";
      this.id_classe_erreur = "";

    },
  },
};
</script>
