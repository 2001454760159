<template>
    <div class="page-wrapper">
        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper">
                <div class="page-content">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">
                            <div class=" pull-left">
                                <div class="page-title">Nouvelle planification</div>
                            </div>
                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Tableau
                                        de
                                        Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li><a class="parent-item" :href="'/liste-planification'">Liste
                                        Planification</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">Nouvelle Planification &nbsp;<a class="parent-item"></a>&nbsp;<i
                                        class="fa fa-angle-right"></i> </li>

                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card-box">
                                <div class="card-head">
                                    <header>Information</header>
                                    <button id="panel-button"
                                        class="mdl-button mdl-js-button mdl-button--icon pull-right"
                                        data-upgraded=",MaterialButton">
                                        <i class="material-icons">more_vert</i>
                                    </button>
                                    <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                                        data-mdl-for="panel-button">
                                        <li class="mdl-menu__item"><i class="material-icons">assistant_photo</i>Action
                                        </li>
                                        <li class="mdl-menu__item"><i class="material-icons">print</i>Another action
                                        </li>
                                        <li class="mdl-menu__item"><i class="material-icons">favorite</i>Something else
                                            here</li>
                                    </ul>
                                </div>
                                <div class="card-body row">
                                    <!-- start page content -->

                                    <div class="card-body row">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-6" v-show="!scheduleGenerated">
                                                <div class="btn-group">

                                                    <a id="addRow" class="btn btn-success" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal23" type="button">
                                                        Generer via Système
                                                        <i class="fa fa-book text-white"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-6" v-show="scheduleGenerated">
                                                <div class="btn-group">

                                                    <button id="addRow" class="btn btn-danger" @click="retourChange()">
                                                        Retour
                                                        <i class="fa fa-book text-white"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body row"
                                            v-for="(planification, index) in form_planification.planifications"
                                            :key="index" v-show="!scheduleGenerated">

                                            <div class="header d-flex justify-content-end">
                                                <svg class="cursor-pointer"
                                                    @click.prevent="duplicatePlanification(index)"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                    height="24">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="green"
                                                        d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                </svg>
                                                <svg v-show="form_planification.planifications.length > 1"
                                                    class="cursor-pointer ml-2"
                                                    @click.prevent="removePlanification(index)"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                    height="24">
                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                    <path fill="#EC4899"
                                                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                </svg>
                                            </div>

                                            <div class="col-lg-12 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label for="categorySelect-{{ index }}" class="mdl-textfield__label"
                                                        v-show="!planification.id_cour">Choisissez
                                                        le cours</label>
                                                    <select class="mdl-textfield__input" :id="'categorySelect-' + index"
                                                        readonly tabIndex="-1" v-model="planification.id_cour"
                                                        @change="validatedata('id_cours')">
                                                        <option v-for="(cour, index) in cours" :value="cour.id"
                                                            :key="index">
                                                            {{ cour.matiere.intitule }}
                                                            {{ cour.formateur.user.nom }}
                                                            {{ cour.classe.nom_classe }}
                                                            {{ cour.semestre.intitule }}
                                                        </option>
                                                    </select>

                                                    <span class="erreur" v-if="planification.id_cours_erreur">{{planification.id_cours_erreur }}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label for="list7-{{ index }}" class="mdl-textfield__label"
                                                        v-show="!planification.id_salle">
                                                        Choisissez la salle</label>
                                                    <select class="mdl-textfield__input" :id="'list7-' + index" readonly
                                                        tabIndex="-1" v-model="planification.id_salle"
                                                        @change="validatedata('id_salle')">
                                                        <option v-for="salle in salles" :value="salle.id"
                                                            :key="salle.id">
                                                            {{ salle.intitule }}
                                                        </option>
                                                    </select>
                                                    <span class="erreur"
                                                        v-if="planification.id_salle_erreur">{{ planification.id_annee_academique_erreur
                                                        }}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label for="list6-{{ index }}" class="mdl-textfield__label"
                                                        v-show="!planification.id_annee_academique">
                                                        Choisissez l'année</label>
                                                    <select class="mdl-textfield__input" :id="'list6-' + index" readonly
                                                        tabIndex="-1" v-model="planification.id_annee_academique"
                                                        @change="validatedata('id_annee_academique')">
                                                        <option v-for="annee_academique in annee_academiques"
                                                            :value="annee_academique.id" :key="annee_academique.id">
                                                            {{ annee_academique.intitule }}
                                                        </option>
                                                    </select>
                                                    <span class="erreur"
                                                        v-if="planification.id_annee_academique_erreur">{{planification.id_annee_academique_erreur}}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-4 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                    <label for="list8-{{ index }}" class="mdl-textfield__label"
                                                        v-show="!planification.repetition">Repeter pour</label>
                                                    <select class="mdl-textfield__input" :id="'list8-' + index" readonly
                                                        tabIndex="-1" v-model="planification.repetition">
                                                        <option value="aucune">Aucune</option>
                                                        <option value="semaine">Une semaine</option>
                                                        <option value="mois">Un mois</option>
                                                    </select>
                                                </div>
                                            </div>


                                            <div class="col-lg-3 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                    <label class="mdl-textfield__label" for="starts-at-{{ index }}"
                                                        v-show="!planification.date_debut">Date Debut</label>
                                                    <input type="date" class="mdl-textfield__input"
                                                        placeholder="Start Date" name="starts_at"
                                                        :id="'starts-at-' + index" v-model="planification.date_debut"
                                                        @input="validatedata('date_debut')">
                                                    <span class="erreur" v-if="planification.date_debut_erreur">{{planification.date_debut_erreur }}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-3 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                    <label class="mdl-textfield__label" for="starts-at2"
                                                        v-show="!planification.date_fin">Date
                                                        Fin</label>
                                                    <input type="date" class="mdl-textfield__input"
                                                        placeholder="End Date" name="starts_at2" id="starts-at2"
                                                        v-model="planification.date_fin"
                                                        @input="validatedata('date_fin')">
                                                    <span class="erreur" v-if="planification.date_fin_erreur">{{planification.date_fin_erreur}}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-3 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                    <label class="mdl-textfield__label" for="starts-at-{{ index }}"
                                                        v-show="!planification.heure_debut">Heure
                                                        Debut</label>
                                                    <input class="mdl-textfield__input" placeholder="Heure debut"
                                                        type="time" :id="'starts-at-' + index"
                                                        v-model="planification.heure_debut"
                                                        @input="validatedata('heure_debut')" />
                                                    <span class="erreur" v-if="planification.heure_debut_erreur">{{
                    planification.heure_debut_erreur
                }}</span>
                                                </div>
                                            </div>

                                            <div class="col-lg-3 p-t-20">
                                                <div
                                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                    <label class="mdl-textfield__label" for="starts-at-{{ index }}"
                                                        v-show="!planification.heure_fin">Heure
                                                        Fin</label>
                                                    <input class="mdl-textfield__input" placeholder="Heure Fin"
                                                        type="time" :id="'starts-at-' + index"
                                                        v-model="planification.heure_fin"
                                                        @input="validatedata('heure_fin')" />
                                                    <span class="erreur" v-if="planification.heure_fin_erreur">{{ planification.heure_fin_erreur }}</span>
                                                </div>
                                            </div>



                                        </div>


                                        <!-- fenetre modal pour generer un emploi du temps -->
                                        <div class="modal fade" id="exampleModal23" tabindex="-1" role="dialog"
                                            aria-hidden="true" v-show="!scheduleGenerated">
                                            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h3 class="modal-title" id="addEventTitle">Me proposer un emploi
                                                            du temps</h3>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">

                                                        <div class="card-body " id="bar-parent6">
                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Heure
                                                                    Total:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input
                                                                        placeholder="Quel est le nombre total d'heure de la planification ?"
                                                                        type="number" v-model="form.totalHours"
                                                                        class="form-control">
                                                                    <span class="help-block"></span>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Durée des
                                                                    Seances:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input type="number"
                                                                        v-model="form.durationPerSession"
                                                                        placeholder="Combien de temps vouliez vous que vos séances dure ?"
                                                                        class="form-control">
                                                                    <span class="help-block"></span>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Décalage des
                                                                    seances:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input type="number" v-model="form.offset"
                                                                        placeholder="Vos séances doivent se séparer de combien de jour ?"
                                                                        class="form-control">
                                                                    <span class="help-block"></span>
                                                                </div>
                                                            </div>

                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Nombre de
                                                                    semaine:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <input type="number" v-model="form.numberOfWeeks"
                                                                        placeholder="Sur combien de semaine vouliez vous que l'emploi du temps dure ?"
                                                                        class="form-control">
                                                                    <span class="help-block"></span>
                                                                </div>
                                                            </div>

                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Periode:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <select class="form-select select2"
                                                                        v-model="form.courseTimes"
                                                                        placeholder="Preferiez vous faire des cours en journée ou soirée ?">
                                                                        <!-- <option value="">Preferiez vous faire des cours en journée ou soirée ?</option> -->
                                                                        <optgroup label="Moment">
                                                                            <option value="morning">Matiné</option>
                                                                            <option value="afternoon">Soiré</option>
                                                                        </optgroup>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Classe:</label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <select id="classe" class="form-select select2"
                                                                        v-model="form.classe" ref="select">
                                                                        <option value="">Selectionner la classe</option>
                                                                        <optgroup label="Classe">
                                                                            <option v-for="classe in classes"
                                                                                :key="classe.id" :value="classe.id">
                                                                                {{ classe.type_formation.intitule }}
                                                                                {{ classe.nom_classe }}
                                                                                {{ classe.niveau }}
                                                                                {{ classe.type_classe }}
                                                                            </option>
                                                                        </optgroup>
                                                                    </select>

                                                                </div>
                                                            </div>



                                                            <div class="form-group row">
                                                                <label class="col-sm-2 control-label">Cours:
                                                                </label>
                                                                <div class="col-lg-9 col-md-8">
                                                                    <select id="multiple"
                                                                        class="form-control select2-multiple"
                                                                        v-model="form.selectedCourses" multiple>
                                                                        <option value="">Selectionner le cours</option>
                                                                        <optgroup label="Cours">
                                                                            <option v-for="cour in cours" :key="cour.id"
                                                                                :value="cour.id">
                                                                                {{ cour.matiere.intitule }}
                                                                                {{ cour.formateur.user.nom }}
                                                                                {{ cour.classe.nom_classe }}
                                                                                {{ cour.semestre.intitule }}
                                                                            </option>
                                                                        </optgroup>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 p-t-20 text-center">
                                                                <button type="submit" v-if="!this.editModal"
                                                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                                                    @click="generateSchedule">
                                                                    Enregistrer
                                                                </button>

                                                                <button type="button" id="close"
                                                                    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                                                                    @click="resetForm" data-bs-dismiss="modal">
                                                                    Annuler
                                                                </button>
                                                            </div>

                                                            <!-- </form> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End fenetre modal pour generer un emploi du temps -->

                                    </div>

                                    <div class="col-lg-12 p-t-20 text-center" v-show="!scheduleGenerated">
                                        <button type="submit" v-if="!this.editModal"
                                            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                            @click.prevent="validerAvantAjout()">
                                            Enregistrer
                                        </button>
                                        <button type="submit" v-if="this.editModal"
                                            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
                                            @click.prevent="validerAvantAjout()">
                                            Modifier
                                        </button>
                                        <button type="button"
                                            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
                                            @click="resetForm">
                                            Annuler
                                        </button>
                                    </div>

                                    <!-- <full-calendar :events="events"></full-calendar> -->
                                    <!-- Main calendar display with hours column -->
                                    <div class="card-body" v-if="scheduleGenerated">
                                        <div class="panel-body">
                                            <div class="has-toolbar">
                                                <FullCalendar ref="fullCalendar" :options="calendarOptions" />
                                                <!--  ref="fullCalendar" :options="calendarOptions"    -->
                                            </div>
                                        </div>
                                    </div>

                                    <button v-show="scheduleGenerated" @click="saveSchedule">Savegarder</button>

                                    <appafterContent />
                                </div>
                            </div>
                        </div>





                    </div>

                </div>
            </div>

            <appfooter />
        </div>
    </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
/* import he from 'public/assets/plugins/fullcalendar/packages/core/locales/he'; */
import Form from "vform";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

import FullCalendar from '@fullcalendar/vue3';
import interactionPlugin from '@fullcalendar/interaction';
import dayGrid from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';

export default {
    components: {
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        flatPickr,
        FullCalendar
    },
    mixins: [permissionMixin],
    name: "planifierEmploiDuTempsCompenent",
    data() {
        return {
            calendarOptions: {
                plugins: [dayGrid, interactionPlugin, timeGrid],
                initialView: 'timeGridWeek',
                locale: 'fr',
                headerToolbar: {
                    left: 'prev,next,today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                },
                height: "auto",
                selectable: true,
                allDaySlot: false,
                slotMinTime: '08:00:00',
                slotMaxTime: '22:00:00',
                slotLabelInterval: '01:00',
                events: [],
                /* eventsSet: this.handleEventsSet, */
                eventContent: function (info) {
                    let professeur = info.event.extendedProps.professeur || 'Pas de professeur';
                    let salle = info.event.extendedProps.salle || 'Pas de salle';
                    let classe = info.event.extendedProps.classe || 'Pas de classe';
                    return {
                        html: `<div>
                     <strong>${info.event.title} ${classe}</strong><br>
                     <span>${professeur}</span><br>
                     <span>${salle}</span>
                   </div>`
                    };
                },

            },

            salles: [],
            form: {
                totalHours: '',
                classe: '',
                durationPerSession: '',
                offset: '',
                numberOfWeeks: '',
                courseTimes: '',
                selectedCourses: []
            },
            courses: [], // Load this with your courses data
            events: [],
            scheduleGenerated: false,



            form_planification: new Form({
                planifications: [
                    {

                        id_cour: "",
                        repetition: "",
                        id_salle: "",
                        id_annee_academique: "",
                        date_debut: "",
                        date_fin: "",
                        heure_debut: "",
                        heure_fin: "",
                        uniqueKey: Date.now() + Math.random(),
                        id_annee_academique_erreur: "",

                        id_salle_erreur: "",
                        id_cours_erreur: "",
                        heure_debut_erreur: "",
                        heure_fin_erreur: "",
                        date_debut_erreur: "",
                        date_fin_erreur: "",
                    },
                ],
            }),
            cours: [],
            classes: [],
            annee_academiques: [],



            etatForm: false,
            editModal: false,
        };
    },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },
    beforeMount() {

        this.$data.calendarOptions.events = this.events;
    },

    async created() {
       
        await this.get_permission();
       // console.log(this.form_planification);

        this.get_cours();
        this.get_classe();
        this.get_salle();
        this.get_annee_academique();
        this.initializeSelect2();

        bus.on("paiementModifier", (eventData) => {
            this.editModal = eventData.editModal;
            // this.monterToupdate(eventData.paiement);
        });

        $(this.$refs.select).select2().on('change', (event) => {
            this.get_cours_by_id_classe(event.target.value);
        });
    },

    watch: {
      /*   events(newEvents) {
            this.calendarOptions.events = newEvents;
            this.$nextTick(() => {
                this.focusOnFirstEvent();
            });
        }, */
        'form.selectedCourses'(newVal) {
            $('#multiple').val(newVal).trigger('change');
        },

        events(newEvents) {
            this.calendarOptions.events = newEvents; // update calendar options when events change
        },
        'form.classe'(newVal) {
            if (newVal) {
                this.get_cours_by_id_classe(newVal);
            }
        },
    },

    methods: {
        ...mapActions(["login", "logout"]),
        async soumettre() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
            formdata.append("emploiplanifiers", JSON.stringify(this.form_planification.planifications));

            if (this.hasCreatePermission("Planifier emploi du temps")) {
                try {
                    const create_store = await axios.post("/planification/emploidutemps/store", formdata, {
                        headers,
                    });
                    showDialog6("Emploi du Temps planifié avec succès");
                    bus.emit("planificationAjoutee");
                    this.resetForm();
                    setTimeout(() => {
                        window.location.href = "/emploi-du-temps";
                    }, 1500);

                    bus.emit("planificationAjoutee");
                } catch (e) {
                    if (409 === e.response.data.statut) {
                        showDialog3(e.response.data.message);
                    } else {
                        showDialog3("Une erreur est survenue lors de l'enregistrement");
                    }
                    console.log(e);

                }
            }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },


        async get_cours_by_id_classe(id) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasReadPermission("Planifier emploi du temps")) {
                await axios
                    .get(`/find/cours/by/classe/${id}`, { headers })
                    .then((response) => {
                        this.cours = response.data.cour;
                        //console.log('lancer avec succes')
                        //  this.validatedata("formateur");
                        this.$nextTick(() => {
                $('#multiple').select2().trigger('change');
               // console.log(response.data.cour);

            });
                    })
                    .catch((error) => {
                        Swal.fire(
                            "Erreur!",
                            "une erreur est survenue lors de la recuperation des filières",
                            "error"
                        );
                    });
            }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        focusOnFirstEvent() {
            if (this.events.length > 0) {
                const firstEventDate = this.events[0].start;
                const calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.gotoDate(firstEventDate);
            }
        },
        disableCalendar() {
            const calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.destroy(); // Détruit l'instance de FullCalendar
        },
        retourChange() {
            $('#exampleModal23').modal('show');
      /*this.get_classe();
        this.get_salle();
        this.get_annee_academique();
        this.initializeSelect2(); */
       this.scheduleGenerated = false;
    },

        initializeSelect2() {
            const vm = this;
            $('#multiple').select2();
            $('#multiple').on('change', function () {
                const selectedValues = $(this).val();
                // Avoid unnecessary updates to selectedCourses
                if (JSON.stringify(vm.form.selectedCourses) !== JSON.stringify(selectedValues)) {
                    vm.form.selectedCourses = selectedValues;
                }
            });
            
        },

        async get_cours() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            await axios.get('/cour/all', { headers })
                .then(response => {
                    this.cours = response.data.cour
                }).catch(error => {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des derniers cours', 'error')
                });
        },

        async generateSchedule() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            $('#exampleModal23').modal('hide');

            try {
                const response = await axios.post('/generate-schedule', this.form, { headers });
                this.events = response.data.events;
                this.scheduleGenerated = true;
            } catch (error) {
                console.error('Error generating schedule:', error);
            }
        },

        async saveSchedule() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };

            try {
                const response = await axios.post('/save-schedule', { events: this.events }, { headers });
                if (response.data.success) {
                    showDialog6('Schedule saved successfully');
                } else {
                    showDialog3('Error saving schedule');
                }
            } catch (error) {
                console.error('Error saving schedule:', error);
            }
        },

        async get_classe() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasReadPermission("Planifier emploi du temps")) {
                await axios
                    .get("/classe/all", { headers })
                    .then((response) => {
                        this.classes = response.data.classe;
                    })
                    .catch((error) => {
                        Swal.fire(
                            "Erreur!",
                            "Une erreur est survenue lors de la recuperation des classes",
                            "error"
                        );
                    });
            } else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        validerAvantAjout() {
            const isVerifIdValid = this.validatedataOld();

            if (isVerifIdValid === true) {
                this.etatForm = false;
                this.editModal = false;

                return 0;
            } else {
                if (this.editModal === true) {
                    this.etatForm = true;
                    this.update_paiement(this.idPaiement);
                    this.editModal = false;
                } else {

                    this.soumettre();
                    this.etatForm = true;
                    this.editModal = false;
                }
            }
        },
        validatedata(champ) {

            // Variable pour vérifier la présence d'erreurs
            let hasError = false;

            // Validation spécifique en fonction du champ
            switch (champ) {
                case "id_cours":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.id_cours_erreur = "";
                        if (planification.id_cour === "") {
                            planification.id_cours_erreur = "Vous avez oublié de sélectionner un cours " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;
                case "id_salle":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.id_salle_erreur = "";
                        if (planification.id_cour === "") {
                            planification.id_salle_erreur = "Vous avez oublié de sélectionner un salle " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "id_annee_academique":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.id_annee_academique_erreur = "";
                        if (planification.id_annee_academique === "") {
                            planification.id_annee_academique_erreur = "Vous avez oublié de sélectionner une Annee Academique " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "date_debut":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.date_debut_erreur = "";
                        if (planification.date_debut === "") {
                            planification.date_debut_erreur = "Vous avez oublié de sélectionner une date pour le début " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "date_fin":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.date_fin_erreur = "";
                        if (planification.date_fin === "") {
                            planification.date_fin_erreur = "Vous avez oublié de sélectionner une date de fin " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "heure_fin":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.heure_fin_erreur = "";
                        if (planification.heure_fin === "") {
                            planification.heure_fin_erreur = "Vous avez oublié de sélectionner une heure de fin " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                case "heure_debut":
                    this.form_planification.planifications.forEach((planification, index) => {
                        planification.heure_debut_erreur = "";
                        if (planification.heure_debut === "") {
                            planification.heure_debut_erreur = "Vous avez oublié de sélectionner une heure de début " + (index + 1);
                            hasError = true;
                            return hasError;
                        }
                    });
                    break;

                default:
                    break;
            }
        },

        validatedataOld() {
            // Réinitialiser toutes les erreurs
            this.form_planification.planifications.forEach(planification => {
                planification.id_cours_erreur = "";
                planification.id_salle_erreur = "";
                planification.id_annee_academique_erreur = "";
                planification.date_debut_erreur = "";
                planification.date_fin_erreur = "";
                planification.heure_debut_erreur = "";
                planification.heure_fin_erreur = "";
            });

            // Variable pour vérifier la présence d'erreurs
            let hasError = false;

            // Validation des champs
            this.form_planification.planifications.forEach((planification, index) => {
                if (planification.id_cour === "") {
                    planification.id_cours_erreur = "Vous avez oublié de sélectionner un cours " + (index + 1) + ".";
                    hasError = true;
                }
                if (planification.id_salle === "") {
                    planification.id_salle_erreur = "Vous avez oublié de sélectionner une salle " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.id_annee_academique === "") {
                    planification.id_annee_academique_erreur = "Vous avez oublié de sélectionner l'Annee Academique pour la planification " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.date_debut === "") {
                    planification.date_debut_erreur = "Vous avez oublié de sélectionner une date pour le débutfff " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.date_fin === "") {
                    planification.date_fin_erreur = "Vous avez oublié de sélectionner une date de fin " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.heure_debut === "") {
                    planification.heure_debut_erreur = "Vous avez oublié de sélectionner l'heure de début " + (index + 1) + ".";
                    hasError = true;
                }

                if (planification.heure_fin === "") {
                    planification.heure_fin_erreur = "Vous avez oublié de sélectionner l'heure de fin " + (index + 1) + ".";
                    hasError = true;
                }
            });

            return hasError;
        },


        getImageUrl(url) {
            //return url ? `${window.location.origin}/storage/${url}` : '';
            //return url ? `http://127.0.0.1:8000/storage/${url}` : "";
            return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
        },

        async get_annee_academique() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasReadPermission("Paiement Scolarité")) {
                await axios
                    .get("/annee_academique/index", { headers })
                    .then((response) => {
                        this.annee_academiques = response.data.annee_academique;
                    })
                    .catch((error) => {
                        Swal.fire(
                            "Erreur!",
                            "Une erreur est survenue lors de la recuperation des année_academiques",
                            "error"
                        );
                    });
            }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },


        resetForm() {

            this.form_planification.planifications.forEach(planification => {
                planification.id_cour = "";
                planification.id_salle = "";
                planification.id_annee_academique = "";
                planification.date_debut = "";
                planification.date_fin = "";
                planification.heure_debut = "";
                planification.heure_fin = "";

                planification.id_cours_erreur = "";
                planification.id_salle_erreur = "";
                planification.id_annee_academique_erreur = "";
                planification.date_debut_erreur = "";
                planification.date_fin_erreur = "";
                planification.heure_debut_erreur = "";
                planification.heure_fin_erreur = "";
            });

        },
        async get_salle() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasReadPermission("Cours")) {
                await axios
                    .get("/salle/index", { headers })
                    .then((response) => {
                        this.salles = response.data.salle;
                    })
                    .catch((error) => {
                        Swal.fire(
                            "Erreur!",
                            "Une erreur est survenue lors de la recuperation des dernières salles",
                            "error"
                        );
                    });
            } else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        rafraichissementAutomatique() {
            document.addEventListener("DOMContentLoaded", this.resetForm());
        },
    

        duplicatePlanification(index) {
            const planification = this.form_planification.planifications[index];
            const newPlanification = {
                ...planification,
                uniqueKey: Date.now() + Math.random(), // Assurez-vous d'une clé unique
                id_annee_academique: "",
                date_debut: "",
                id_cour: "",
                id_salle: "",
                date_fin: "",
                heure_debut: "",
                heure_fin: "",
                repetition: planification.repetition, // Copiez également la valeur de répétition
                id_annee_academique_erreur: "",
                id_cours_erreur: "",
                id_salle_erreur: "",
                heure_debut_erreur: "",
                heure_fin_erreur: "",
                date_debut_erreur: "",
                date_fin_erreur: "",
            };

            this.form_planification.planifications.splice(index + 1, 0, newPlanification);

            this.$nextTick(() => {
                this.reinitializeMDL();
            });
        },


        reinitializeMDL() {
            window.componentHandler.upgradeDom();
        },

        removePlanification(index) {
            if (this.form_planification.planifications.length > 1) {
                this.form_planification.planifications.splice(index, 1);
            }
        },

    },
};
</script>

<style>
:root {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2C3E50;
  --fc-button-border-color: #2C3E50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: red;
}

</style>
