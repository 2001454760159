<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Accueil</a>&nbsp;<i
                    class="fa fa-angle-right"></i>
                </li>
                <li class="active">
                  Paramétres &nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/listematiere'"> Matiére</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab">Matiére</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Toutes les matiéres</header>
                            <div class="tools">
                              <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
                              <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
                              <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a :href="'/creatematiere'" id="addRow" class="btn btn-primary">
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>

                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <button :href="'/creatematiere'" id="addRow" class="btn btn-success"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal2" type="button">
                                    Assigner matiere
                                    <i class="fa fa-book"></i>
                                  </button>
                                </div>
                              </div>


                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length">
                                  <label class="me-2">Afficher</label>
                                  <select name="example47_length" aria-controls="example47" class="form-select-sm"
                                    @change="updatePerPage" v-model="limitQuery">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2">Recherche :</label>
                                  <input type="text" id="searchInput" placeholder="" aria-controls="example47"
                                    v-model="searchQuery" @input="get_matiere(currentPage)" />
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Matiére</th>
                                  <th>Durée</th>

                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="odd gradeX" v-for="(matiere, index) in matieres" :key="index">
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ matiere.intitule }}</td>
                                  <td>{{ matiere.duree }} heures</td>

                                  <td>
                                    <a class="tblEditBtn" @click="openModal(matiere)">
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a class="tblDelBtn" @click="deleteMatiere(matiere)">
                                      <i class="fa fa-trash-o"></i>
                                    </a>

                                    <button @click="monterDetail(matiere)" type="button" class="tblDetailBtn border-0 "
                                      data-bs-toggle="modal" data-bs-target="#exampleModal1">
                                      <i class="fa fa-ellipsis-h"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="row" v-if="this.matieres.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info" id="datable_1_info" role="status" aria-live="polite">
                                  Affichage de {{ from_begin }} à
                                  {{ to_begin }} sur {{ total }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div class="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                  <paginate-component :total-pages="total_page" :total="total" :per-page="per_page"
                                    :current-page="current_page" @pagechanged="pagPage"></paginate-component>
                                </div>
                              </div>
                            </div>

                            <!-- fenetre modal pour assigner une matiere a un formateur-->
<div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="addEventTitle">Assigner Formateur</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetForm"></button>
      </div>
      <div class="modal-body">
        <div class="header d-flex justify-content-end">
          <svg class="cursor-pointer" @click.prevent="duplicateMatiere(index)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
          </svg>
          <svg v-show="form_matiere.matieres.length > 1" class="cursor-pointer ml-2" @click.prevent="removeMatiere(index)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path fill="#EC4899" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
          </svg>
        </div>

        <div class="card-body" v-for="(matiereProf, index) in form_matiere.matieres" :key="index">
          <div class="row">
            <div class="col-md-4 mb-4">
              <label :for="'departementSelect' + index">Departement</label>
              <select class="form-select" :id="'departementSelect' + index" v-model="matiereProf.id_departement" @change="get_formateur_by_id_departement(index, matiereProf.id_departement)">
                <option v-for="(departement, index) in departements" :value="departement.id" :key="index">
                  {{ departement.nom_departement }}
                </option>
              </select>
              <span class="erreur" v-if="matiereProf.id_departement_erreur">{{ matiereProf.id_departement_erreur }}</span>
            </div>

            <div class="col-md-4 mb-4">
              <label :for="'formateurSelect' + index">Formateur</label>
              <select class="form-select" :id="'formateurSelect' + index" v-model="matiereProf.id_formateur" @change="validatedata('id_formateur')">
                <option v-if="matiereProf.formateurs.length === 0" disabled selected>Aucun formateur n'à été trouvé dans ce departement</option>
                <option v-for="(formateur, index) in matiereProf.formateurs" :value="formateur.id" :key="index">
                  {{ formateur.user.nom }} {{ formateur.user.prenom }}
                </option>
              </select>
              <span class="erreur" v-if="matiereProf.id_formateur_erreur">{{matiereProf.id_formateur_erreur }}</span>
            </div>

            <div class="col-md-4 mb-4">
              <label :for="'matiereSelect' + index">Matiere</label>
              <select class="form-select" :id="'matiereSelect' + index" v-model="matiereProf.id_matiere" @change="validatedata('id_matiere')">
                <option v-for="(matiere, index) in matieres" :value="matiere.id" :key="index">
                  {{ matiere.intitule }}
                </option>
              </select>
              <span class="erreur" v-if="matiereProf.id_matiere_erreur">{{ matiereProf.id_matiere_erreur }}</span>
            </div>
          </div>
        </div>

        <div class="modal-footer bg-whitesmoke pr-0">
          <button type="submit" v-if="!this.editModal" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary" @click.prevent="validerAvantAjout()">
            Enregistrer
          </button>
          <button type="button" id="close" @click="resetForm()" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger" data-bs-dismiss="modal">Fermer</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End fenetre modal pour assigner une matiere a un formateur -->


                            <!-- fenetre modal pour afficher la liste des formateurs assignées a une matiere-->
                            <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h3 class="modal-title" id="addEventTitle">Liste des formateurs assignées</h3>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                      aria-label="Close"></button>
                                  </div>
                                  <div class="modal-body">
                                    <form class="">

                                      <table class="table table-striped custom-table table-hover">
                                        <thead>
                                          <tr>
                                            <th>Nom</th>
                                            <th>Prenom</th>
                                            <th>Specialite</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(professeur, index) in ProfesseurFinds">
                                            <td>{{ professeur.formateur.user.nom }}</td>
                                            <td>{{ professeur.formateur.user.prenom }}</td>
                                            <td>
                                              {{professeur.formateur.specialite.intitule}}
                                            </td>
                                            <td>
                                              <a href="javascript:void(0)" class="tblDelBtn"
                                                @click="deleteMatiereAssign(professeur.id)">
                                                <i class="fa fa-trash-o"></i>
                                              </a>
                                            </td>
                                          </tr>

                                        </tbody>
                                      </table>

                                      <div class="modal-footer bg-whitesmoke pr-0">
                                        <button type="button" id="close" class="btn btn-danger" @click="resetForm"
                                          data-bs-dismiss="modal">Fermer</button>
                                      </div>


                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- End fenetre modal pour afficher la liste des formateurs assignées a une matiere -->



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content-wrapper" v-show="editModal">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Nouvelle Matiere</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard'">Tableau de
                    Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Paramétres</li>
                <li>
                  <a class="parent-item" :href="'/listematiere'">Matiére</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li class="active">Modifier Matière</li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card-box">
                <div class="card-head">
                  <header>Information</header>
                  <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                    data-upgraded=",MaterialButton">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                    data-mdl-for="panel-button">
                    <li class="mdl-menu__item">
                      <i class="material-icons">assistant_photo</i>Action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">print</i>Another action
                    </li>
                    <li class="mdl-menu__item">
                      <i class="material-icons">favorite</i>Something else here
                    </li>
                  </ul>
                </div>
                <div class="card-body row">
                  <FormulaireModification />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import datatable from "datatables.net-bs5";
import FormulaireModification from "./editMatiereComponent.vue";
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  name: "listeMatiereComponent",
  components: {
    FormulaireModification,
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    PaginateComponent,
  },
  mixins: [permissionMixin],
  data() {
    return {
      form: new Form({
        intitule: "",
        duree: "",
      }),

      form_matiere: new Form({
        matieres: [
          {

            id_matiere: "",
            id_departement: "",
            id_formateur: "",

            id_formateur_erreur: "",
            id_matiere_erreur: "",
            id_departement_erreur: "",

            formateurs: [],

          },
        ],
      }),

      intitule_erreur: "",
      formateurId: "",
      duree_erreur: "",
      id_classe_erreur: "",
      etatForm: false,
      editModal: false,
      matieres: [],
     //formateurs: [],
      departements: [],
      ProfesseurFinds: [],
      classes: [],
      idMatiere: "",
      limitQuery: 5,
      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,
      searchQuery: "",
      currentPage: 1,
      lastPage: 1,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  async created() {
    await this.get_permission();
    this.get_matiere();
    this.get_unite_de_formation();
    this.get_all_matiere();

    bus.on("matiereAjoutee", () => {
      this.get_matiere();
    });
    bus.on("matiereDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_matiere();
    });
  },
  /*     mounted() {
        this.get_matiere();
        
        bus.on('matiereAjoutee', () => { // Écouter l'événement de nouvelle utilisateur ajoutée
            this.get_matiere(); // Mettre à jour la liste des utilisateurs
        });
        bus.on('matiereDejaModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.get_matiere();
        });
    },
 */
  methods: {
    ...mapActions(["login", "logout"]),

    pagPage(pagination) {
      this.current_page = pagination;
      this.get_matiere(this.currentPage);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },
    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQuery !== 5) {
        this.limitQuery = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_matiere(this.currentPage);
      }
    },

   /*  async get_formateur_by_id_departement(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Matière")) {
        await axios
          .get(`/formateur/find/by/unite/formation/${id}`, { headers })
          .then((response) => {
            this.formateurs = response.data.formateur;
            this.validatedata("id_departement");
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "une erreur est survenue lors de la recuperation des formateurs",
              "error"
            );
          });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    }, */

    async get_formateur_by_id_departement(index, id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Matière")) {
        await axios
          .get(`/formateur/find/by/unite/formation/${id}`, { headers })
          .then((response) => {
           // this.$set(this.form_matiere.matieres[index], 'formateurs', response.data.data);
           this.form_matiere.matieres[index].formateurs = response.data.formateur;
            this.validatedata("id_departement");
          });
      }
    },

    async find_professeur_assign_to_matiere(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Utilisateur")) {
        await axios
          .get("/matiere/find/formateur/assign/" + id, { headers })
          .then((response) => {
            this.ProfesseurFinds = response.data.formateurmatiere;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "une erreur est survenue lors de la recuperation des matières du formateur",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async monterDetail(professeur) {

      await this.find_professeur_assign_to_matiere(professeur.id)
      //console.log(this.ProfesseurFinds)
      this.formateurId = professeur.id;
    },

    get_all_matiere() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Matière")) {
        axios
          .get("/matiere/index", { headers })
          .then((response) => {
            this.matieres = response.data.matiere;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernieres matieres",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    get_unite_de_formation() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Matière")) {
        axios
          .get("/departement/all", { headers })
          .then((response) => {
            this.departements = response.data.departement;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des unite de formation",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_matiere() {
      const token = localStorage.getItem("token");
      const filterCondition = (matiere) => true;
      const headers = { Authorization: `Bearer ${token}` };
      const params = {
        per_page: this.limitQuery,
        page: this.current_page,
        // Ajoutez d'autres paramètres si nécessaire
      };
      if (this.hasReadPermission("Matière")) {
        try {
          const response = await axios.get("/matiere/all/paginate", {
            headers: headers,
            params: params, // Ajouter les paramètres ici
          });
          const allmatiere = response.data.matiere.data;

          const filteredMatieres = allmatiere.filter((matiere) => {
            return (
              filterCondition(matiere) &&
              Object.keys(matiere)
                .reduce((acc, key) => {
                  const value = matiere[key];
                  if (typeof value === "string") {
                    acc.push(value.toLowerCase());
                  } else if (value && typeof value === "object") {
                    Object.values(value).forEach((nestedValue) => {
                      if (typeof nestedValue === "string") {
                        acc.push(nestedValue.toLowerCase());
                      }
                    });
                  }
                  return acc;
                }, [])
                .some((value) => value.includes(this.searchQuery.toLowerCase()))
            );
          });

          const formattedMatiere = filteredMatieres.map((matiere) => {
            return {
              id: matiere.id,
              intitule: matiere.intitule,

              duree: matiere.duree,
              editModal: true,
              /*   date: matiere.created_at */
            };
          });

          const page = {
            total_page: response.data.matiere.last_page,
            total: response.data.matiere.total,
            per_page: response.data.matiere.per_page,
            current_page: response.data.matiere.current_page,
            from_begin: response.data.matiere.from,
            to_begin: response.data.matiere.to,
          };

          this.matieres = formattedMatiere;
          this.total_page = page.total_page;
          this.total = page.total;
          this.per_page = page.per_page;
          this.current_page = page.current_page;
          this.from_begin = page.from_begin;
          this.to_begin = page.to_begin;

        } catch (error) {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la récupération des matiéres",
            "error"
          );
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    resetForm() {
      this.form_matiere.matieres.forEach(matiere => {
        matiere.id_matiere = "";
        matiere.id_departement = "";
        matiere.id_formateur = "";

        matiere.id_matiere_erreur = "";
        matiere.id_departement_erreur = "";
        matiere.id_formateur_erreur = "";
      });
      this.form.input = "";
      this.form.intitule = "";
      this.form.id_classe = "";
      this.formateurId = "";
    },

    async deleteMatiere(type) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasDeletePermission("Matière")) {
        Swal.fire({
          title: "Voulez-vous confirmer la suppression?",
          text: "Cette action sera irréversible!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
        }).then((result) => {
          if (result.value) {
            axios
              .delete(`/matiere/delete/${type.id}`, { headers })
              .then((resp) => {
                showDialog6("Matiére supprimée avec succés");
                this.get_matiere();
              })
              .catch(function (error) {
                console.log(error);
                showDialog3("Erreur lors de la suppression de la matiére");
              });
          }
        });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    openModal(matiere) {
      this.editModal = true;
      // Créez un objet avec les données à envoyer
      const eventData = {
        matiere: matiere,
        editModal: this.editModal,
        // Ajoutez d'autres propriétés si nécessaire
      };
      bus.emit("matiereModifier", eventData);
    },

    async deleteMatiereAssign(formateur) {
      $('#exampleModal1').modal('hide');
      const confirmation = Swal.fire({
        title: "Êtes-vous sûr de vouloir désassigner cette matière a ce formateur?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then((result) => {
        if (this.hasDeletePermission("Matière")) {
          if (result.value) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            try {
              axios.delete(`/matiere/formateur/delete/${formateur}`, { headers });
              Swal.fire({
                position: " bottom-end",
                title: "Formateur rétiré avec succès",
                showConfirmButton: false,
                icon: "success",
                timer: 1500,
              }).then((result) => {
                // if (result.value) {
                this.find_professeur_assign_to_matiere(this.formateurId);

                $('#exampleModal1').modal('show');
                // }
              });
            } catch (error) {
              showDialog3(
                "Une erreur est survenue lors de la designation du formateur"
              );
            }
          } else {
            this.find_professeur_assign_to_matiere(this.formateurId);

            $('#exampleModal1').modal('show');
          }
        } else {
          // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
          showDialog3(
            "Vous n'avez pas la permission de lire cette fonctionnalité."
          );
        }
      });

    },

    duplicateMatiere(index) {
      const newMatiere = { ...this.form_matiere.matieres[index], id_departement: '', id_formateur: '', formateurs: [],id_matiere_erreur: "",
        id_departement_erreur: "",
        id_formateur_erreur: "", };
      this.form_matiere.matieres.push(newMatiere);
      this.$nextTick(() => {
        this.reinitializeMDL();
      });
    },
    removeMatiere(index) {
      if (this.form_matiere.matieres.length > 1) {
        this.form_matiere.matieres.splice(index, 1);
      }
    },
/*     duplicateMatiere(index) {
      const matiere = this.form_matiere.matieres[index];
      const newMatiere = {
        ...matiere,
        id_matiere: "",
        id_departement: "",
        id_formateur: "",

        id_matiere_erreur: "",
        id_departement_erreur: "",
        id_formateur_erreur: "",
      };

      this.form_matiere.matieres.splice(index + 1, 0, newMatiere);

      this.$nextTick(() => {
        this.reinitializeMDL();
      });
    },


    reinitializeMDL() {
      window.componentHandler.upgradeDom();
    },

    removeMatiere(index) {
      if (this.form_matiere.matieres.length > 1) {
        this.form_matiere.matieres.splice(index, 1);
      }
    }, */

    reinitializeMDL() {
      window.componentHandler.upgradeDom();
    },

    async soumettreMatiereAssignerProfesseur() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      // formdata.append('id_formateur', this.formateursFind);
      formdata.append("matiereProfessur", JSON.stringify(this.form_matiere.matieres));

      if (this.hasCreatePermission("Matière")) {
        try {
          const create_store = await axios.post("/matiere/formateur/store", formdata, {
            headers,
          });
          $('#exampleModal2').modal('hide');
          Swal.fire({
            position: " bottom-end",
            title: "Matiere assignée avec succès",
            showConfirmButton: false,
            icon: "success",
            timer: 1500,
          }).then((result) => {
            this.resetForm();
            $('#exampleModal2').modal('show');
            // }
          });

        } catch (e) {
          if (409 === e.response.data.statut) {
            $('#exampleModal2').modal('hide');
            Swal.fire({
              title: "Oops...",
              text: e.response.data.message,
              icon: "error",
              footer: "<a href>Reessayer s'il vous plait</a>",

            }).then((result) => {
              if (result.value) {
                this.find_matiere_professeur(this.formateursFind);
                this.get_matiere();
                $('#exampleModal2').modal('show');
              }
            });
          } else {
            showDialog3("Une erreur est survenue lors de l'enregistrement");
          }
        }
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    validatedata(champ) {
      // Variable pour vérifier la présence d'erreurs
      let hasError = false;

      // Validation spécifique en fonction du champ
      switch (champ) {
        case "id_matiere":
          this.form_matiere.matieres.forEach((matiere, index) => {
            matiere.id_matiere_erreur = "";
            if (matiere.id_matiere === "") {
              matiere.id_matiere_erreur = "Vous avez oublié de sélectionner la matiere au niveau " + (index + 1);
              hasError = true;
              return hasError;
            }
          });
          break;
        case "id_formateur":
          this.form_matiere.matieres.forEach((matiere, index) => {
            matiere.id_formateur_erreur = "";
            if (matiere.id_formateur === "") {
              matiere.id_formateur_erreur = "Vous avez oublié de sélectionner le formateur au niveau " + (index + 1);
              hasError = true;
              return hasError;
            }
          });
          break;
        case "id_departement":
          this.form_matiere.matieres.forEach((matiere, index) => {
            matiere.id_departement_erreur = "";
            if (matiere.id_departement === "") {
              matiere.id_departement_erreur = "Vous avez oublié de sélectionner le departement au niveau " + (index + 1);
              hasError = true;
              return hasError;
            }
          });
          break;

        default:
          break;
      }
    },
    validatedataOld() {
      // Réinitialiser toutes les erreurs
      this.form_matiere.matieres.forEach(matiere => {
        matiere.id_matiere_erreur = "";
        matiere.id_departement_erreur = "";
        matiere.id_formateur_erreur = "";

      });

      // Variable pour vérifier la présence d'erreurs
      let hasError = false;

      // Validation des champs
      this.form_matiere.matieres.forEach((matiere, index) => {
        if (matiere.id_matiere === "") {
          matiere.id_matiere_erreur = "Vous avez oublié de sélectionner une matiere " + (index + 1) + ".";
          hasError = true;
        }
        if (matiere.id_departement === "") {
          matiere.id_departement_erreur = "Vous avez oublié de sélectionner le departement au niveau " + (index + 1) + ".";
          hasError = true;
        }
        if (matiere.id_formateur === "") {
          matiere.id_formateur_erreur = "Vous avez oublié de sélectionner le formateur au niveau " + (index + 1) + ".";
          hasError = true;
        }

      });
      return hasError;
    },

    validerAvantAjout() {
      const isVerifIdValid = this.validatedataOld();

      if (isVerifIdValid === true) {
        this.etatForm = false;
        this.editModal = false;

        return 0;
      } else {
        this.soumettreMatiereAssignerProfesseur();
        this.etatForm = true;
        this.editModal = false;
      }
    },

    watch: {
    form_matiere: {
      deep: true,
      handler(newValue) {
        newValue.matieres.forEach((matiereProf, index) => {
          if (matiereProf.id_departement) {
            this.get_formateur_by_id_departement(index, matiereProf.id_departement);
          }
        });
      },
    },
  },
  },
};
</script>

<style>
/* Assurez-vous que les labels sont bien positionnés */
label {
  position: relative;
  top: 0;
}
</style>
