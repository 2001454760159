<template>
  <div class="page-wrapper" v-if="!this.editModal">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'/dashboard'"
                    >Accueil</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>

                <li class="active">
                  Paramétres &nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/liste-departement'">
                    Département </a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab"
                      >Département</a
                    >
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Tous les départements</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a
                                    :href="'/create-departement'"
                                    id="addRow"
                                    class="btn btn-primary"
                                  >
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div
                                  class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length"
                                >
                                  <label class="me-2">Afficher</label>
                                  <select
                                    name="example47_length"
                                    aria-controls="example47"
                                    class="form-select-sm"
                                    @change="updatePerPage"
                                    v-model="limitQuery"
                                  >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2"
                                    >Recherche :</label
                                  >
                                  <input
                                    type="text"
                                    id="searchInput"
                                    placeholder=""
                                    aria-controls="example47"
                                    v-model="searchQuery"
                                    @input="get_departement(currentPage)"
                                  />
                                </div>
                              </div>
                            </div>

                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Département</th>
                                  <th>Chef de département</th>
                                  <th>Direction</th>
                                  <th>Date de création</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(departement, index) in departements"
                                  :key="index"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ departement.departement }}</td>
                                  <td>
                                    {{ departement.user_prenom }}
                                    {{ departement.user_nom }}
                                  </td>
                                  <td>{{ departement.direction }}</td>
                                  <td>
                                    {{ this.formatDateTime(departement.date) }}
                                  </td>

                                  <td>
                                    <a
                                      class="tblEditBtn"
                                      @click="openModal(departement)"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a
                                      class="tblDelBtn"
                                      @click="deleteDepartement(departement)"
                                    >
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              class="row"
                              v-if="this.departements.length > 0"
                            >
                              <div class="col-sm-12 col-md-5">
                                <div
                                  class="dataTables_info"
                                  id="datable_1_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Affichage de {{ from_begin }} à
                                  {{ to_begin }} sur {{ total }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div
                                  class="dataTables_paginate paging_simple_numbers"
                                  id="datable_1_paginate"
                                >
                                  <paginate-component
                                    :total-pages="total_page"
                                    :total="total"
                                    :per-page="per_page"
                                    :current-page="current_page"
                                    @pagechanged="pagPage"
                                  ></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <appafterContent />
    </div>
    <appfooter />
  </div>
  <div v-if="this.editModal">
    <FormulaireModification />
  </div>
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import datatable from "datatables.net-bs5";
import FormulaireModification from "./createDepartementComponent.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import permissionMixin from "@/permission";

import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    FormulaireModification,
    PaginateComponent,
  },
  mixins: [permissionMixin],
  name: "listeDepartementCompenent",

  data() {
    return {
      form: new Form({
        nom: "",
        id_direction: "",
        id_user: "",
      }),
      departements: [],
      idDepartement: "",
      editModal: false,
      limitQuery: 5,
      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,
      searchQuery: "",
      currentPage: 1,
      lastPage: 1,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_departement();
    bus.on("departementAjoutee", () => {
      this.get_departement();
    });
    bus.on("departementDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_departement();
    });
  },

  /*  mounted() {
        this.get_departement();
        bus.on('departementAjoutee', () => {
            this.get_departement();
        });
        bus.on('departementDejaModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.get_departement();
        });
    }, */

  methods: {
    ...mapActions(["login", "logout"]),
    pagPage(pagination) {
      this.current_page = pagination;
      this.get_departement(this.currentPage);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },

    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQuery !== 5) {
        this.limitQuery = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_direction(this.currentPage);
      }
    },
    formatDateTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.formatDate(new Date(dateTime));
    },
    formatDate(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      // Tableau des trois premières lettres des mois en français
      const monthAbbreviations = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];

      // Obtenez les trois premières lettres du mois correspondant au numéro du mois
      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },

    async get_departement() {
      const token = localStorage.getItem("token");
      const filterCondition = (depart) => true; // Condition de filtre à définir

      const headers = { Authorization: `Bearer ${token}` };
      const params = {
        per_page: this.limitQuery,
        page: this.current_page,
        // Ajoutez d'autres paramètres si nécessaire
      };
      if (this.hasReadPermission("Departement")) {
        try {
          // const response = await axios.get('/departement/all/paginate', { headers });
          const response = await axios.get("/departement/all/paginate", {
            headers: headers,
            params: params, // Ajouter les paramètres ici
          });
          const allDepartements = response.data.departement.data;

          const filteredDepartements = allDepartements.filter((depart) => {
            return (
              filterCondition(depart) &&
              Object.keys(depart)
                .reduce((acc, key) => {
                  const value = depart[key];
                  if (typeof value === "string") {
                    acc.push(value.toLowerCase());
                  } else if (value && typeof value === "object") {
                    Object.values(value).forEach((nestedValue) => {
                      if (typeof nestedValue === "string") {
                        acc.push(nestedValue.toLowerCase());
                      }
                    });
                  }
                  return acc;
                }, [])
                .some((value) => value.includes(this.searchQuery.toLowerCase()))
            );
          });

          const formattedDepartements = filteredDepartements.map((depart) => {
            return {
              id: depart.id,
              departement: depart.nom_departement,
              direction: depart.direction.nom_direction,
              id_direction: depart.direction.id,
              user_prenom: depart.user ? depart.user.prenom : "Non défini",
              user_nom: depart.user ? depart.user.nom : "Non défini",
              id_user: depart.user ? depart.user.id : null,
              editModal: true,
              date: depart.created_at,
            };
          });
          const page = {
            total_page: response.data.departement.last_page,
            total: response.data.departement.total,
            per_page: response.data.departement.per_page,
            current_page: response.data.departement.current_page,
            from_begin: response.data.departement.from,
            to_begin: response.data.departement.to,
          };

          this.departements = formattedDepartements;
          // this.directions = formattedDirections;
          this.total_page = page.total_page;
          this.total = page.total;
          this.per_page = page.per_page;
          this.current_page = page.current_page;
          this.from_begin = page.from_begin;
          this.to_begin = page.to_begin;

        } catch (error) {
          //Swal.fire('Erreur!', 'Une erreur est survenue lors de la recuperation des departements', 'error')
          showDialog3(
            "Une erreur est survenue lors de la recuperation des departements"
          );
        }
      }
    },

    changement(event) {
      this.interesser = event;
    },

    resetForm() {
      this.form.nom = "";
      this.form.id_direction = "";
      this.form.id_user = "";
    },

    async deleteDepartement(type) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasDeletePermission("Departement")) {
        Swal.fire({
          title: "Voulez-vous confirmer la suppression?",
          text: "Cette action sera irréversible!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
        }).then((result) => {
          if (result.value) {
            axios
              .delete(`/departement/delete/${type.id}`, { headers })
              .then((resp) => {
                showDialog6("Département supprimé avec succès");
                this.get_departement();
              })
              .catch(function (error) {
                console.log(error);
                showDialog3("Erreur lors de la suppression du département");
              });
          }
        });
      }
    },

    openModal(departement) {
      this.editModal = true;
      // Créez un objet avec les données à envoyer
      const eventData = {
        departement: departement,
        editModal: this.editModal,
      };

      bus.emit("departementModifier", eventData);
    },
  },
};
</script>
