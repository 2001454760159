<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <appheader
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
        />
      </div>
    </div>
    <div class="page-container">
      <appnavbar
        :userNom="userNom"
        :userPrenom="userPrenom"
        :userPhoto="userPhoto"
        :userIdrole="userIdrole"
        :userRole="userRole"
      />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <!-- start page content -->
        <div class="page-content" v-if="!this.editModal">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a
                    class="parent-item"
                    :href="'dashboard'"
                    >Accueil</a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>

                <li class="active">
                  Paramétres &nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'/liste-batiment'">
                    Batiment </a
                  >&nbsp;<i class="fa fa-angle-right"></i>
                </li>
              </ol>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-line">
                <ul class="nav customtab nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a href="#tab1" class="nav-link active" data-bs-toggle="tab"
                      >Batiment</a
                    >
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active fontawesome-demo" id="tab1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card card-box">
                          <div class="card-head">
                            <header>Tous les batiments</header>
                            <div class="tools">
                              <a
                                class="fa fa-repeat btn-color box-refresh"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-collapse btn-color fa fa-chevron-down"
                                href="javascript:;"
                              ></a>
                              <a
                                class="t-close btn-color fa fa-times"
                                href="javascript:;"
                              ></a>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 col-sm-6 col-6">
                                <div class="btn-group">
                                  <a
                                    :href="'/create-batiment'"
                                    id="addRow"
                                    class="btn btn-primary"
                                  >
                                    Ajouter
                                    <i class="fa fa-plus text-white"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <div class="row align-items-end">
                              <div class="col-sm-12 col-md-4">
                                <div
                                  class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                  id="example47_length"
                                >
                                  <label class="me-2">Afficher</label>
                                  <select
                                    name="example47_length"
                                    aria-controls="example47"
                                    class="form-select-sm"
                                    @change="updatePerPage"
                                    v-model="limitQuery"
                                  >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                  </select>
                                  <span class="ms-2">entrées</span>
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                <div style="float: right">
                                  <label for="searchInput" class="me-2"
                                    >Recherche :</label
                                  >
                                  <input
                                    type="text"
                                    id="searchInput"
                                    placeholder=""
                                    aria-controls="example47"
                                    v-model="searchQuery"
                                    @input="get_batiment(currentPage)"
                                  />
                                </div>
                              </div>
                            </div>

                            <!--  -->
                            <table
                              class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                              id="example47"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Batiment</th>
                                  <th>Nombre de Salle</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="odd gradeX"
                                  v-for="(batiment, index) in batiments"
                                  :key="index"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td class="left">{{ batiment.intitule }}</td>
                                  <td class="left">{{ batiment.nbr_salle }}</td>

                                  <td class="left">
                                    <a
                                      class="tblEditBtn"
                                      @click="openModal(batiment)"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </a>
                                    <a
                                      class="tblDelBtn"
                                      @click="deleteBatiment(batiment)"
                                    >
                                      <i class="fa fa-trash-o"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="row" v-if="this.batiments.length > 0">
                              <div class="col-sm-12 col-md-5">
                                <div
                                  class="dataTables_info"
                                  id="datable_1_info"
                                  role="status"
                                  aria-live="polite"
                                >
                                  Affichage de {{ from_begin }} à
                                  {{ to_begin }} sur {{ total }} entrées
                                </div>
                              </div>

                              <div class="col-sm-12 col-md-7 text-right">
                                <div
                                  class="dataTables_paginate paging_simple_numbers"
                                  id="datable_1_paginate"
                                >
                                  <paginate-component
                                    :total-pages="total_page"
                                    :total="total"
                                    :per-page="per_page"
                                    :current-page="current_page"
                                    @pagechanged="pagPage"
                                  ></paginate-component>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-content-wrapper" v-show="editModal">
          <div class="page-content">
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">Nouveau batiment</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li>
                    <i class="fa fa-home"></i>&nbsp;<a
                      class="parent-item"
                      :href="'/dashboard'"
                      >Tableau de Bord</a
                    >&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li>
                    <a class="parent-item" :href="'/liste-batiment'">Batiment</a
                    >&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li class="active">Modifier Batiment</li>
                </ol>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card-box">
                  <div class="card-head">
                    <header>Information</header>
                    <button
                      id="panel-button"
                      class="mdl-button mdl-js-button mdl-button--icon pull-right"
                      data-upgraded=",MaterialButton"
                    >
                      <i class="material-icons">more_vert</i>
                    </button>
                    <ul
                      class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                      data-mdl-for="panel-button"
                    >
                      <li class="mdl-menu__item">
                        <i class="material-icons">assistant_photo</i>Action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">print</i>Another action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">favorite</i>Something else
                        here
                      </li>
                    </ul>
                  </div>
                  <div class="card-body row">
                    <FormulaireModification />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />
  </div>
</template>

<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";

import FormulaireModification from "./editBatiment.vue";

import datatable from "datatables.net-bs5";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    FormulaireModification,
    appfooter,
    appheader,
    appafterContent,
    PaginateComponent,
  },
  mixins: [permissionMixin],
  name: "listeBatimentComponent",
  data() {
    return {
      form: new Form({
        intitule: "",
      }),
      batiments: [],
      idBatiment: "",
      editModal: false,
      limitQuery: 5,
      total_page: 0,
      total: 0,
      per_page: 0,
      current_page: 1,
      from_begin: 0,
      to_begin: 0,
      is_loading: false,
      searchQuery: "",
      currentPage: 1,
      lastPage: 1,
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_batiment();
    bus.on("batimentAjoutee", () => {
      this.get_batiment();
    });
    bus.on("batimentDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_batiment();
    });
  },

  /*   mounted() {
        this.get_batiment();
        bus.on('batimentAjoutee', () => {
            this.get_batiment();
        });
        bus.on('batimentDejaModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.get_batiment();
        });
    }, */

  methods: {
    ...mapActions(["login", "logout"]),
    pagPage(pagination) {
      this.current_page = pagination;
      this.get_batiment(this.currentPage);
    },
    updatePerPage(event) {
      // Récupérer la nouvelle valeur sélectionnée
      const newPerPage = parseInt(event.target.value);

      // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
      this.reloadDataWithPerPage(newPerPage);
    },

    reloadDataWithPerPage(newPerPage) {
      // Mettre à jour la limite de la requête avec la nouvelle quantité par page
      if (this.limitQuery !== 5) {
        this.limitQuery = newPerPage;
        // Recharger les données avec la nouvelle quantité par page
        this.get_batiment(this.currentPage);
      }
    },

    async get_batiment() {
      const token = localStorage.getItem("token");
      const filterCondition = (batiment) => true; // Condition de filtre à définir
      const headers = { Authorization: `Bearer ${token}` };
      const params = {
        per_page: this.limitQuery,
        page: this.current_page,
        // Ajoutez d'autres paramètres si nécessaire
      };

      if (this.hasReadPermission("Batiment")) {
        try {
          //const response = await axios.get('/batiment/index', { headers });
          const response = await axios.get("/batiment/index/paginate", {
            headers: headers,
            params: params, // Ajouter les paramètres ici
          });
          const allBatiment = response.data.batiment.data;

          const filteredBatiments = allBatiment.filter((batiment) => {
            return (
              filterCondition(batiment) &&
              Object.keys(batiment)
                .reduce((acc, key) => {
                  const value = batiment[key];
                  if (typeof value === "string") {
                    acc.push(value.toLowerCase());
                  } else if (typeof value === "number") {
                    acc.push(value.toString().toLowerCase());
                  } else if (value && typeof value === "object") {
                    Object.values(value).forEach((nestedValue) => {
                      if (typeof nestedValue === "string") {
                        acc.push(nestedValue.toLowerCase());
                      } else if (typeof nestedValue === "number") {
                        acc.push(nestedValue.toString().toLowerCase());
                      }
                    });
                  }
                  return acc;
                }, [])
                .some((value) => value.includes(this.searchQuery.toLowerCase()))
            );
          });

          const formattedBatiments = filteredBatiments.map((batiment) => {
            return {
              id: batiment.id,
              intitule: batiment.intitule,
              nbr_salle: batiment.salle.length,
              editModal: true,
            };
          });

          const page = {
            total_page: response.data.batiment.last_page,
            total: response.data.batiment.total,
            per_page: response.data.batiment.per_page,
            current_page: response.data.batiment.current_page,
            from_begin: response.data.batiment.from,
            to_begin: response.data.batiment.to,
          };

          this.batiments = formattedBatiments;
          this.total_page = page.total_page;
          this.total = page.total;
          this.per_page = page.per_page;
          this.current_page = page.current_page;
          this.from_begin = page.from_begin;
          this.to_begin = page.to_begin;
        } catch (error) {
          showDialog3(
            "Une erreur est survenue lors de la récupération des batiments"
          );
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    changement(event) {
      this.interesser = event;
    },

    resetForm() {
      this.form.input = "";
      this.form.intitule = "";
    },

    async deleteBatiment(type) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasDeletePermission("Batiment")) {
        Swal.fire({
          title: "Voulez-vous confirmer la suppression?",
          text: "Cette action sera irréversible!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
        }).then((result) => {
          if (result.value) {
            axios
              .delete(`/batiment/delete/${type.id}`, { headers })
              .then((resp) => {
                showDialog6("Batiment supprimé avec succés");
                this.get_batiment();
              })
              .catch(function (error) {
                console.log(error);
                showDialog3(
                  "Une erreur est survenue lors de la suppression du batiment"
                );
              });
          }
        });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    openModal(batiment) {
      this.editModal = true;
      // Créez un objet avec les données à envoyer
      const eventData = {
        batiment: batiment,
        editModal: this.editModal,
      };
      bus.emit("batimentModifier", eventData);
    },
  },
};
</script>
