<template>
    <div class="page-wrapper">

        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper">
                <!-- start page content -->
                <div class="page-content" v-if="!this.editModal">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">

                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                        :href="'/dashboard'">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li><a class="parent-item" :href="'/liste-paiement'"> Nouevau Paiement</a>&nbsp;<i
                                        class="fa fa-angle-right"></i>
                                </li>
                                <li class="active">Liste Paiements &nbsp;<i class="fa fa-angle-right"></i> </li>

                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tabbable-line">
                                <ul class="nav customtab nav-tabs" role="tablist">
                                    <li class="nav-item"><a href="#tab1" class="nav-link active"
                                            data-bs-toggle="tab">Paiement</a>
                                    </li>

                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane active fontawesome-demo" id="tab1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="card card-box">
                                                    <div class="card-head">
                                                        <header>Liste des paiements</header>
                                                        <div class="tools">
                                                            <a class="fa fa-repeat btn-color box-refresh"
                                                                href="javascript:;"></a>
                                                            <a class="t-collapse btn-color fa fa-chevron-down"
                                                                href="javascript:;"></a>
                                                            <a class="t-close btn-color fa fa-times"
                                                                href="javascript:;"></a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body ">
                                                        <div class="row">
                                                            <div class="col-md-6 col-sm-6 col-6">
                                                                <div class="btn-group">
                                                                    <a :href="'/create-paiement'" id="addRow"
                                                                        class="btn btn-primary">
                                                                        Ajouter <i class="fa fa-plus text-white"></i>
                                                                    </a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--  -->
                                                        <div class="row align-items-end">
                                                            <div class="col-sm-12 col-md-4">
                                                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                                                    id="example47_length">
                                                                    <label class="me-2">Afficher</label>
                                                                    <select name="example47_length"
                                                                        aria-controls="example47" class="form-select-sm"
                                                                        @change="updatePerPage" v-model="limitQuery">
                                                                        <option value="5">5</option>
                                                                        <option value="10">10</option>
                                                                        <option value="15">15</option>
                                                                    </select>
                                                                    <span class="ms-2">entrées</span>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                                                <div style="float: right">
                                                                    <label for="searchInput" class="me-2">Recherche
                                                                        :</label>
                                                                    <input type="text" id="searchInput" placeholder=""
                                                                        aria-controls="example47" v-model="searchQuery"
                                                                        @input="get_paiement(currentPage)" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--  -->

                                                        <table
                                                            class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                                                            id="example47">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Matricule</th>
                                                                    <th>Nom</th>
                                                                    <th>Prenom</th>
                                                                    <th>Classe</th>
                                                                    <th>Mode de paiement</th>
                                                                    <th>Type recouvrement</th>
                                                                    <th>Année Académique</th>
                                                                    <th>Mois</th>
                                                                    <th>Montant</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="odd gradeX"
                                                                    v-for="(paiement, index) in paiements" :key="index">
                                                                    <td class="patient-img"> <img
                                                                            :src="getImageUrl(paiement.photo)"
                                                                            alt="Etu">
                                                                    </td>
                                                                    <td> {{ paiement.matricule }} </td>
                                                                    <td> {{ paiement.eleve_nom }}</td>
                                                                    <td> {{ paiement.eleve_prenom }}</td>
                                                                    <td> {{ paiement.classe }}</td>
                                                                    <td> {{ paiement.mode_paiement }}</td>
                                                                    <td> {{ paiement.type_recouvrement }}</td>
                                                                    <td> {{ paiement.annee }} </td>
                                                                    <td> {{ paiement.mois }} </td>
                                                                    <td> {{ paiement.montant }} </td>

                                                                    <td>
                                                                        <a class="tblEditBtn"
                                                                            @click="openModal(paiement)">
                                                                            <i class="fa fa-pencil"></i>
                                                                        </a>
                                                                        <a class="tblDelBtn"
                                                                            @click="deletepaiement(paiement)">
                                                                            <i class="fa fa-trash-o"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div class="row" v-if="this.paiements.length > 0">
                                                            <div class="col-sm-12 col-md-5">
                                                                <div class="dataTables_info" id="datable_1_info"
                                                                    role="status" aria-live="polite">
                                                                    Affichage de {{ from_begin }} à
                                                                    {{ to_begin }} sur {{ total }} entrées
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-7 text-right">
                                                                <div class="dataTables_paginate paging_simple_numbers"
                                                                    id="datable_1_paginate">
                                                                    <paginate-component :total-pages="total_page"
                                                                        :total="total" :per-page="per_page"
                                                                        :current-page="current_page"
                                                                        @pagechanged="pagPage"></paginate-component>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="page-content-wrapper" v-show="editModal">
                    <div class="page-content">
                        <div class="page-bar">
                            <div class="page-title-breadcrumb">
                                <div class=" pull-left">
                                    <div class="page-title">Nouveau paiement</div>
                                </div>
                                <ol class="breadcrumb page-breadcrumb pull-right">
                                    <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                            :href="'/caissier/accueil'">Tableau
                                            de Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                                    </li>
                                    <li><a class="parent-item" :href="'/paiement/accueil'">Paiement</a>&nbsp;<i
                                            class="fa fa-angle-right"></i>
                                    </li>
                                    <li class="active">Modifier Paiement</li>
                                </ol>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card-box">
                                    <div class="card-head">
                                        <header>Information</header>
                                        <button id="panel-button"
                                            class="mdl-button mdl-js-button mdl-button--icon pull-right"
                                            data-upgraded=",MaterialButton">
                                            <i class="material-icons">more_vert</i>
                                        </button>
                                        <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                                            data-mdl-for="panel-button">
                                            <li class="mdl-menu__item"><i
                                                    class="material-icons">assistant_photo</i>Action
                                            </li>
                                            <li class="mdl-menu__item"><i class="material-icons">print</i>Another action
                                            </li>
                                            <li class="mdl-menu__item"><i class="material-icons">favorite</i>Something
                                                else
                                                here</li>
                                        </ul>
                                    </div>
                                    <div class="card-body row">
                                        <FormulaireModification />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <appafterContent />
            </div>
            <appfooter />
        </div>
    </div>
</template>

<script>
import bus from '../../eventBus';
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";

import axios from '@/axios';
import Form from 'vform';
import datatable from 'datatables.net-bs5';
import FormulaireModification from './editPaiement.vue';
import { mapState, mapActions } from "vuex";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
    components: {
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        FormulaireModification,
        PaginateComponent
    },
    mixins: [permissionMixin],
    name: "listeUserCompenent",

    data() {
        return {
            form: new Form({
                'nom_paiement': ""

            }),
            paiements: [],
            editModal: false,
            limitQuery: 5,
            total_page: 0,
            total: 0,
            per_page: 0,
            current_page: 1,
            from_begin: 0,
            to_begin: 0,
            is_loading: false,
            searchQuery: "",
            currentPage: 1,
            lastPage: 1,


        }
    },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },

    async created() {
        await this.get_permission();
        this.get_paiement();
        bus.on('paiementAjoutee', () => {
            this.get_paiement();
        });

        bus.on('paiementDejaModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.get_paiement();
        });
    },
    /*  mounted() {
         this.get_paiement();
         bus.on('paiementAjoutee', () => { // Écouter l'événement de nouvelle utilisateur ajoutée
             this.get_paiement(); // Mettre à jour la liste des utilisateurs
         });
 
         bus.on('paiementDejaModifier', (eventData) => {
             this.editModal = eventData.editModal;
             this.get_paiement();
         });
     }, */

    methods: {
        ...mapActions(["login", "logout"]),
        pagPage(pagination) {
            this.current_page = pagination;
            this.get_paiement(this.currentPage);
        },
        updatePerPage(event) {
            // Récupérer la nouvelle valeur sélectionnée
            const newPerPage = parseInt(event.target.value);

            // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
            this.reloadDataWithPerPage(newPerPage);
        },

        reloadDataWithPerPage(newPerPage) {
            // Mettre à jour la limite de la requête avec la nouvelle quantité par page
            if (this.limitQuery !== 5) {
                this.limitQuery = newPerPage;
                // Recharger les données avec la nouvelle quantité par page
                this.get_paiement(this.currentPage);
            }
        },
        async get_paiement() {
            const token = localStorage.getItem("token");
            const filterCondition = (direct) => true; // Condition de filtre à définir
            const headers = { Authorization: `Bearer ${token}` };
            const params = {
                per_page: this.limitQuery,
                page: this.current_page,
                // Ajoutez d'autres paramètres si nécessaire
            };

            if (this.hasReadPermission("Paiement Scolarité")) {
                try {
                    // const response = await axios.get('/direction/index/paginate', { headers });
                    const response = await axios.get('/paiement/index/paginate', {
                        headers: headers,
                        params: params, // Ajouter les paramètres ici
                    });

                    const allpaiement = response.data.paiement.data;

                    const filteredPaiements = allpaiement.filter((paie) => {
                        return (
                            filterCondition(paie) &&
                            Object.keys(paie).reduce((acc, key) => {
                                const value = paie[key];
                                if (typeof value === 'string') {
                                    acc.push(value.toLowerCase());
                                } else if (value && typeof value === 'object') {
                                    Object.values(value).forEach((nestedValue) => {
                                        if (typeof nestedValue === 'string') {
                                            acc.push(nestedValue.toLowerCase());
                                        }
                                    });
                                }
                                return acc;
                            }, []).some((value) => value.includes(this.searchQuery.toLowerCase()))
                        );
                    });

                    const formattedPaiement = filteredPaiements.map(paie => {
                        return {
                            id: paie.id,
                            id_eleve: paie.eleve.user.id,
                            matricule: paie.eleve.user.matricule,
                            photo: paie.eleve.user.photo,
                            eleve_prenom: paie.eleve.user.prenom,
                            eleve_nom: paie.eleve.user.nom,
                            date_naissance: paie.eleve.user.date_naissance,
                            adresse: paie.eleve.user.adresse,
                            matricule: paie.eleve.user.matricule,

                            type_recouvrement: paie.type_recouvrement,
                            mode_paiement: paie.mode_paiement,
                            reference: paie.reference,

                            //nom_service: utilisateur.personnel_admin_appui.map(ele => ele.service.nom_service).join(', '),
                            classe: paie.eleve.inscription.map(p => p.classe.nom_classe).join(', '),
                            annee: paie.concerner.map(p => p.annee_academique.intitule).join(', '),
                            mois: paie.concerner.map(p => p.mois.intitule).join(', '),
                            id_annee: paie.concerner.map(p => p.annee_academique.id).join(', '),
                            id_mois: paie.concerner.map(p => p.mois.id).join(', '),
                            montant: paie.montant,
                            editModal: true,
                        };
                    });
                    this.paiements = formattedPaiement;

                    const page = {
                        total_page: response.data.paiement.last_page,
                        total: response.data.paiement.total,
                        per_page: response.data.paiement.per_page,
                        current_page: response.data.paiement.current_page,
                        from_begin: response.data.paiement.from,
                        to_begin: response.data.paiement.to,
                    };

                    this.paiements = formattedPaiement;
                    this.total_page = page.total_page;
                    this.total = page.total;
                    this.per_page = page.per_page;
                    this.current_page = page.current_page;
                    this.from_begin = page.from_begin;
                    this.to_begin = page.to_begin;

                } catch (error) {
                    Swal.fire('Erreur!', 'Une erreur est survenue lors de la récupération des paiements', 'error');
                }
            }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },


        changement(event) {
            this.interesser = event;
        },

        resetForm() {
            this.form.input = "";
            this.form.nom_paiement = "";
        },

        async deletepaiement(type) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasDeletePermission("Paiement Scolarité")) {
                Swal.fire({
                    title: 'Êtes-vous sûr?',
                    text: "Cette action sera irréversible!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui, supprimer!',
                    cancelButtonText: 'Annuler'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.delete(`/paiement/delete/${type.id}`, { headers }).then(resp => {
                            showDialog6("Paiment supprimée avec succés")
                            this.get_paiement();

                        }).catch(function (error) {
                            console.log(error);
                            showDialog3("Erreur lors de la suppression du paiement")
                        })
                    }
                });
            }
            else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission requise.");
            }
        },

        openModal(paiement) {
            this.editModal = true;
            // Créez un objet avec les données à envoyer
            const eventData = {
                paiement: paiement,
                editModal: true
                // Ajoutez d'autres propriétés si nécessaire
            };
            bus.emit('paiementModifier', eventData);
        },
        getImageUrl(url) {
            //return url ? `${window.location.origin}/storage/${url}` : '';
            //return url ? `http://127.0.0.1:8000/storage/${url}` : '';
            return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
        },
    }
}
</script>