<template>
    <div class="page-wrapper">
      <div class="page-header navbar navbar-fixed-top">
        <div class="page-header-inner">
          <appheader
            :userNom="userNom"
            :userPrenom="userPrenom"
            :userPhoto="userPhoto"
          />
        </div>
      </div>
      <div class="page-container">
        <appnavbar
          :userNom="userNom"
          :userPrenom="userPrenom"
          :userPhoto="userPhoto"
          :userIdrole="userIdrole"
          :userRole="userRole"
        />
  
        <!-- start page content -->
        <div class="page-content-wrapper">
          <div class="page-content">
            <div class="page-bar">
              <div class="page-title-breadcrumb">
                <div class="pull-left">
                  <div class="page-title">Nouveau Cours</div>
                </div>
                <ol class="breadcrumb page-breadcrumb pull-right">
                  <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'dashboard'">Tableau de
                      Bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li class="active">Paramétres &nbsp;<a class="parent-item"></a>&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                  <li><a class="parent-item" :href="'listeCours'">Cours</a>&nbsp;<i class="fa fa-angle-right"></i>
                  </li>
                </ol>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card-box">
                  <div class="card-head">
                    <header>Information</header>
                    <button
                      id="panel-button"
                      class="mdl-button mdl-js-button mdl-button--icon pull-right"
                      data-upgraded=",MaterialButton"
                    >
                      <i class="material-icons">more_vert</i>
                    </button>
                    <ul
                      class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                      data-mdl-for="panel-button"
                    >
                      <li class="mdl-menu__item">
                        <i class="material-icons">assistant_photo</i>Action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">print</i>Another action
                      </li>
                      <li class="mdl-menu__item">
                        <i class="material-icons">favorite</i>Something else here
                      </li>
                    </ul>
                  </div>
  
                  <div class="card-body row">

<div class="col-lg-6 p-t-20">
  <div
    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
    <label for="list6" class="mdl-textfield__label" v-show="!form.id_classe">Choisissez la
      classe</label>
    <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1" v-model="form.id_classe"
      @change="validatedata('classe')">
      <option v-for="(classe, index) in classes" :value="classe.id" :key="index">
        {{ classe.nom_classe }} {{ classe.niveau }} {{
classe.type_classe }}
      </option>
    </select>
    <span class="erreur">{{ id_classe_erreur }}</span>
  </div>
</div>


<div class="col-lg-6 p-t-20">
  <div
    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
    <label for="list6" class="mdl-textfield__label" v-show="!form.id_formateur">Choisissez le
      formateur</label>
    <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1"
      v-model="form.id_formateur" @change="get_matiere_by_id_formateur(form.id_formateur)">
      <option v-for="(formateur, index) in formateurs" :value="formateur.id" :key="index">{{
formateur.user.prenom }} {{ formateur.user.nom }}
      </option>
    </select>
    <span class="erreur">{{ id_formateur_erreur }}</span>
  </div>
</div>

<div class="col-lg-6 p-t-20">
  <div
    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
    <label for="list6" class="mdl-textfield__label" v-show="!form.id_matiere">Choisissez la
      matiére</label>
      <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1" v-model="form.id_matiere"
    @change="validatedata('matiere')">
    <option v-if="matieres.length === 0" disabled selected>Aucune matière n'a été assigné à ce formateur</option>
    <option v-for="(matiereProf, index) in matieres" :value="matiereProf.matiere.id" :key="index">
        {{ matiereProf.matiere.intitule }}
    </option>
</select>

    <span class="erreur">{{ id_matiere_erreur }}</span>
  </div>
</div>

<div class="col-lg-6 p-t-20">
  <div
    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
    <label for="list6" class="mdl-textfield__label" v-show="!form.id_annee">Choisissez l'annee
      academique</label>
    <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1" v-model="form.id_annee"
      @change="validatedata('annee')">
      <option v-for="(annee, index) in annee_academiques" :value="annee.id" :key="index">
        {{ annee.intitule }}
      </option>
    </select>
    <span class="erreur">{{ id_annee_erreur }}</span>
  </div>
</div>

<div class="col-lg-12 p-t-20">
  <div
    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
    <label for="list6" class="mdl-textfield__label" v-show="!form.id_semestre">Choisissez le
      semestre</label>
    <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1"
      v-model="form.id_semestre" @change="validatedata('semestre')">
      <option v-for="(semestre, index) in semestres" :value="semestre.id" :key="index">{{
semestre.intitule }}
      </option>
    </select>
    <span class="erreur">{{ id_semestre_erreur }}</span>
  </div>
</div>





<div class="col-lg-12 p-t-20 text-center">

  <button type="submit" v-if="!this.editModal"
    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
    @click.prevent="validerAvantAjout()">Enregistrer</button>
  <button type="submit" v-if="this.editModal"
    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
    @click.prevent="validerAvantAjout()">Modifier</button>
  <button type="button"
    class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
    @click="resetForm">Annuler</button>
</div>

<div class="card card-box mt-4">
  <div class="card-head">
    <header>Liste des derniers cours enregistrés</header>
    <div class="tools">
      <a class="fa fa-repeat btn-color box-refresh" href="javascript:;"></a>
      <a class="t-collapse btn-color fa fa-chevron-down" href="javascript:;"></a>
      <a class="t-close btn-color fa fa-times" href="javascript:;"></a>
    </div>
  </div>
  <div class="card-body ">
    <table
      class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
      id="example47">
      <thead>
        <tr>
          <th> # </th>

          <th> Classe </th>
          <th> Formateur </th>
          <th> Matiére </th>
          <th> Annee Academique </th>
          <th> Semestre </th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd gradeX" v-for="(cour, index) in cours" :key="index">
          <td> {{ index + 1 }} </td>

          <td> {{ cour.date_cour }} </td>
          <td> {{ cour.classe.nom_classe }} {{ cour.classe.niveau }} {{ cour.classe.type_classe }}
          </td>
          <td> {{ cour.formateur.user.prenom }} {{ cour.formateur.user.nom }}</td>
          <td> {{ cour.matiere.intitule }} </td>
          <td> {{ cour.annee.intitule }} </td>
          <td> {{ cour.semestre.intitule }} </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <appafterContent />
      </div>
      <appfooter />
    </div>
  </template>
  
  <script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import { mapState, mapActions } from "vuex";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    flatPickr,
  },
  mixins: [permissionMixin],
  props: ["cour"],
  name: "createCourCompenent",

  data() {
    return {
      form: new Form({
        id_classe: "",
        id_annee: "",
        id_formateur: "",
        id_matiere: "",
        id_semestre: "",
      }),

      id_classe_erreur: "",
      id_annee_erreur: "",
      id_formateur_erreur: "",
      id_matiere_erreur: "",
      id_semestre_erreur: "",
      etatForm: false,
      editModal: false,
      cours: [],
      classes: [],
      formateurs: [],
      matieres: [],
      annee_academiques: [],
      semestres: [],
      idCour: "",
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },
  async created() {
    
    await this.get_permission();
    this.get_classe();
    this.get_cours();
    this.get_formateur();
    this.get_annee_academique();
    this.get_semestre();
   // this.get_matiere();
    bus.on("courModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.monterToupdate(eventData.cour);
    });
  },


  methods: {
    ...mapActions(["login", "logout"]),


    async soumettre() {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    const formdata = new FormData();

    formdata.append("id_classe", this.form.id_classe);
    formdata.append("id_formateur", this.form.id_formateur);
    formdata.append("id_matiere", this.form.id_matiere);
    formdata.append("id_annee_academique", this.form.id_annee);
    formdata.append("id_semestre", this.form.id_semestre);
    if (this.hasCreatePermission("Cours")) {
      try {
        const create_store = await axios.post("/cour/store", formdata, {
          headers,
        });
        showDialog6("Cours ajouté avec succès");
        bus.emit("courAjoutee");
        this.resetForm();
        setTimeout(() => {
          window.location.href = "/liste-cours";
        }, 1500);
      } catch (e) {
        // Affichez l'erreur complète pour aider à déboguer
        console.log(e);

        if (e.response && e.response.data && e.response.data.message) {
          const cours_existe = e.response.data.statut;

          if (404 === cours_existe) {
            console.log(cours_existe);
            Swal.fire("Erreur!", "Ce cours existe déjà", "error");
          } else {
            Swal.fire("Erreur!", "Une erreur est survenue lors de l'enregistrement", "error");
          }
        } else {
          // Gère les autres types d'erreurs
          Swal.fire("Erreur!", "Une erreur inattendue est survenue", "error");
        }
      }
    } else {
      // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
      showDialog3("Vous n'avez pas la permission requise.");
    }
  },

  

  async get_matiere_by_id_formateur(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if(this.hasReadPermission("Recouvrement Scolarité")){
      await axios
        .get(`/formateur/find/matiere/professeur/${id}`, { headers })
        .then((response) => {
          this.matieres = response.data.matiereformateur;
          this.validatedata("formateur");
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "une erreur est survenue lors de la recuperation des filières",
            "error"
          );
        });
      }
      else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
    },

    formatDateTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.formatDate(new Date(dateTime));
    },
    formatDate(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      // Tableau des trois premières lettres des mois en français
      const monthAbbreviations = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];

      // Obtenez les trois premières lettres du mois correspondant au numéro du mois
      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },

    verifCaratere(nom) {
      const valeur = /^[a-zA-ZÀ-ÿ\s]*$/;
      return valeur.test(nom);
    },

    validatedata(champ) {
      var i = 0;
      switch (champ) {

        case "classe":
          this.id_classe_erreur = "";
          //pour classe
          if (this.editModal) {
            if (this.form.id_classe === "") {
              this.id_classe_erreur =
                "Vous avez oublié de sélectionner  la classe'";
              i = 1;
              return true;
            }
          }
        case "formateur":
          this.id_formateur_erreur = "";
          //pour formateur
          if (this.editModal) {
            if (this.form.id_formateur === "") {
              this.id_formateur_erreur =
                "Vous avez oublié de sélectionner  le formateur'";
              i = 1;
              return true;
            }
          }
          break;

        case "matiere":
          this.id_matiere_erreur = "";
          //pour matiere
          if (this.editModal) {
            if (this.form.id_matiere === "") {
              this.id_matiere_erreur =
                "Vous avez oublié de sélectionner  la matiére'";
              i = 1;
              return true;
            }
          }
          break;

        case "annee":
          this.id_annee_erreur = "";
          //pour annee
          if (this.editModal) {
            if (this.form.id_annee === "") {
              this.id_annee_erreur =
                "Vous avez oublié de sélectionner l'annee'";
              i = 1;
              return true;
            }
          }
          break;

        case "semestre":
          this.id_semestre_erreur = "";
          //pour semestre
          if (this.editModal) {
            if (this.form.id_semestre === "") {
              this.id_semestre_erreur =
                "Vous avez oublié de sélectionner  le semestre'";
              i = 1;
              return true;
            }
          }
          break;

        default:
          break;
      }
    },

    validatedataOld() {

      this.id_classe_erreur = "";
      this.id_formateur_erreur = "";
      this.id_matiere_erreur = "";
      this.id_annee_erreur = "";
      this.id_semestre_erreur = "";
      var i = 0;

      if (this.form.id_annee === "") {
        this.id_annee_erreur = "Ce champ est obligatoire";
        i = 1;
      }
      if (this.editModal) {
        if (this.form.id_classe === "") {
          this.id_classe_erreur =
            "Vous avez oublié de sélectionner le chef de direction ";
          i = 1;
        }
        if (this.form.id_formateur === "") {
          this.id_formateur_erreur =
            "Vous avez oublié de sélectionner le chef de direction ";
          i = 1;
        }
        if (this.form.id_matiere === "") {
          this.id_matiere_erreur =
            "Vous avez oublié de sélectionner le chef de direction ";
          i = 1;
        }
        if (this.form.id_annee === "") {
          this.id_salle_erreur =
            "Vous avez oublié de sélectionner le chef de direction ";
          i = 1;
        }
        if (this.form.semestre === "") {
          this.semestre_erreur =
            "Vous avez oublié de sélectionner le chef de direction ";
          i = 1;
        }
      }

      if (i == 1) return true;

      return false;
    },

    validerAvantAjout() {
      const isNomDirectionValid = this.validatedataOld();

      if (isNomDirectionValid === true) {
        this.etatForm = false;
        this.editModal = false;
        return 0;
      } else {
        if (this.editModal === true) {
          this.etatForm = false;
          // this.form.intitule = this.form.intitule.toUpperCase();
          this.update_cour(this.idCour);

          this.editModal = false;
        } else {
          this.etatForm = true;
          // this.form.intitule = this.form.intitule.toUpperCase();
          this.soumettre();

          this.editModal = false;
        }
      }
    },

    resetForm() {

      this.form.id_classe_erreur = "";
      this.form.id_formateur_erreur = "";
      this.form.id_matiere_erreur = "";
      this.form.id_annee_erreur = "";
      this.form.id_semestre_erreur = "";
      this.editModal = false;
      this.form.id_classe = "";
      this.form.id_formateur = "";
      this.form.id_matiere = "";
      this.form.id_annee = "";
      this.form.id_semestre = "";

      const eventData = {
        editModal: false,
      };
      bus.emit("courDejaModifier", eventData);
    },

    async get_classe() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      
      if (this.hasReadPermission("Cours")) {
        await axios
          .get("/classe/all", { headers })
          .then((response) => {
            this.classes = response.data.classe;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recupération des derniers classes",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    async get_formateur() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasCreatePermission("Cours")) {
        await axios
          .get("/formateur/index", { headers })
          .then((response) => {
            this.formateurs = response.data.formateur;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recupération des derniers formatyeurs",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    get_matiere() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        axios
          .get("/matiere/index", { headers })
          .then((response) => {
            this.matieres = response.data.matiere;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernieres matieres",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    get_semestre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        axios
          .get("/semestre/index", { headers })
          .then((response) => {
            this.semestres = response.data.semestre;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernieres matieres",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_cours() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        await axios
          .get("/cour/get/last", { headers })
          .then((response) => {
            this.cours = response.data.cour;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des derniers cours",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    async get_salle() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Cours")) {
        await axios
          .get("/salle/index", { headers })
          .then((response) => {
            this.salles = response.data.salle;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des dernières salles",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    async get_annee_academique() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      await axios
        .get("/annee_academique/index", { headers })
        .then((response) => {
          this.annee_academiques = response.data.annee_academique;
        })
        .catch((error) => {
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la recuperation des année_academiques",
            "error"
          );
        });
    },

    async update_cour(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("id_classe", this.form.id_classe);
      formdata.append("id_formateur", this.form.id_formateur);
      formdata.append("id_matiere", this.form.id_matiere);
      formdata.append("id_annee_academique", this.form.id_annee);
      formdata.append("id_semestre", this.form.id_semestre);
      if (this.hasUpdatePermission("Cours")) {
        try {
          await axios.post("/cour/update/" + id, formdata, { headers });
          showDialog6("Cours modifié avec succès");
          bus.emit("courAjoutee");
          const eventData = {
            editModal: false,
          };
          bus.emit("courDejaModifier", eventData);
        } catch (e) {
          console.log(e)
          if (e.request.status === 404) {
            Swal.fire("Erreur !", "Ce Cours existe déjà", "error");
          } else {
            Swal.fire(
              "Erreur !",
              "Une erreur est survenue lors de l'enregistrement",
              "error"
            );
          }
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    monterToupdate(direction) {
      this.idDirection = direction.id;
      this.editModal = direction.editModal;
      this.form.nom_direction = direction.direction;
      this.form.id_user = direction.id_user;
    },
  },
};
</script>
  