<template>
  <div class="col-lg-12 p-t-20" v-show="searchidPaiement && !editModal">
    <div
      class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
    >
      <label class="mdl-textfield__label" for="txtCode" v-show="!search_query"
        >Numéro Facture</label
      >
      <input
        class="mdl-textfield__input"
        type="text"
        id="txtCode"
        v-model="this.search_query"
        @input="performSearch"
      />
      <span class="erreur">{{ this.id_paiement_erreur }}</span>
    </div>
  </div>
  <div
    v-for="(facture, key) in factures"
    :key="key"
    @click="selectFacture(facture)"
  >
    <a href="#">
      {{ facture.location.partenaire.nom_partenaire }}
    </a>
  </div>
  <div
    class="card-body row"
    v-show="form.id_facture !== '' && selectedFacture.id"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="pull-left">
          <p class=" ">
            <b>Numéro Paiement :</b> {{ search_query }} <br />
            <b>Numéro Facture :</b> N° {{ selectedFacture.id
            }}{{ formatDateFac(selectedFacture.facture.created_at) }} <br />
            <b>Nom Partenaire :</b> {{ selectedFacture.partenaire }} <br />
            <b>Type Facture :</b> {{ selectedFacture.type }} <br />
            <b>Montant Total :</b>{{ selectedFacture.montant }} <br />
            <b>Reliquat :</b> {{ selectedFacture.reliquat }} <br />
            <!-- Affichage du reliquat -->
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-6 p-t-20">
      <div
        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
      >
        <label
          class="mdl-textfield__label"
          for="dateOfBirth"
          v-show="!form.date_paiement"
          >Date facture</label
        >
        <flat-pickr
          v-model="form.date_paiement"
          class="mdl-textfield__input"
          @input="validatedata('date_paiement')"
        ></flat-pickr>
        <span class="erreur">{{ this.date_paiement_erreur }}</span>
      </div>
    </div>

    <div class="col-lg-12 p-t-20">
      <div
        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
      >
        <label
          for="list3"
          class="mdl-textfield__label"
          v-show="!form.mode_paiement"
          >Choisissez le mode de paiement</label
        >
        <select
          class="mdl-textfield__input"
          id="list3"
          readonly
          tabIndex="-1"
          v-model="form.mode_paiement"
          @change="validatedata('mode_paiement')"
        >
          <option value="Cash">Espèce</option>
          <option value="Cheque">Cheque</option>
          <option value="Orange">Orange</option>
          <option value="Wave">Wave</option>
        </select>
        <span class="erreur">{{ this.mode_paiement_erreur }}</span>
      </div>
    </div>

    <div class="col-lg-12 p-t-20">
      <div
        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width"
      >
        <label
          class="mdl-textfield__label"
          for="txtmontant_payer"
          v-show="!form.montant_payer"
          >Mettez le montant à payer
        </label>
        <input
          class="mdl-textfield__input"
          type="text"
          id="txtmontant_payer"
          v-model="form.montant_payer"
          @input="validatedata('montant_payer')"
        />
        <span class="erreur">{{ this.montant_payer_erreur }}</span>
      </div>
    </div>
  </div>

  <div class="col-lg-12 p-t-20 text-center">
    <button
      type="submit"
      v-if="!this.editModal"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
      @click.prevent="validerAvantAjout()"
    >
      Enregistrer
    </button>
    <button
      type="submit"
      v-if="this.editModal"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
      @click.prevent="validerAvantAjout()"
    >
      Modifier
    </button>
    <button
      type="button"
      class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
      @click="resetForm"
    >
      Annuler
    </button>
  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapActions } from "vuex";
import "flatpickr/dist/flatpickr.css";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import permissionMixin from "@/permission";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
    flatPickr,
  },
  mixins: [permissionMixin],
  name: "createComptableCompenent",
  data() {
    return {
      filieres: [],
      form: new Form({
        id_paiement: "",
        id_facture: "",
        montant_payer: "",
        date_paiement: "",
        mode_paiement: "",
      }),

      paiement_partenaires: [],
      factures: [],

      /*  classes: [], */

      /*  annee_academiques: [], */
      search_query: "",
      selectedFacture: {
        id: "",
        partenaire: "",
        type: "",
        reliquat: "",
        montant: "",
        facture: [],
        formattedDate: "",
      },
      eleve_classe: "",
      id_paiement_erreur: "",
      id_annee_accademique_erreur: "",

      montant_payer_erreur: "",
      date_paiement_erreur: "",
      mode_paiement_erreur: "",
      etatForm: false,
      searchidPaiement: true,
      editModal: false,
      idPaiement: "",
    };
  },
  computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_partenaire();
    this.rafraichissementAutomatique();
    bus.on("paiementModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.monterToupdate(eventData.paiement);
      this.get_partenaire();
    });
  },

  /*  mounted() {
        this.get_partenaire();
        this.rafraichissementAutomatique();
        bus.on('paiementModifier', (eventData) => {
            this.editModal = eventData.editModal;
            this.monterToupdate(eventData.paiement);
            this.get_partenaire();
        });
    }, */

  methods: {
    ...mapActions(["login", "logout"]),
    async soumettre() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();

      formdata.append("id_facture", this.selectedFacture.id);
      formdata.append("type", this.selectedFacture.type);
      formdata.append("partenaire", this.selectedFacture.partenaire);
      formdata.append("montant_payer", this.form.montant_payer);
      formdata.append("date_paiement", this.form.date_paiement);
      formdata.append("mode_paiement", this.form.mode_paiement);

      if (this.hasCreatePermission("Paiement")) {
        try {
          const create_store = await axios.post(
            "paiement_partenaire/store",
            formdata,
            { headers }
          );

          showDialog6("Paiement effectué avec succès");
          bus.emit("paiementAjoutee");
          this.resetForm();
          window.location.href = "/liste-paiement-facture";
        } catch (e) {
          console.log(e);
          showDialog3("Une erreur est survenue lors de l'enregistrement");
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },
    formatDateFac(date) {
      // Convertir la date en objet Date
      const dateObj = new Date(date);

      // Extraire l'année, le mois et le jour
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Ajoute un 0 devant si le mois est inférieur à 10
      const day = String(dateObj.getDate()).padStart(2, "0"); // Ajoute un 0 devant si le jour est inférieur à 10

      // Construire la chaîne de date dans le format désiré (YYYYMMDD)
      const formattedDate = `${year}${month}${day}`;

      // Retourner la chaîne formatée
      return formattedDate;
    },
    FacTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.Fac(new Date(dateTime));
    },
    async get_facture(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Paiement")) {
        await axios
          .post(`/get/facture/${id}`, {}, { headers })
          .then((response) => {
            this.facture = response.data.facture;

            this.locations = this.facture.location;

            this.partenaires = this.locations.partenaire;
            this.salles = this.locations.salle;
            this.reservations = this.facture.reservation;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la récupération des factures",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    validerAvantAjout() {
      const isVerifIdValid = this.validatedataOld();

      if (isVerifIdValid) {
        this.etatForm = false;
        this.editModal = false;
        return 0;
      } else {
        if (this.editModal === true) {
          this.etatForm = true;
          this.update_paiement(this.idPaiement);
          this.resetForm();
          this.editModal = false;
        } else {
          this.etatForm = true;
          this.soumettre();
          this.editModal = false;
          console.log("Tokkos");
        }
      }
    },
    validatedata(champ) {
      switch (champ) {
        case "montant":
          this.montant_erreur = "";
          for (let i = 0; i < this.form_paiement.paiement.length; i++) {
            const paiement = this.form_paiement.paiement[i];
            if (
              paiement.montant === "" ||
              isNaN(paiement.montant) ||
              paiement.montant <= 0
            ) {
              this.montant_erreur =
                "Le montant pour le paiement " + (i + 1) + " est invalide";
              return true;
            }
          }
          break;
        case "reference":
          this.reference_erreur = "";
          if (
            (this.form.reference === "") &
            (this.form.mode_paiement === "Cheque")
          ) {
            this.reference_erreur = "La reference est obligatoire ";
            i = 1;
            return true;
          }
          break;
        case "id_annee_accademique":
          this.id_annee_accademique_erreur = "";
          for (let i = 0; i < this.form_paiement.paiement.length; i++) {
            const paiement = this.form_paiement.paiement[i];
            if (paiement.id_annee_academique === "") {
              this.id_annee_accademique_erreur =
                "Vous avez oublié de sélectionner l'\Annee Academique pour le paiement " +
                (i + 1);
              return true;
            }
          }
          break;
        case "id_mois":
          this.id_mois_erreur = "";
          for (let i = 0; i < this.form_paiement.paiement.length; i++) {
            const paiement = this.form_paiement.paiement[i];
            if (paiement.id_mois === "") {
              this.id_mois_erreur =
                "Vous avez oublié de sélectionner le mois pour le paiement " +
                (i + 1);
              return true;
            }
          }
          break;
        case "id_eleve":
          this.id_eleve_erreur = "";
          //Vérification de l'eleve selectionner
          if (this.form.id_eleve === "") {
            this.id_eleve_erreur = "Matricule invalide ";
            i = 1;
            return true;
          }

          break;
        case "mode_paiement":
          this.mode_paiement_erreur = "";
          //Vérification de l'eleve selectionner
          if (this.form.mode_paiement === "") {
            this.mode_paiement_erreur = "Le mode de paiement est obligatoire ";
            i = 1;
            return true;
          }

          break;
        case "type_recouvrement":
          this.type_recouvrement_erreur = "";
          //Vérification de l'eleve selectionner
          if (this.form.type_recouvrement === "") {
            this.type_recouvrement_erreur =
              "Le type de recouvrement est obligatoire ";
            i = 1;
            return true;
          }

          break;
        default:
          break;
      }
    },

    validatedataOld() {
      this.id_annee_accademique_erreur = "";
      this.id_eleve_erreur = "";
      this.montant_payer_erreur = "";
      var i = 0;

      if (this.form.id_annee_academique === "") {
        this.id_annee_accademique_erreur =
          "Vous avez oublié de sélectionner l'\ annee academique";
        i = 1;
      }
      if (this.form.montant_payer === "") {
        this.montant_payer_erreur =
          "Vous avez oublié de sélectionner le montant_payer de l'inscription";
        i = 1;
      }

      if (this.form.id_eleve === "") {
        this.id_eleve_erreur = "Code invalide ";
        i = 1;
      }

      if (i == 1) return true;

      return false;
    },

    async performSearch() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      this.id_facture_erreur = "";
      try {
        const response = await axios.get("/recherche/id_facture", {
          params: {
            query: this.search_query,
          },
          headers: headers,
        });
        this.factures = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    selectFacture(facture) {
      this.form.id_facture = facture.id;
      this.search_query = facture.id;
      this.selectedFacture.id = facture.id;
      this.selectedFacture.partenaire =
        facture.location.partenaire.nom_partenaire;
      this.selectedFacture.type = facture.type;
      this.selectedFacture.reliquat = facture.location.partenaire.reliquat;
      this.selectedFacture.montant =
        facture.location.montant_jour * facture.location.nombre_jour;

      this.recuper_reliquat();
      this.selectedFacture.facture.created_at = facture.location.created_at;
      this.paiement_partenaires = [];
      this.factures = [];
      this.searchidPaiement = false;
    },
    getImageUrl(url) {
      //return url ? `${window.location.origin}/storage/${url}` : '';
      return url ? `http://127.0.0.1:8000/storage/${url}` : "";
    },

    async recuper_reliquat() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Paiement")) {
        try {
          const response = await axios.post(
            "/paiement_partenaire/valider-facture/" + this.search_query,
            {},
            { headers }
          );
          const reliquat = response.data.reliquat;
          this.selectedFacture.reliquat = reliquat; // Mettre à jour le reliquat dans les données de la facture sélectionnée
        } catch (error) {
          console.error("Erreur lors de la récupération du reliquat :", error);
          Swal.fire(
            "Erreur!",
            "Une erreur est survenue lors de la récupération du reliquat",
            "error"
          );
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    async get_partenaire() {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Paiement")) {
        await axios
          .get("/partenaire/index", { headers })
          .then((response) => {
            this.partenaires = response.data.partenaire;
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la recuperation des partenaires",
              "error"
            );
          });
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    resetForm() {
      this.form.id_facture = "";
      this.form.statut = "";
      this.form.id_annee_academique = "";
      this.selectedFacture.id = "";
      this.selectedFacture.type = "";
      this.selectedFacture.partenaire = "";
      this.selectedFacture.classe = "";
      this.search_query = "";
      this.montant_payer = "";
      this.mode_paiement = "";
      this.date_paiement = "";
      this.searchidPaiement = true;
      const eventData = {
        editModal: false,
      };
      bus.emit("factureDejaModifier", eventData);
    },

    rafraichissementAutomatique() {
      document.addEventListener("DOMContentLoaded", this.resetForm());
    },

    async update_paiement(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const formdata = new FormData();
      formdata.append("id_facture", this.selectedFacture.id);
      formdata.append("type", this.selectedFacture.type);
      formdata.append("partenaire", this.selectedFacture.partenaire);
      formdata.append("montant_payer", this.form.montant_payer);
      formdata.append("date_paiement", this.form.date_paiement);
      formdata.append("mode_paiement", this.form.mode_paiement);

      if (this.hasUpdatePermission("Paiement")) {
        try {
          await axios.post("/paiement_partenaire/update/" + id, formdata, {
            headers,
          });
          showDialog6("Paiement modifié avec succès");
          bus.emit("paiementAjoutee");
          const eventData = {
            editModal: false,
          };
          bus.emit("paiementDejaModifier", eventData);
        } catch (e) {
          console.log(e);
          if (e.request.status === 404) {
            showDialog3("Une erreur est survenue lors de la modification");
          } else {
            showDialog3("Une erreur est survenue lors de la modification");
          }
        }
      } else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    monterToupdate(paiement) {
      // Récupérer l'ID du paiement
      this.idPaiement = paiement.id_facture;
      // Définir le mode édition du formulaire
      this.editModal = paiement.editModal;

      // Récupérer les détails de la facture associée au paiement
      this.get_facture(paiement.id_facture).then(() => {
        // Mettre à jour les valeurs des champs du formulaire avec les détails de la facture
        this.selectedFacture.partenaire = paiement.partenaire;
        this.form.date_facture = paiement.date_facture;
        this.selectedFacture.montant = paiement.montant_payer;
        this.selectedFacture.reliquat = paiement.reliquat;
        this.selectedFacture.type = paiement.type;
        // Définir l'ID de la facture dans le champ de recherche
        this.search_query = paiement.id_facture;
      });
    },
  },
};
</script>
