<template>
  <div class="page-wrapper">
    <div class="page-header navbar navbar-fixed-top">
      <div class="page-header-inner">
        <div class="hide-on-print">
          <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
        </div>
      </div>
    </div>
    <div class="page-container">
      <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
        :userRole="userRole" />

      <!-- start page content -->
      <div class="page-content-wrapper">
        <div class="page-content">
          <div class="page-bar">
            <div class="page-title-breadcrumb">
              <div class="pull-left">
                <div class="page-title">Facture</div>
              </div>
              <ol class="breadcrumb page-breadcrumb pull-right">
                <li>
                  <i class="fa fa-home"></i>&nbsp;<a class="parent-item" :href="'/dashboard-saf'">Tableau de
                    bord</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
                <li>
                  <a class="parent-item" :href="'liste-facture'">Facture</a>&nbsp;<i class="fa fa-angle-right"></i>
                </li>
              </ol>
            </div>
          </div>
          <div class="row print-head">
            <div class="col-md-12">
              <div class="white-box">
                <div class="row">
                  <div>
                    <div class="pull-left">
                      <!-- Deux logos de l'entreprise -->
                      <img style="width: 10%; height: 10%" :src="'/assets/img/logoCFPT--clr.png'" alt="logo-left"
                        class="img-circle" />
                      <p class="text-muted m-l-5" style="font-size: 10px; margin-bottom: 5px;">
                        REPUBLIQUE DU SENEGAL<br />
                        MINISTERE DE LA FORMATION PROFESSIONNELLE<br />
                        DE L'APPRENTISSAGE ET DE L'INSERTION<br />
                        CENTRE DE FORMATION PROFESSIONNELLE ET TECHNIQUE SENEGAL
                        - JAPON
                      </p>
                      <!-- <img src="/assets/img/logo_right.png" alt="logo-right" class="logo-right" /> -->
                    </div>
                  </div>
                </div>
                <h4 class="pull-right">
                  <p class="m-t-10">
                    <b>Dakar, le :</b> <i class="fa fa-calendar"></i>
                    {{ formattedDate }}
                  </p>
                </h4>
                <div class="row justify-content-left mt-4" style="width: 100%;">
                  <h3 class="pull-right text-center">
                    <b>FACTURE
                      {{ convertToUpperCase(this.factureProforma.type) }} N° {{ this.factureProforma.id }}{{
            formatDateFac(this.factureProforma.created_at) }}</b>
                  </h3>

                  <div class="col-md-6">
                    <!-- Utilisez la taille de colonne souhaitée -->
                    <div class="pull-left text-left">
                      <address>
                        <h5>
                          <strong class="font-bold addr-font-h4">OBJET :</strong>
                          {{ this.locations.designation }}
                          {{ this.salles.intitule }}
                        </h5>

                        <h5>
                          <strong class="font-bold addr-font-h4">CLIENT :</strong>
                          {{ this.partenaires.nom_partenaire }}
                        </h5>
                        <h5>
                          <strong class="font-bold addr-font-h4">TEL :</strong>
                          {{ this.partenaires.contact }}
                        </h5>
                        <h5>
                          <strong class="font-bold addr-font-h4">Periode :</strong>
                          {{ FacTime(this.reservations.date_debut) }} au
                          {{ FacTime(this.reservations.date_fin) }}
                        </h5>
                      </address>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="table-responsive m-t-40 m-l-40">
                      <table class="table table-hover invoice-table">
                        <thead>
                          <tr>
                            <!--  <th class="text-center">#</th> -->
                            <th class="text-right">DESIGNATION</th>
                            <th class="text-right">Nombre de jours</th>
                            <th class="text-right">Montant/Jour</th>
                            <!-- <th class="text-right">Invoice number</th> -->
                            <th class="text-right">Cout</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <!--  <td class="text-center">1</td> -->
                            <td class="text-right">
                              {{ this.locations.designation }}
                              {{ this.salles.intitule }}
                            </td>
                            <td class="text-right">
                              {{ this.locations.nombre_jour }}
                            </td>
                            <td class="text-right">
                              {{ this.locations.montant_jour }}
                            </td>
                            <!-- <td class="text-right">#IN-345609865</td> -->
                            <td class="text-right">
                              {{
            this.locations.montant_jour *
            this.locations.nombre_jour
          }}
                            </td>
                          </tr>
                          <tr v-if="this.partenaires.exoneration === 0">
                            <td colspan="3" class="text-right black-text bold">MONTANT HT</td>
                            <td>
                              {{
            this.locations.montant_jour *
            this.locations.nombre_jour
          }}
                            </td>
                          </tr>

                          <tr v-if="this.partenaires.exoneration === 0">
                            <td colspan="3" class="text-right black-text bold">TVA (18 %)</td>
                            <td>
                              {{
            (this.locations.montant_jour *
              this.locations.nombre_jour * 18) / 100
          }}
                            </td>
                          </tr>

                          <tr v-if="this.partenaires.exoneration === 0" class="black-text bold">
                            <td colspan="3" class="text-right">MONTANT TTC</td>
                            <td>
                              {{
            ((this.locations.montant_jour *
              this.locations.nombre_jour * 18) / 100) + (this.locations.montant_jour *
                this.locations.nombre_jour)
          }}
                            </td>
                          </tr>

                          <tr v-if="this.partenaires.exoneration === 1" class="black-text bold">
                            <td colspan="3" class="text-right">MONTANT TTC</td>
                            <td>
                              {{
            this.locations.montant_jour * this.locations.nombre_jour
          }}
                            </td>
                          </tr>

                          <tr v-if="this.factureProforma.type === 'Solde'" class="black-text bold">
                            <td colspan="3" class="text-right">RELIQUAT</td>
                            <td>
                              {{
            (this.locations.montant_jour *
              this.locations.nombre_jour) - (
              this.factureProforma.montant_payer)
          }}
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="signature" v-if="this.partenaires.exoneration === 0">
                      <strong class="black-text" style="font-size: 12px">
                        Arrêtée La Présente Facture {{ this.factureProforma.type }} à la Somme de :
                        {{
            convertNumberToWords(
              ((this.locations.montant_jour *
                this.locations.nombre_jour * 18) / 100) + (this.locations.montant_jour *
                  this.locations.nombre_jour)
            )
          }} FCFA
                      </strong>
                    </div>
                    <div class="signature" v-if="this.partenaires.exoneration === 1">
                      <strong class="black-text" style="font-size: 12px">
                        Arrêtée La Présente Facture {{ this.factureProforma.type }} à la Somme de :
                        {{
            convertNumberToWords(
              this.locations.montant_jour *
              this.locations.nombre_jour
            )
          }} FCFA
                      </strong>
                    </div>
                    <hr /><br>

                    <div class="signature mt-4">Signature: _______________</div>
                    <div class="pull-right text-right"></div>

                    <div class="clearfix"></div>
                    <hr />

                    <footer class="print-footer"
                      style="bottom: 0; text-align: center; width: 100%; padding: 0px; border-top: 1px solid #050505;">
                      <div class="row">
                        <div class="">
                          <p style="margin-bottom: 5px; font-size: 10px;">SUD FIDAK CICES VDN -BP 8411 DAKAR-YOFF -
                            Tél.:+221 33 869.82.82 - +221 33 827.62.70.</p>
                          <p style="margin-bottom: 5px;font-size: 10px;">Site / www.cfpt-sj.sn -email :
                            cfptsj@orange.snCode Banque : SN011 Code Guichet : 01017 Compte Bancaire N°022004505689,</p>
                          <p style="margin-bottom: 5px; font-size: 10px;">RIB: 59 BIC: SGSNSNDA3X NINEA: 0061972150R0
                          </p>
                        </div>
                      </div>
                    </footer>


                  </div>
                </div>
              </div>
            </div>

            <div class="text-right hide-on-print">
              <a class="btn btn-danger" @click="goBack()">
                Retour
              </a>
              <button onclick="javascript:window.print();" class="btn btn-default btn-outline" type="button">
                <span><i class="fa fa-print"></i> Imprimer</span>
              </button>
            </div>

          </div>
        </div>
      </div>
      <appafterContent />
    </div>
    <appfooter />

  </div>
</template>

<script>
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import FormulaireModification from "./editFacture.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import { mapState, mapActions } from "vuex";
import permissionMixin from "@/permission";

export default {
  name: "factureCompenent",
  components: {
    FormulaireModification,
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
  },
  mixins: [permissionMixin],
  data() {
    return {
      form: new Form({
        designation: "",
        nombre_jour: "",
        montant_jour: "",
        date_location: "",
        id_partenaire: "",
        id_salle: "",
        id_user: "",
      }),
      factureProforma: [],
      locations: [],
      salles: [],
      reservations: [],
      partenaires: [],
      idUser: "",
      editModal: false,

      activePhase: 1,
      idUser: "",
      userEnCoursDeModification: null,
      factureId: "",
      formattedDate: "",
    };
  },
  computed: {
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

  async created() {
    await this.get_permission();
    this.get_facture(this.$route.params.id);
    bus.on("factureAjoutee", () => {
      this.get_facture(this.$route.params.id);
    });

    bus.on("factureDejaModifier", (eventData) => {
      this.editModal = eventData.editModal;
      this.get_facture(this.$route.params.id);
    });
    this.factureId = this.$route.params.id;
    this.formatCurrentDate();
  },
  /*   mounted() {
      this.get_facture(this.$route.params.id);
      bus.on("factureAjoutee", () => {
        this.get_facture(this.$route.params.id);
      });
  
      bus.on("factureDejaModifier", (eventData) => {
        this.editModal = eventData.editModal;
        this.get_facture(this.$route.params.id);
      });
      this.factureId = this.$route.params.id;
      this.formatCurrentDate();
    }, */

  methods: {
    ...mapActions(["login", "logout"]),

    async get_facture(id) {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      if (this.hasReadPermission("Facture")) {
        await axios
          .post(`/get/facture/${id}`, {}, { headers })
          .then((response) => {
            this.factureProforma = response.data.facture;

            this.locations = this.factureProforma.location;

            this.partenaires = this.locations.partenaire;
            this.salles = this.locations.salle;
            this.reservations = this.factureProforma.reservation;
            //console.log(this.salles);
          })
          .catch((error) => {
            Swal.fire(
              "Erreur!",
              "Une erreur est survenue lors de la récupération des factures",
              "error"
            );
          });
      }
      else {
        // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
        showDialog3("Vous n'avez pas la permission requise.");
      }
    },

    FacTime(dateTime) {
      // Utilisez une fonction pour formater la date
      return this.Fac(new Date(dateTime));
    },

    convertToUpperCase(word) {
      if (typeof word === "string") {
        return word.toUpperCase();
      } else {
        return "";
      }
    },

    goBack() {
      // Utilisez la méthode Vue.js $router pour revenir en arrière
      //this.resetForm()
      this.$router.go(-1);
    },

    formatDateFac(date) {
      const dateObj = new Date(date);
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return year + month + day;
    },

    convertNumberToWords(number) {
      // Tableaux de nombres et de mots
      const units = [
        "",
        "Un",
        "Deux",
        "Trois",
        "Quatre",
        "Cinq",
        "Six",
        "Sept",
        "Huit",
        "Neuf",
      ];
      const teens = [
        "Dix",
        "Onze",
        "Douze",
        "Treize",
        "Quatorze",
        "Quinze",
        "Seize",
        "Dix-Sept",
        "Dix-Huit",
        "Dix-Neuf",
      ];
      const tens = [
        "",
        "Dix",
        "Vingt",
        "Trente",
        "Quarante",
        "Cinquante",
        "Soixante",
        "Soixante-Dix",
        "Quatre-Vingt",
        "Quatre-Vingt-Dix",
      ];
      const thousands = ["", "Mille", "Million", "Milliard", "Billion", "Trillion"];

      function convertToWords(number) {
        if (number === 0) return "Zéro";

        let result = "";
        let i = 0;

        while (number > 0) {
          if (number % 1000 !== 0) {
            let groupInWords = convertHundreds(number % 1000);
            // Si le premier chiffre du groupe est zéro, on ignore le "Un"
            if (number % 1000 >= 100 && number % 1000 < 200) {
              groupInWords = groupInWords.substring(3); // Ignore "Un"
            }
            result = groupInWords + (i > 0 ? " " + thousands[i] : "") + " " + result;
          }
          number = Math.floor(number / 1000);
          i++;
        }

        return result.trim();
      }




      // Fonction pour convertir les centaines en mots
      function convertHundreds(number) {
        let result = "";

        if (number >= 100) {
          result += units[Math.floor(number / 100)] + " Cent ";
          number %= 100;
        }

        if (number >= 20) {
          result += tens[Math.floor(number / 10)] + " ";
          number %= 10;
        }

        if (number >= 10) {
          result += teens[number - 10] + " ";
          return result;
        }

        if (number > 0) {
          result += units[number] + " ";
        }

        return result;
      }

      return convertToWords(number);
    },

    Fac(date) {
      const day = date.getDate();
      const monthNumber = date.getMonth() + 1;
      const year = date.getFullYear();

      // Tableau des trois premières lettres des mois en français
      const monthAbbreviations = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Aoû",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];

      // Obtenez les trois premières lettres du mois correspondant au numéro du mois
      const month = monthAbbreviations[monthNumber - 1];

      return `${day} ${month} ${year}`;
    },

    formatCurrentDate() {
      // Obtenir la date actuelle
      const today = new Date();
      // Options pour formater la date
      const options = { day: "numeric", month: "long", year: "numeric" };
      // Formater la date en "14 Juin 2017"
      const formattedDate = today.toLocaleDateString("fr-FR", options);
      // Mettre à jour la variable de date formatée
      this.formattedDate = formattedDate;
    },
  },
};
</script>
<style>
@media print {
  .hide-on-print {
    display: none;
    visibility: hidden;
  }
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  border: 2px solid #050505;
  padding: 1%;
  text-align: center;
}

.invoice-table th {
  background-color: #f2f2f2;
}

.invoice-table tr:last-child td:first-child {
  text-align: center;
}

.invoice-table tr:last-child td:last-child {
  text-align: center;
}

.black-text {
  color: black;
}

/* CSS pour le pied de page lors de l'impression */
@media print {
  .print-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #080707;
    padding: 10px;
    border-top: 1px solid #050505;
    text-align: center;
  }
}

@media print {
  .print-head {
    position: fixed;
    top: 0;
    /* Définit la distance entre le haut de l'élément et le haut de la fenêtre du navigateur */
    width: 100%;
  }
}
</style>
