
<template> 
          <div >
              <div >
                  <div>
                      
                      <div class="card-body row">
                            <!-- start page content -->
  
         <div class="col-lg-12 p-t-20" v-show="searchMatricule && !editModal">
              <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                  <label class="mdl-textfield__label" for="txtMatricule" v-show="!search_query"> Matricule </label>
                  <input class="mdl-textfield__input" type="text" id="txtMatricule" v-model="this.search_query" @input="performSearch" />
                  <span class="erreur">{{ this.id_eleve_erreur }}</span>
              </div>
          </div>
          <div v-for="(eleve, key) in eleves" :key="key" @click="selectEleve(eleve)" >
            <a href="#"> {{ eleve.nom }} {{ eleve.prenom }}</a>
          </div> 
  
          <div class="card-body row" v-show="form.id_eleve !== '' && selectedEleve.id">
              <div class="row">
                  <div class="col-md-12">
                      <div class="pull-left">
                            <address> 
                              <img :src="getImageUrl(selectedEleve.photo)" alt="logo" class="logo-default"
                              style="width: 10%; height: 10%;" />
                              <p class=" ">
                                  <ul class="performance-list">   
                                      <li><i class=" fa fa-circle-o" style="color:#AA00AA;"> </i>{{ search_query }}</li>
                                      <li><i class=" fa fa-circle-o" style="color:#F39C12;"> </i> Nom complet : {{ selectedEleve.nom }} {{ selectedEleve.prenom }}, </li>
                                      <li><i class=" fa fa-circle-o" style="color:#DD4B39;"> </i> Classe : {{selectedEleve.classe }} , </li>  
                                      <li><i class=" fa fa-circle-o" style="color:#00A65A;"> </i> Date Naissance : {{ this.formatDateTime(selectedEleve.date_naissance) }}, </li>   
                                      <li><i class=" fa fa-circle-o" style="color:#555555;"> </i>Adresse :{{selectedEleve.adresse }}</li>  
                                  </ul>
                              </p>
                            </address> 
                      </div>
                  </div>
              </div>  
  
              <div class="col-lg-12 p-t-20">
                  <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                      <label for="list3" class="mdl-textfield__label" v-show="!form.quotient" >Choisissez le quotient</label>
                      <select class="mdl-textfield__input" id="list3" readonly tabIndex="-1" v-model="form.quotient" @change="validatedata('quotient')" >
                          <option value="90000">90 000</option>
                          <option value="30000">30 000</option>
                         
                      </select>
                      <span class="erreur">{{ this.quotient_erreur }}</span>
                  </div>
              </div>  
  
             

              <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width" >
                          <label for="list6" class="mdl-textfield__label" v-show="!form.id_chambre" >Choisissez la Chambre</label>
                          <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1"  v-model="form.id_chambre"  @change="validatedata('id_chambre')">
                          <option v-for="(chambre, index) in chambres" :value="chambre.id" :key="index">
                              {{ chambre.intitule }} Batiment {{ chambre.batiment.intitule }}
                          </option>
                          </select>
                          <span class="erreur">{{ id_chambre_erreur }}</span>
                      </div>
                  </div>

              <div class="col-lg-6 p-t-20">
                      <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width" >
                          <label for="list6" class="mdl-textfield__label" v-show="!form.id_annee" >Choisissez l'année</label>
                          <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1"  v-model="form.id_annee"  @change="validatedata('id_annee')">
                          <option v-for="(annee_academique, index) in annee_academiques" :value="annee_academique.id" :key="index">
                              {{ annee_academique.intitule }}
                          </option>
                          </select>
                          <span class="erreur">{{ id_annee_erreur }}</span>
                      </div>
                  </div>
  
         
          </div>
  
          <div class="col-lg-12 p-t-20 text-center">
              <button
                  type="submit"
                  v-if="!this.editModal"
                  class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary" @click.prevent="validerAvantAjout()" >
                  Enregistrer
              </button>
              <button type="submit" v-if="this.editModal" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary" @click.prevent="validerAvantAjout()" >
                  Modifier
              </button>
              <button type="button" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger" @change="resetForm">
                  Annuler
              </button>  
          </div>
         
                      </div>
                  </div>
              </div>
          </div>
  
  </template>
    
    <script>
    import bus from "../../eventBus";
    import axios from "@/axios";
    /* import he from 'public/assets/plugins/fullcalendar/packages/core/locales/he'; */
    import Form from "vform";
    import { mapState, mapActions } from "vuex";
    import appheader from "../layout/header.vue";
    import appfooter from "../layout/footer.vue";
    import appnavbar from "../layout/navbar.vue";
    import appafterContent from "../layout/afterContent.vue";
    import permissionMixin from "@/permission";
    
    export default {
      components: {
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
      },
      mixins: [permissionMixin],
      name: "createHebergementCompenent",
      data() {
        return {
          filieres: [],
          form: new Form({
              id_eleve: "",
              id_chambre: "",
              quotient: "",
              id_annee: "",
          }),
          
          eleves: [],
          annee_academiques: [],
          chambres: [],
          search_query: "",
          idhebergement: "",
          selectedEleve: {
            id: "",
            nom: "",
            prenom: "",
            classe: "",
            adresse: "",
            date_naissance: "",
            photo: "",
            idEleve:""
          },
          eleve_classe: "",
          id_eleve_erreur: "",
          id_annee_erreur: "",
          quotient_erreur: "",
          id_chambre_erreur: "",
          etatForm: false,
          editModal: false,
          searchMatricule: true,
        };
      },
      computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
          return this.$store.state.isLoggedIn;
        },
        userNom() {
          return this.$store.state.userNom;
        },
        userPrenom() {
          return this.$store.state.userPrenom;
        },
        userIdrole() {
          return this.$store.state.userIdrole;
        },
        userPhoto() {
          return this.$store.state.userPhoto;
        },
        userRole() {
          return this.$store.state.userRole;
        },
      },
    
      async created() {
        await this.get_permission();
        this.get_annee_academique();
        this.get_chambre();
        this.rafraichissementAutomatique();

        bus.on("hebergementModifier", (eventData) => {
          this.editModal = eventData.editModal;
          this.monterToupdate(eventData.hebergement);
        });
      },
    /* 
      mounted() {
        this.get_annee_academique();
        this.get_chambre();
        this.rafraichissementAutomatique();

        bus.on("hebergementModifier", (eventData) => {
          this.editModal = eventData.editModal;
          this.monterToupdate(eventData.hebergement);
        });
      }, */
    
      methods: {
        ...mapActions(["login", "logout"]),
        async soumettre() {
          const token = localStorage.getItem("token");
          const headers = { Authorization: `Bearer ${token}` };
          const formdata = new FormData();
          formdata.append("id_chambre", this.form.id_chambre);
          formdata.append("id_eleve", this.selectedEleve.idEleve);
          formdata.append("quotient", this.form.quotient);
          formdata.append("id_annee", this.form.id_annee);
          
          if(this.hasCreatePermission("Hebergement")){
          try {
            const create_store = await axios.post("/hebergement/store", formdata, {
              headers,
            });
            showDialog6("Hebergement ajouté avec succès");
            bus.emit("hebergementAjoutee");
            this.resetForm();
            setTimeout(() => {
              window.location.href = "/liste-hebergement";
            }, 1500);
    
            bus.emit("hebergementAjoutee");
          } catch (e) {
            console.log(e);
            showDialog3("Une erreur est survenue lors de l'enregistrement");
          }
          }
          else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
        },
    
        formatDateTime(dateTime) {
          // Utilisez une fonction pour formater la date
          return this.formatDate(new Date(dateTime));
        },
        formatDate(date) {
          const day = date.getDate();
          const monthNumber = date.getMonth() + 1;
          const year = date.getFullYear();
    
          // Tableau des trois premières lettres des mois en français
          const monthAbbreviations = [
            "Jan",
            "Fév",
            "Mar",
            "Avr",
            "Mai",
            "Juin",
            "Juil",
            "Aoû",
            "Sep",
            "Oct",
            "Nov",
            "Déc",
          ];
    
          // Obtenez les trois premières lettres du mois correspondant au numéro du mois
          const month = monthAbbreviations[monthNumber - 1];
    
          return `${day} ${month} ${year}`;
        },
    
        validerAvantAjout() {
          const isVerifIdValid = this.validatedataOld();
    
          if (isVerifIdValid === true) {
            this.etatForm = false;
            this.editModal = false;
            return 0;
          } else {
            if (this.editModal === true) {
              this.etatForm = true;
              this.update_hebergement(this.idhebergement);
              this.editModal = false;
            } else {
              this.soumettre();
              this.etatForm = true;
              this.editModal = false;
            }
          }
        },

        validatedata(champ) {
          switch (champ) {
            case "quotient":
              this.quotient_erreur = "";
              if (this.form.quotient === "") {
                this.quotient_erreur = "Ce champ est obligatoire ";
                i = 1;
                return true;
              }
              break;
            case "id_chambre":
              this.id_chambre_erreur = "";
              if (this.form.id_chambre === "") {
                this.id_chambre_erreur = "Ce champ est obligatoire ";
                i = 1;
                return true;
              }
              break;
            case "id_eleve":
              this.id_eleve_erreur = "";
              //Vérification de l'eleve selectionner
              if (this.form.id_eleve === "") {
                this.id_eleve_erreur = "Matricule invalide ";
                i = 1;
                return true;
              }
              break;
            case "id_annee":
              this.id_annee_erreur = "";
              //Vérification de l'eleve selectionner
              if (this.form.id_annee === "") {
                this.id_annee_erreur = "Ce champ est obligatoire ";
                i = 1;
                return true;
              }
              break;
            default:
              break;
          }
        },
    
        validatedataOld() {
          this.id_annee_erreur = "";
          this.quotient_erreur = "";
          this.id_chambre_erreur = "";
          this.id_eleve_erreur = "";
          var i = 0;
    
          
          if (this.form.id_eleve === "") {
            this.id_eleve_erreur = "Matricule invalide ";
            i = 1;
          }
          if (this.form.id_chambre === "") {
              this.id_chambre_erreur = "Ce champ est obligatoire ";
              i = 1;
          }
    
          if (this.form.quotient === "") {
              this.quotient_erreur = "Ce champ est obligatoire ";
              i = 1;
          }
          if (this.form.id_annee === "") {
              this.id_annee_erreur = "Ce champ est obligatoire ";
              i = 1;
          }
    
          if (i == 1) return true;
    
          return false;
        },
    
        async performSearch() {
          const token = localStorage.getItem("token");
          const headers = { Authorization: `Bearer ${token}` };
          this.id_eleve_erreur = "";
          try {
            const response = await axios.get("/recherche/eleve", {
              params: {
                query: this.search_query,
              },
              headers: headers,
            });
            this.eleves = response.data;
          } catch (error) {
            console.log(error);
          }
        },
    
        selectEleve(eleve) {
          this.form.id_eleve = eleve.user.id;
          this.search_query = eleve.user.matricule;
          this.selectedEleve.id = eleve.user.id;
          this.selectedEleve.nom = eleve.user.nom;
          this.selectedEleve.prenom = eleve.user.prenom;
          this.selectedEleve.adresse = eleve.user.adresse;
          this.selectedEleve.date_naissance = eleve.user.date_naissance;
          this.selectedEleve.photo = eleve.user.photo;
          this.selectedEleve.idEleve
              this.selectedEleve.idEleve= eleve.id;
            eleve.inscription.forEach((inscription) => {
              this.selectedEleve.classe =
                inscription.classe.type_classe +
                " " +
                inscription.classe.nom_classe +
                " " +
                inscription.classe.niveau;
            });
          //});
          this.eleves = [];
          this.eleves = []; // Pour vider la liste après avoir sélectionné un élève
          this.searchMatricule = false;
        },
        getImageUrl(url) {
          //return url ? `${window.location.origin}/storage/${url}` : '';
          //return url ? `http://127.0.0.1:8000/storage/${url}` : "";
          return url ? `https://erp.cfptdigital.sn/back/storage/${url}` : "";
        },
    
        async get_annee_academique() {
          const token = localStorage.getItem("token");
          const headers = { Authorization: `Bearer ${token}` };
          if(this.hasReadPermission("Hebergement")){
          await axios
            .get("/annee_academique/index", { headers })
            .then((response) => {
              this.annee_academiques = response.data.annee_academique;
            })
            .catch((error) => {
              Swal.fire(
                "Erreur!",
                "Une erreur est survenue lors de la recuperation des année academiques",
                "error"
              );
            });
          }
          else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
        },
    
        async get_chambre() {
          const token = localStorage.getItem("token");
          const headers = { Authorization: `Bearer ${token}` };
          if(this.hasReadPermission("Hebergement")){
          await axios
            .get("/chambre/index", { headers })
            .then((response) => {
              this.chambres = response.data.chambre;
            })
            .catch((error) => {
              Swal.fire(
                "Erreur!",
                "Une erreur est survenue lors de la recuperation des chambres",
                "error"
              );
            });
          }
          else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
        },
    
        resetForm() {
          this.form.id_eleve = "";
          this.form.id_chambre= "";
          this.form.quotient= "";
          this.form.id_annee= "";
  
          this.id_eleve_erreur= "";
          this.id_annee_erreur= "";
          this.quotient_erreur= "";
          this.id_chambre_erreur= "";
  
          this.selectedEleve.id = "";
          this.selectedEleve.nom = "";
          this.selectedEleve.prenom = "";
          this.selectedEleve.classe = "";
          this.selectedEleve.adresse = "";
          this.selectedEleve.date_naissance = "";
          this.selectedEleve.photo = "";
          this.search_query = "";
          this.eleve_classe = "";
          this.searchMatricule = true;
          this.editModal = false;
        },
    
        rafraichissementAutomatique() {
          document.addEventListener("DOMContentLoaded", this.resetForm());
        },
    
     
    
        async update_hebergement(id) {
          const token = localStorage.getItem("token");
          const headers = { Authorization: `Bearer ${token}` };
          const formdata = new FormData();
          formdata.append("id_chambre", this.form.id_chambre);
          formdata.append("id_eleve",  this.selectedEleve.idEleve);
          formdata.append("quotient", this.form.quotient);
          formdata.append("id_annee", this.form.id_annee);
    
          if(this.hasUpdatePermission("Hebergement")){
          try {
            await axios.post("/hebergement/update/" + id, formdata, { headers });
            showDialog6("Hébergement modifié avec succès");
            bus.emit("hebergementAjoutee");
            const eventData = {
              editModal: false,
            };
            bus.emit("hebergementDejaModifier", eventData);
          } catch (e) {
            console.log(e);
            if (e.request.status === 404) {
              showDialog3("Une erreur est survenue lors de la modification");
            } else {
              showDialog3("Une erreur est survenue lors de la modification");
            }
          }
        }
        else {
            // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
            showDialog3("Vous n'avez pas la permission requise.");
        }
        },
    
    
        monterToupdate(hebergement) {
          this.idhebergement = hebergement.id;
          this.editModal = hebergement.editModal;
    
          this.form.id_eleve = hebergement.id_eleve;
          this.selectedEleve.id = hebergement.id_eleve;
          this.selectedEleve.photo = hebergement.photo;
          this.selectedEleve.nom = hebergement.nom;
          this.selectedEleve.prenom = hebergement.prenom;

          this.selectedEleve.classe = hebergement.classes;

          this.selectedEleve.date_naissance = hebergement.date_naissance;
          this.selectedEleve.adresse = hebergement.adresse;

          this.selectedEleve.idEleve= hebergement.idEleve;

          this.search_query = hebergement.search_query;
    
          this.form.id_chambre = hebergement.id_chambre;
          this.form.quotient = hebergement.quotient;
          this.form.id_annee = hebergement.id_annee;
          
        },
      },
    };
    </script>

