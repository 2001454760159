<template>
    <div class="main">
		<!-- Sing in  Form -->
		<section class="sign-in">
			<div class="container">
				<div class="signin-content">
					<div class="signin-image">
						<figure><img :src="'./assets/auth/images/logo.jpg'" alt="sing up image"></figure>
						<!-- <a href="sign_up.html" class="signup-image-link">Create an account</a> -->
					</div>
					<div class="signin-form">
						<h2 class="form-title">Connexion</h2>
					
                        <form class="register-form"  id="login-form">
            <div class="form-floating mb-2 mt-4" >
              <input
                v-model="form.matricule"
                type="text"
                class="form-control input-height p-4"
                id="floatingInput"
                placeholder="MXXXX"
                :style="'width: 88%; background: rgba(255, 255, 255, 0.66); box-shadow: -2px 5px 10.899999618530273px -2px #595353;  backdrop-filter: blur(29.50px)'"
              />
              <label for="floatingInput" v-show="!form.matricule"
                ><i class="fa fa-user"></i> Matricule</label
              >
            </div>

            <div class="form-floating mt-4" >
              <input
                v-model="form.password"
                :type="passwordFieldType"
                class="form-control input-height p-4"
                id="floatingPassword"
                placeholder="Password"
                :style="'width: 88%; background: rgba(255, 255, 255, 0.66); box-shadow: -2px 5px 10.899999618530273px -2px #59535300;  backdrop-filter: blur(29.50px)'"
              />
              <button
                class="border-0 position-absolute top-50 end-0 translate-middle-y"
                @click.prevent="togglePasswordVisibility"
                type="button"
                id="togglePassword"
                :style="'margin-right: 15%; background-color: white;'"
              >
                <i
                  class="fi"
                  :class="
                    passwordFieldType === 'password'
                      ? 'fi-rr-eye'
                      : 'fi-rr-eye-crossed'
                  "
                ></i>
              </button>
              <label for="floatingPassword" v-show="!form.password"
                ><i class="fa fa-lock"></i> Mot de passe</label
              >
            </div>

         
            <div class="form-group" :style="' top: 65%;'">
    <input type="checkbox" v-model="rememberMe" name="remember-me" id="remember-me" class="agree-term mt-4" />
    <label for="remember-me" class="label-agree-term ms-2 mt-4">Se souvenir de moi</label>
</div>



            <div class="form-group form-button" :style="'width: 205px; height: 52px;  top: 60%;'">
								<button  @click.prevent="verification()" class="btn btn-round btn-primary" name="signin" id="signin">Se connecter</button>
							</div>
            
          </form>
					
					</div>
				</div>
			</div>
		</section>
	</div>
  </template>
  
  <script>
  /* import { useToast } from 'vue-toast-notification';
  import 'vue-toast-notification/dist/theme-sugar.css';
   */
  import axios from "@/axios";
  import Form from "vform";
  
  export default {
    name: "loginComponent",
    data() {
      return {
        form: new Form({
          matricule: "",
          password: "",
        }),
        message: "",
        errorMessage: "",
        erreur: false,
        bouton: "",
        rememberMe: false,
        passwordVisible: false,
        passwordFieldType: "password",
      };
    },

    mounted() {
      const rememberMeChecked = localStorage.getItem('rememberMeChecked');
    if (rememberMeChecked) {
      this.rememberMe = JSON.parse(rememberMeChecked);
    }
    swal.close();
    },
    watch: {
    // Surveiller les changements de la case à cocher et stocker l'état dans le stockage local
    rememberMe(newValue) {
      localStorage.setItem('rememberMeChecked', JSON.stringify(newValue));
    },
  },
    methods: {
      togglePasswordVisibility() {
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      },
      afficherToastInfo(message) {
        $.toast({
          heading: "Erreur",
          text: message,
          position: "top-right",
          loaderBg: "#ff6849",
          icon: "error",
          hideAfter: 3500,
        });
      },
  
  
      async verification() {
    if (this.form.matricule !== "" && this.form.password !== "") {
      try {
        const { data } = await axios.post("/connexion", this.form);
  
        this.message = "";
  
        if (data.statut !== "Erreur" && data.token) {
          window.location.href = data.url;
  
                localStorage.setItem("token", data.token);
                localStorage.setItem("userNom", data.user.nom);
                localStorage.setItem("userPrenom", data.user.prenom);
                localStorage.setItem("userIdrole", data.user.id_role);
                localStorage.setItem("userPhoto", data.user.photo); 
                localStorage.setItem("userrole", data.user.role.intitule); 
                localStorage.setItem("authmatricule", data.user.matricule); 
                localStorage.setItem("authid", data.user.id); 
                localStorage.setItem("authadresse", data.user.adresse); 
                localStorage.setItem("authtelephone", data.user.telephone); 
                localStorage.setItem("authemail", data.user.email); 
                localStorage.setItem("authdate_de_naissance", data.user.date_de_naissance); 
                localStorage.setItem("authlieu_de_naissance", data.user.lieu_de_naissance); 
                localStorage.setItem("authnationalite", data.user.nationalite); 
                localStorage.setItem("authstatus", data.user.status); 
                localStorage.setItem("authgenre", data.user.genre);
                localStorage.setItem("authdepartement", data.user.formateur.unite_de_formation.departement.nom_departement); 
                localStorage.setItem("authunite_de_formation", data.user.formateur.unite_de_formation.nom_unite_formation);
               
                this.$store.commit("LOGIN",  data.user.nom, data.user.prenom, data.user.id_role, data.user.photo, data.user.role.intitule, data.user.matricule
                ,data.user.id, data.user.adresse , data.user.telephone , data.user.lieu_de_naissance, data.user.nationalite, data.user.status, data.user.formateur.unite_de_formation.departement.nom_departement, data.user.formateur.unite_de_formation.nom_unite_formation); 
               
                this.message = "";
        } else {
          if (data.message === "Rôle utilisateur inconnu.") {
            this.message = "Rôle utilisateur inconnu.";
          } else {
            this.message = "Matricule ou mot de passe incorrect.";
          }
          this.afficherToastInfo(this.message);
        }
      } catch (error) {
        //console.error("Une erreur s'est produite :", error);
        //console.log(error.response.data.message)
        if(error.response.data.message==="Connexion échouée."){
          this.message = "Matricule ou mot de passe incorrect.";
          this.afficherToastInfo(this.message);
        }
      
      }
    } else {
      this.message = "Tous les champs sont obligatoires.";
      this.afficherToastInfo(this.message);
    }
  },
  
    },
  };
  </script>
  <style>
  .main {
      background-image: url('/public/assets/auth/images/image_font_2.png');
     /*  background-image: url('/public/assets/auth/images/image_font.jpeg'); */
     /*  background-size: cover;
      background-position: center;
      height: 100vh; */ /* Réglez la hauteur en fonction de vos besoins */
  }
</style>
  