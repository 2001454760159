<template>
    <div class="page-wrapper">
        <div class="page-header navbar navbar-fixed-top">
            <div class="page-header-inner">
                <appheader :userPhoto="userPhoto" :userNom="userNom" :userPrenom="userPrenom" />
            </div>
        </div>
        <div class="page-container">
            <appnavbar :userNom="userNom" :userPrenom="userPrenom" :userPhoto="userPhoto" :userIdrole="userIdrole"
                :userRole="userRole" />

            <!-- start page content -->
            <div class="page-content-wrapper">
                <!-- start page content -->
                <div class="page-content" v-if="!this.editModal">
                    <div class="page-bar">
                        <div class="page-title-breadcrumb">
                            <ol class="breadcrumb page-breadcrumb pull-right">
                                <li>
                                    <i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                        :href="'/dashboard'">Accueil</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>

                                <li class="active">
                                    Paramétres &nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                                <li>
                                    <a class="parent-item" :href="'/liste-filiere'">
                                        Filière</a>&nbsp;<i class="fa fa-angle-right"></i>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tabbable-line">
                                <ul class="nav customtab nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a href="#tab1" class="nav-link active" data-bs-toggle="tab">Filière</a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane active fontawesome-demo" id="tab1">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="card card-box">
                                                    <div class="card-head">
                                                        <header>Toutes les Filières</header>
                                                        <div class="tools">
                                                            <a class="fa fa-repeat btn-color box-refresh"
                                                                href="javascript:;"></a>
                                                            <a class="t-collapse btn-color fa fa-chevron-down"
                                                                href="javascript:;"></a>
                                                            <a class="t-close btn-color fa fa-times"
                                                                href="javascript:;"></a>
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-6 col-sm-6 col-6">
                                                                <div class="btn-group">
                                                                    <a :href="'/create-filiere'" id="addRow"
                                                                        class="btn btn-primary">
                                                                        Ajouter
                                                                        <i class="fa fa-plus text-white"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--  -->
                                                        <div class="row align-items-end">
                                                            <div class="col-sm-12 col-md-4">
                                                                <div class="mt-4 mb-4 dataTables_length d-flex align-items-center justify-content-start"
                                                                    id="example47_length">
                                                                    <label class="me-2">Afficher</label>
                                                                    <select name="example47_length"
                                                                        aria-controls="example47" class="form-select-sm"
                                                                        @change="updatePerPage" v-model="limitQuery">
                                                                        <option value="5">5</option>
                                                                        <option value="10">10</option>
                                                                        <option value="15">15</option>
                                                                    </select>
                                                                    <span class="ms-2">entrées</span>
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-4 mt-4 mb-4 ms-auto">
                                                                <div style="float: right">
                                                                    <label for="searchInput" class="me-2">Recherche
                                                                        :</label>
                                                                    <input type="text" id="searchInput" placeholder=""
                                                                        aria-controls="example47" v-model="searchQuery"
                                                                        @input="get_unite_de_formation(currentPage)" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!--  -->
                                                        <table
                                                            class="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                                                            id="example47">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Filière</th>
                                                                    <th>Chef de filière</th>
                                                                    <th>Département</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="odd gradeX" v-for="( unite_de_formation, index ) in unite_de_formations" :key="index">
                                                                    <td>{{ index + 1 }}</td>
                                                                    <td>{{ unite_de_formation.filiere }}</td>
                                                                    <td>
                                                                        {{ unite_de_formation.user_prenom }}
                                                                        {{ unite_de_formation.user_nom }}
                                                                    </td>
                                                                    <td>{{ unite_de_formation.departement }}</td>

                                                                    <td>
                                                                        <a class="tblEditBtn" @click="openModal(unite_de_formation)">
                                                                            <i class="fa fa-pencil"></i>
                                                                        </a>
                                                                        <a class="tblDelBtn" @click=" deleteUniteDeFormation(unite_de_formation)">
                                                                            <i class="fa fa-trash-o"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div class="row" v-if="this.unite_de_formations.length > 0">
                                                            <div class="col-sm-12 col-md-5">
                                                                <div class="dataTables_info" id="datable_1_info"
                                                                    role="status" aria-live="polite">
                                                                    Affichage de {{ from_begin }} à
                                                                    {{ to_begin }} sur {{ total }} entrées
                                                                </div>
                                                            </div>

                                                            <div class="col-sm-12 col-md-7 text-right">
                                                                <div class="dataTables_paginate paging_simple_numbers"
                                                                    id="datable_1_paginate">
                                                                    <paginate-component :total-pages="total_page"
                                                                        :total="total" :per-page="per_page"
                                                                        :current-page="current_page"
                                                                        @pagechanged="pagPage"></paginate-component>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="page-content-wrapper" v-show="editModal">
                    <div class="page-content">
                        <div class="page-bar">
                            <div class="page-title-breadcrumb">
                                <div class="pull-left">
                                    <div class="page-title">Modifier Filiere</div>
                                </div>
                                <ol class="breadcrumb page-breadcrumb pull-right">
                                    <li>
                                        <i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                            :href="'/dashboard'">Tableau de Bord</a>&nbsp;<i
                                            class="fa fa-angle-right"></i>
                                    </li>
                                    <li>
                                        <a class="parent-item" :href="'/liste-filiere'">Filière</a>&nbsp;<i
                                            class="fa fa-angle-right"></i>
                                    </li>
                                    <li class="active">Modifier Filière</li>
                                </ol>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card-box">
                                    <div class="card-head">
                                        <header>Information</header>
                                        <button id="panel-button"
                                            class="mdl-button mdl-js-button mdl-button--icon pull-right"
                                            data-upgraded=",MaterialButton">
                                            <i class="material-icons">more_vert</i>
                                        </button>
                                        <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                                            data-mdl-for="panel-button">
                                            <li class="mdl-menu__item">
                                                <i class="material-icons">assistant_photo</i>Action
                                            </li>
                                            <li class="mdl-menu__item">
                                                <i class="material-icons">print</i>Another action
                                            </li>
                                            <li class="mdl-menu__item">
                                                <i class="material-icons">favorite</i>Something else
                                                here
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-body row">
                                        <FormulaireModification />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <appafterContent />
            </div>
            <appfooter />
        </div>
    </div>
</template>
<script>
import PaginateComponent from "@/components/paginate/PaginateComponent.vue";
import bus from "../../eventBus";
import axios from "@/axios";
import Form from "vform";
import datatable from "datatables.net-bs5";
import Swal from "sweetalert2";
import FormulaireModification from "./editUniteFormation.vue";
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";
import { mapState, mapActions } from "vuex";
import permissionMixin from "@/permission";

export default {
    name: "listeUniteDeFormationCompenent",
    components: {
        FormulaireModification,
        appnavbar,
        appfooter,
        appheader,
        appafterContent,
        PaginateComponent,
    },
    mixins: [permissionMixin],
    data() {
        return {
            form: new Form({
                nom_unite_formation: "",
                id_formateur: "",
                id_departement: "",
            }),
            unite_de_formations: [],
            editModal: false,
            idFormation: "",
            limitQuery: 5,
            total_page: 0,
            total: 0,
            per_page: 0,
            current_page: 1,
            from_begin: 0,
            to_begin: 0,
            is_loading: false,
            searchQuery: "",
            currentPage: 1,
            lastPage: 1,
        };
    },
    computed: {
        //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
        token() {
            return this.$store.state.isLoggedIn;
        },
        userNom() {
            return this.$store.state.userNom;
        },
        userPrenom() {
            return this.$store.state.userPrenom;
        },
        userIdrole() {
            return this.$store.state.userIdrole;
        },
        userPhoto() {
            return this.$store.state.userPhoto;
        },
        userRole() {
            return this.$store.state.userRole;
        },
    },
    async created() {
        await this.get_permission();
        this.get_unite_de_formation();
        bus.on("unite_formationAjoutee", () => {
            // Écouter l'événement de nouvelle unite de formation
            this.get_unite_de_formation(); // Mettre à jour la liste des unites de formations
        });
        bus.on("unite_formationDejaModifier", (eventData) => {
            this.editModal = eventData.editModal;
        });
    },
    /* mounted() {
          this.get_unite_de_formation();
          bus.on('unite_formationAjoutee', () => { // Écouter l'événement de nouvelle unite de formation
              this.get_unite_de_formation(); // Mettre à jour la liste des unites de formations
          });
          bus.on('unite_formationDejaModifier', (eventData) => {
              this.editModal = eventData.editModal;
          });
      },
  */

    methods: {
        ...mapActions(["login", "logout"]),

        pagPage(pagination) {
            this.current_page = pagination;
            this.get_unite_de_formation(this.currentPage);
        },
        updatePerPage(event) {
            // Récupérer la nouvelle valeur sélectionnée
            const newPerPage = parseInt(event.target.value);

            // Appeler la méthode pour recharger les données avec la nouvelle quantité par page
            this.reloadDataWithPerPage(newPerPage);
        },

        reloadDataWithPerPage(newPerPage) {
            // Mettre à jour la limite de la requête avec la nouvelle quantité par page
            if (this.limitQuery !== 5) {
                this.limitQuery = newPerPage;
                // Recharger les données avec la nouvelle quantité par page
                this.get_unite_de_formation(this.currentPage);
            }
        },
       
        async get_unite_de_formation() {
            const token = localStorage.getItem("token");
            const filterCondition = (unite) => true; // Condition de filtre à définir

            const headers = { Authorization: `Bearer ${token}` };

            const params = {
                per_page: this.limitQuery,
                page: this.current_page,
                // Ajoutez d'autres paramètres si nécessaire
            };
            if (this.hasReadPermission("Filière")) {
                try {
                    //const response = await axios.get('/unite_de_formation/all/paginate', { headers });
                    const response = await axios.get("/unite_de_formation/all/paginate", {
                        headers: headers,
                        params: params, // Ajouter les paramètres ici
                    });
                    const allUnites = response.data.unite_de_formation.data;

                    const filteredUnites = allUnites.filter((unite) => {
                        return (
                            filterCondition(unite) &&
                            Object.keys(unite)
                                .reduce((acc, key) => {
                                    const value = unite[key];
                                    if (typeof value === "string") {
                                        acc.push(value.toLowerCase());
                                    } else if (value && typeof value === "object") {
                                        Object.values(value).forEach((nestedValue) => {
                                            if (typeof nestedValue === "string") {
                                                acc.push(nestedValue.toLowerCase());
                                            }
                                        });
                                    }
                                    return acc;
                                }, [])
                                .some((value) => value.includes(this.searchQuery.toLowerCase()))
                        );
                    });

                    const formattedUnites = filteredUnites.map((unite) => {
                        return {
                            id: unite.id,
                            filiere: unite.nom_unite_formation,
                            departement: unite.departement.nom_departement,
                            id_departement: unite.departement.id,
                            user_prenom: unite.user ? unite.user.prenom : "Non défini",
                            user_nom: unite.user ? unite.user.nom : "Non défini",
                            id_user: unite.user ? unite.user.id : null,
                            editModal: true,
                        };
                    });

                    const page = {
                        total_page: response.data.unite_de_formation.last_page,
                        total: response.data.unite_de_formation.total,
                        per_page: response.data.unite_de_formation.per_page,
                        current_page: response.data.unite_de_formation.current_page,
                        from_begin: response.data.unite_de_formation.from,
                        to_begin: response.data.unite_de_formation.to,
                    };

                    this.unite_de_formations = formattedUnites;
                    this.total_page = page.total_page;
                    this.total = page.total;
                    this.per_page = page.per_page;
                    this.current_page = page.current_page;
                    this.from_begin = page.from_begin;
                    this.to_begin = page.to_begin;
                } catch (error) {
                    Swal.fire(
                        "Erreur!",
                        "Une erreur est survenue lors de la récupération des unités de formations",
                        "error"
                    );
                }
            } else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3(
                    "Vous n'avez pas la permission de lire cette fonctionnalité."
                );
            }
        },

        changement(event) {
            this.interesser = event;
        },

        resetForm() {
            this.form.input = "";
            this.form.nom_unite_formation = "";
            this.form.id_formateur = "";
            this.form.id_departement = "";
        },

        async deleteUniteDeFormation(type) {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            if (this.hasDeletePermission("Filière")) {
                Swal.fire({
                    title: "Voulez-vous confirmer la suppression ?",
                    text: "Cette action sera irréversible!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Oui",
                    cancelButtonText: "Non",
                }).then((result) => {
                    if (result.value) {
                        axios
                            .delete(`/unite_de_formation/delete/${type.id}`, { headers })
                            .then((resp) => {
                                showDialog6("La filière a été supprimée avec succès");
                                this.get_unite_de_formation();
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                });
            } else {
                // Afficher un message d'erreur ou effectuer une autre action si l'utilisateur n'a pas la permission
                showDialog3("Vous n'avez pas la permission de supprimer une filière.");
            }
        },
        openModal(unite_de_formation) {
            this.editModal = true;
            // Créez un objet avec les données à envoyer
            const eventData = {
                filiere: unite_de_formation,
                editModal: true,
            };
            bus.emit("formationModifier", eventData);
        },
    },
};
</script>
