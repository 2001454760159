<template>
   <div class="page-wrapper">

<div class="page-header navbar navbar-fixed-top">
    <div class="page-header-inner">
        <appheader 
        :userNom="userNom"
:userPrenom="userPrenom"
:userPhoto="userPhoto"
        />
    </div>
</div>
<div class="page-container">
    <appnavbar 
    :userNom="userNom"
:userPrenom="userPrenom"
:userPhoto="userPhoto"
:userIdrole="userIdrole"
:userRole="userRole"/>

    <!-- start page content -->
    <div class="page-content-wrapper" >
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class=" pull-left">
                        <div class="page-title">Nouvelle alerte</div>
                    </div>
                    <ol class="breadcrumb page-breadcrumb pull-right">
                        <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                :href="'dashboard'">Tableau de Bord</a>&nbsp;<i
                                class="fa fa-angle-right"></i>
                        </li>
                        <li class="active">Paramétres &nbsp;<a class="parent-item"></a>&nbsp;<i
                                class="fa fa-angle-right"></i>
                        <li><a class="parent-item" :href="'/create-alerte'">alerte</a>&nbsp;<i
                                class="fa fa-angle-right"></i>
                        </li>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card-box">
                        <div class="card-head">
                            <header>Information</header>
                            <button id="panel-button" class="mdl-button mdl-js-button mdl-button--icon pull-right"
                                data-upgraded=",MaterialButton">
                                <i class="material-icons">more_vert</i>
                            </button>
                            <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect"
                                data-mdl-for="panel-button">
                                <li class="mdl-menu__item"><i class="material-icons">assistant_photo</i>Action
                                </li>
                                <li class="mdl-menu__item"><i class="material-icons">print</i>Another action
                                </li>
                                <li class="mdl-menu__item"><i class="material-icons">favorite</i>Something else
                                    here</li>
                            </ul>
                        </div>
                        <div class="card-body row">
                           
                            
                            <div class="col-lg-6 p-t-20">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                    <label class="mdl-textfield__label" for="txtFirstName" v-show="!form.titre">Titre</label>
                    <input class="mdl-textfield__input" type="text" id="txtFirstName" v-model="form.titre"
                        @input="validatedata('titre')">
                    <span class="erreur">{{ this.titre_erreur }}</span>
                </div>
            </div>

            

    <div class="col-lg-6 p-t-20">
        <div
            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
            <label for="list6" class="mdl-textfield__label" v-show="!form.statut">Statut</label>
            <select class="mdl-textfield__input" id="list6" readonly tabIndex="-1" v-model="form.statut"
                @change="validatedata('statut')">
                <option value="Urgence"> Urgence </option>
                <option value="Annonce"> Annonce </option>
            </select>
            <span class="erreur">{{ statut_erreur }}</span>
        </div>
    </div>

    <div class="col-lg-12 p-t-20">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                   <!--  <label class="mdl-textfield__label" for="txtFirstName" v-show="!form.message">Titre</label> -->
                    <textarea class="form-control" rows="5" type="text" id="message" v-model="form.message"
                        @input="validatedata('message')" placeholder="Message ..."></textarea>
                        
                    <span class="erreur">{{ this.message_erreur }}</span>
                </div>
            </div>



    <div class="col-lg-12 p-t-20 text-center">

        <button type="submit" v-if="!this.editModal"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
            @click.prevent="validerAvantAjout()">Enregistrer</button>
        <button type="submit" v-if="this.editModal"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-circle btn-primary"
            @click.prevent="validerAvantAjout()">Modifier</button>
        <button type="button"
            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger"
            @click="resetForm">Annuler</button>
    </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <appafterContent />
</div>

<appfooter />

</div>
</template>
    
<script>
import bus from '../../eventBus';
import axios from '@/axios';
import Form from 'vform';
import appheader from "../layout/header.vue";
import appfooter from "../layout/footer.vue";
import appnavbar from "../layout/navbar.vue";
import appafterContent from "../layout/afterContent.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    appnavbar,
    appfooter,
    appheader,
    appafterContent,
  },
    name: "createAlerteCompenent",
    data() {
        return {
            form: new Form({
                'titre': "",
                'message': "",
                'statut': "",
            }),
            titre_erreur: "",
            message_erreur: "",
            statut_erreur: "",
            etatForm: false,
            editModal: false,
            idSalle: "",
        }
    },
    computed: {
    //...mapState(['isLoggedIn', 'userNom','userPrenom', 'userIdrole', 'userPhoto', 'userRole']),
    token() {
      return this.$store.state.isLoggedIn;
    },
    userNom() {
      return this.$store.state.userNom;
    },
    userPrenom() {
      return this.$store.state.userPrenom;
    },
    userIdrole() {
      return this.$store.state.userIdrole;
    },
    userPhoto() {
      return this.$store.state.userPhoto;
    },
    userRole() {
      return this.$store.state.userRole;
    },
  },

    mounted() {
        bus.on('alerteModifier', (eventData) => {
            this.idSalle = eventData.idSalle;
            this.editModal = eventData.editModal;
            this.form.titre = eventData.titre;
            this.form.message = eventData.message;
            this.form.statut = eventData.statut;
        });
    },



    methods: {
        ...mapActions(["login", "logout"]),
        async soumettre() {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            const formdata = new FormData();
            formdata.append('titre', this.form.titre);
            formdata.append('message', this.form.message);
            formdata.append('statut', this.form.statut);
            try {
                await axios.post('/alerte/store', formdata, {headers});
                this.resetForm();
                bus.emit('alerteAjoutee');
            }
            catch (e) {

                if (e.request.status === 404) {
                    Swal.fire('Erreur !', 'Ce message existe déjà', 'error')
                }
                else {
                    Swal.fire('Erreur !', 'Une erreur est survenue lors de l\'enregistrement', 'error')
                }

            }

        },

        validerAvantAjout() {
            // Exécutez la validation des champs
            const isNomSalleValid = this.validatedataold();

            //console.log(isNomSalleValid);

            if (isNomSalleValid === true) {
                this.etatForm = false;
                this.editModal = false;
                return 0;
            } else {

       /*          if (this.editModal === true) {
                    this.etatForm = true;
                    this.form.titre = this.form.titre.toUpperCase();
                    this.update_salle(this.idSalle);
                   // this.closeModal('[data-modal-confirmation-modifier]');
                    this.editModal = false;
                } */

                //else {
                    this.form.titre = this.form.titre.toUpperCase();
                    this.soumettre();
                    this.etatForm = true;
                   // this.closeModal('[data-modal-confirmation]');
                    this.editModal = false;

                //}
            }
        },

        resetForm() {
            this.form.titre = "";
            this.form.message = "";
            this.form.statut = "";
            this.titre_erreur = "";
            this.message_erreur = "";
            this.statut_erreur = "";
            this.editModal === false;
        },



        validatedataold() {
            this.titre_erreur = "";
            this.message_erreur = "";
            this.statut_erreur = "";
            var i = 0;
            if (this.form.titre === "") {
                this.titre_erreur = "Ce champ est obligatoire"
                i = 1;
            }


            if (this.form.message === "") {
                this.message_erreur = "Ce champ est obligatoire"
                i = 1;

            }


            if (this.form.statut === "") {
                this.statut_erreur = "Vous avez oublié de selectionner le statut"
                    ;
                i = 1;
            }

            if (i === 1)
                return true;
            return false;

        },
        validatedata(champ) {
            // Réinitialiser les erreurs pour le champ actuel

            switch (champ) {
                case 'titre':
                    // Effectuez la validation pour le champ 'nom'
                    this.titre_erreur = "";

                    if (this.form.titre === "") {
                        this.titre_erreur = "Ce champ est obligatoire"
                        return true

                    }

                    // Ajoutez d'autres validations si nécessaire
                    break;
                case 'message':

                    this.message_erreur = "";

                    if (this.form.message === "") {
                        this.message_erreur = "Ce champ est obligatoire"
                        return true
                    }

                    break;
                case 'statut':

                    this.statut_erreur = "";

                    if (this.form.statut === "") {
                        this.statut_erreur = "Vous avez oublié de selectionner le statut"
                        return true;
                    }
                    break;
                default:
                    break;
            }
        },

    }
}
</script>